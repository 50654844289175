import * as actions_timeclock from 'actions/timeclock-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Accordion, Card } from 'react-bootstrap';
import { CloseX, Ibox, Input, Select, Spinner } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { confirm } from 'components/common/toolbox';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

export default function Timesheets(props) {
	
	const seg = 4;
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

    const employees = useSelector((store) => store.employees.employees);
    const users = useSelector(store => store.users);
    const user_permissions = useSelector(store => store.users?.user_permissions);
	const timeClockSettings = useSelector(store => store.settings.settings.timeclock)
	const timeSheet = useSelector(store => store.users?.time_sheet?.[0]) ?? {};
    const checkedIn = useSelector(store => store.users?.time_sheet?.[1]) ?? {};

	const start_work_week = (timeClockSettings?.start_work_week) ? parseInt(timeClockSettings?.start_work_week) : 1;

    const [open, setOpen] = useState('');
	const [showAnalytics, setShowAnalytics] = useState(false);
	const [startDate, setStartDate] = useState(moment().day(start_work_week).format('YYYY-MM-DD'));
	const [renderDate, setRenderDate] = useState(moment().day(start_work_week + 7));
	const [rendering, setRendering] = useState(false);

	const isTimeClock = (props.source == 'timeclock');
	const isTimecards = (props.source == 'timecards' || isTimeClock);

	/* Effects -----------------------------------------------------------------------------------------------------------------*/
	
	// Get rendered Timesheets & Timecards
	useEffect(() => {
		let user_id = (isTimeClock) ? users.user.id : props.user_id;
		if (isTimecards) {
			dispatch(actions_timeclock.subTimeCards(params.handle, user_id));
		} else {
			dispatch(actions_timeclock.subTimeSheets(params.handle));
		}

        return () => {
            var unsubscribe = (isTimecards) ? users.time_cards_unsubscribe : users.time_sheets_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
	}, [params.appId]);

	// Get selected week timesheet data
	useEffect(() => {
		if (startDate) dispatch(actions_timeclock.getTimeSheetData(params.handle, [moment(startDate).toDate(), moment(startDate).add(6, 'days').toDate()]));
	}, [startDate]);

	// delay to prevent jank
	useEffect(() => {
		setShowAnalytics(false);
		setTimeout(() => {
			setShowAnalytics(true);
		}, 150);
	}, []);

	/* Handlers --------------------------*/

	const handleInc = () => {
		setStartDate(moment(startDate).add(1, 'week').format('YYYY-MM-DD'));
	}
	const handleDec = () => {
		setStartDate(moment(startDate).subtract(1, 'week').format('YYYY-MM-DD'));
	}
	const handleSelectTimesheet = (event, value) => {
		let newValue = (value) ? value : event.target.value;
		setStartDate(newValue);
	}
	const handleOpenAccordian = (tab) => {
        if (open == tab) setOpen("");
        else setOpen(tab);
	}
	const openEditTimeClock = (id) => {
		let location = toolbox.modifyPath(routeLocation.pathname, seg, id + '/time-clock/' + moment(startDate).format('X') + '/' + moment(startDate).add(6, 'days').format('X')); 
		history.push({ pathname: location });
	}
	const openEditCheckin = (id) => {
		let location = toolbox.modifyPath(routeLocation.pathname, seg, id + '/check-in/' + moment(startDate).format('X') + '/' + moment(startDate).add(6, 'days').format('X')); 
		history.push({ pathname: location });
	}
	const handleRender = () => {
		setRendering(true);
		dispatch(actions_timeclock.createTimesheetPdf({ 
			handle: params.handle,
			shadeColor: '#116a75',
			startdate: moment(renderDate).format('YYYY-MM-DD'),
		}, isTimecards, (result) => setRendering(false)));
	}

	/* Constants -----------------------------------------------------------------------------------------------------------------*/
	
	var totalTime = 0;

	const days = 7;

	const timecards = [];
	Object.entries(timeSheet).forEach(([userId, entriesArray], index) => {
		const employee = _.find(employees, (o) => { return o.contact?.email == userId });
		if (!employee) return null;

		var entries = [];
		entriesArray.forEach((date) => entries = entries.concat(date.entries));
		entries = _.orderBy(entries, ['time-in'], ['asc']);

		var secondsAccumulated = 0;
		var daily = {};
		var missingClockout = [];
		var currentlyClockedIn = false;

		if (!entries.length) return null;

		// calculate totals
		entries.forEach((entry, index) => {
			if (entry?.['time-out'] && entry?.['time-in']) {
				const momentIn = moment(entry['time-in'].toDate());
				const momentOut = moment(entry['time-out'].toDate());
				const secondsEntry = momentOut.diff(momentIn, 'seconds');

				var current = daily[momentIn.format('YYYY-MM-DD')] ?? 0;
				
				if (secondsEntry > 59) {
					daily[momentIn.format('YYYY-MM-DD')] = current + secondsEntry;
					secondsAccumulated += secondsEntry;
					totalTime += secondsEntry;
				}
			} else if (!entry?.['time-out'] && entry?.['time-in']) {
				missingClockout.push(moment(entry['time-in'].toDate()).toDate());
				if (index == entries.length-1) currentlyClockedIn = true;
			}
		});
		
		var secondsTasks = 0;
		var secondsCheckedIn = {
			inprogress: 0,
			enroute: 0,
			onsite: 0,
		};
		var accrue = null;
		var prevTime = null;
		var missingCheckout = [];
		
		checkedIn[userId]?.forEach((checkin) => {
			
			if (checkin.type == 'task') {
				let entries = _.orderBy(checkin.entries, ['time'], ['asc']);
				entries.forEach((entry) => {
					if (entry.type == 'checkin') {
						prevTime = entry.time;
					} else {
						const momentIn = (moment(prevTime?.seconds, 'X').isValid()) ? moment(prevTime?.seconds, 'X') : null;
						const momentOut = (moment(entry.time?.seconds, 'X').isValid()) ? moment(entry.time?.seconds, 'X') : null;
						const secondsEntry = (momentIn && momentOut) ? momentOut.diff(momentIn, 'seconds') : 0;
						secondsTasks += secondsEntry;
					}
				});
				// if (accrue) missingCheckout.push(moment(prevTime?.seconds, 'X').toDate());
				
			} else {
				let entries = _.orderBy(checkin.entries, ['time'], ['asc']);
				entries.forEach((entry) => {
				
					const momentIn = (moment(prevTime?.seconds, 'X').isValid()) ? moment(prevTime?.seconds, 'X') : null;
					const momentOut = (moment(entry.time?.seconds, 'X').isValid()) ? moment(entry.time?.seconds, 'X') : null;
					const secondsEntry = (momentIn && momentOut) ? momentOut.diff(momentIn, 'seconds') : 0;

					if (entry.type == 'checkout') {
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = null;
						accrue = null;
					} else {
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = entry.time;
						accrue = entry.type;
					}
				});
				if (accrue) missingCheckout.push(moment(prevTime?.seconds, 'X').toDate());
			}
		});;

		
		const percentInprogress = secondsCheckedIn.inprogress / secondsAccumulated * 100;
		const percentEnroute = secondsCheckedIn.enroute / secondsAccumulated * 100;
		const percentOnsite = secondsCheckedIn.onsite / secondsAccumulated * 100;
		const percentTasks = secondsTasks / secondsAccumulated * 100;

		const missingClockoutAlert = (missingClockout.length > 1 || (missingClockout.length == 1 && !currentlyClockedIn));
		const missingCheckoutAlert = (missingCheckout.length > 1 || (missingCheckout.length == 1 && !currentlyClockedIn));
		const showClockedIn = (missingClockout.length == 1 && currentlyClockedIn);

		if (secondsAccumulated > 59) timecards.push(

			<Card className="card-table" key={'card' + userId}>
				<Accordion.Toggle as={Card.Header} eventKey={index.toString()} style={{ cursor: 'pointer' }} onClick={() => handleOpenAccordian(userId)}>
					<span className='float-right ml-4 mt-2'>
						<i className={`fa fa-chevron-${(open == userId) ? 'up' : 'down'}`}></i>
					</span>
					<span className="float-right" style={{ fontSize: '28px' }}>
						{ showClockedIn &&
							<span className="fa-stack animated infinite fa-beat-fade mt-n1" style={{ fontSize: '13px' }}>
								<i className="fas fa-circle fa-stack-2x text-success"></i>
								<i className="far fa-clock fa-stack-1x fa-inverse"></i>
							</span>
						}
						{ (missingClockoutAlert || missingCheckoutAlert) && 
							<i className="fa-solid fa-triangle-exclamation mr-2" style={{ color: 'red' }}></i> 
						}
						{ moment.duration(secondsAccumulated, 'seconds').format("hh:mm", { trim: false }) } <small>hrs</small>
					</span>
					<p className="font-weight-bold m-0">{`${employee.contact.firstName} ${employee.contact.lastName}`}</p>
					<p className="m-0">{ userId }</p>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={index.toString()}>
					<Card.Body>
						<div className="progress">
							<div className="progress-bar" role="progressbar" style={{ width: `${percentOnsite}%` }}></div>
							<div className="progress-bar bg-warning" role="progressbar" style={{ width: `${percentInprogress}%` }}></div>
							<div className="progress-bar bg-info" role="progressbar" style={{ width: `${percentEnroute}%` }}></div>
							<div className="progress-bar bg-success" role="progressbar" style={{ width: `${percentTasks}%` }}></div>
						</div>
						{
							Object.entries(daily)?.map((line, index) => {
								return (
									<div key={index} className="d-flex justify-content-center mt-2">
										<span className="pr-2">{ moment(line[0]).format('dddd, MMM D, YYYY') }</span>
										<div style={{ height: '1.2rem', borderBottom: '2px dotted #aaaaaa', flexGrow: 2 }}></div>
										<span className="pl-2">{ moment.duration(line[1], 'seconds')?.format('hh:mm', { trim: false }) }</span>
									</div>
								);
							})
						}
						{ missingClockoutAlert &&
							<>
								{ missingClockout.map((clockout) => 
									<p className="btn btn-danger btn-sm mt-2" onClick={ openEditTimeClock.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
										Resolve Missing "Clock Out": &nbsp; { moment(clockout).format('dddd, MMM D, YYYY') }
									</p>) 
								}
							</>
						}
						{ missingCheckoutAlert &&
							<>
								{ missingCheckout.map((checkout) => 
									<p className="btn btn-danger btn-sm mt-2" onClick={ openEditCheckin.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
										Missing Work Order "Check Out": { moment(checkout).format('dddd, MMM D, YYYY') }
									</p>) 
								}
							</>
						}
						<p className="text-right mt-3 mb-0">
							<i className="fa-solid fa-circle text-primary"></i> <span className="font-weight-bold">Onsite:</span> { moment.utc((secondsCheckedIn.onsite + 1) * 1000)?.format('H:mm') } |&nbsp; 
							<i className="fa-solid fa-circle text-info"></i> <span className="font-weight-bold">Enroute:</span> { moment.utc((secondsCheckedIn.enroute + 1) * 1000)?.format('H:mm') } |&nbsp;
							<i className="fa-solid fa-circle text-warning"></i> <span className="font-weight-bold">Inprogress:</span> { moment.utc((secondsCheckedIn.inprogress + 1) * 1000)?.format('H:mm') } |&nbsp;
							<i className="fa-solid fa-circle text-success"></i> <span className="font-weight-bold">Tasks:</span> { moment.utc((secondsTasks + 1) * 1000)?.format('H:mm') }
						</p>
						<p className="text-right mt-2 mb-0">Period: {days} days</p>

						{ !isTimeClock &&
							<div className="float-right">
								<button type="button" className={'btn btn-default btn-sm mt-3 mr-2' } onClick={openEditCheckin.bind(this, employee.id)}>
									Edit Checkin
								</button>
								<button type="button" className={'btn btn-default btn-sm mt-3 mr-2' } onClick={openEditTimeClock.bind(this, employee.id)}>
									Edit Timecard
								</button>
							</div>
						}

						<div style={{ clear: 'both' }}></div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	});

	/* Constants --------------------------*/

	const timesheet = _.find(users.time_sheets, { id: startDate });
	const timecard = _.find(users.time_cards, (o) => o.id.startsWith(startDate));

	let periodOptions = [];
	let periodEntries = [];
	let entries = (isTimecards) ? users.time_cards : users.time_sheets;

	for (let entry of entries) {
		let date = moment(entry.id);
		periodOptions.push(<option value={ date.format('YYYY-MM-DD') }>{ `${date.format('ddd MMM Do, YYYY')} - Total Time: ${moment.duration(entry.totalTime, 'seconds').format("hh:mm", { trim: false })} hrs` }</option>);
		periodEntries.push({ ...entry, 
			duration: moment.duration(entry.totalTime, 'seconds').format("hh:mm", { trim: false }),
			alert: (entry.missingClockout?.length) ? <i className="fa-solid fa-triangle-exclamation fa-lg" style={{ color: 'red' }}></i> : null,
		});
	}

	return (
		<>
			{ showAnalytics &&
				<>
					{ !isTimecards &&

						// RENDERED TIMESHEETS -----------------------------------------------------------------
						
						<div className="col-lg-6">
							{ numPanels(1) 
								?	<Select
										className="mt-3 mb-3"
										label="Time Period Starting"
										noLabel={false} 
										onChange={handleSelectTimesheet}
									>
										<option value={ moment().startOf('week').format('YYYY-MM-DD') }>{ moment().startOf('week').format('ddd MMM Do, YYYY') + ' - Current' }</option>
										{ periodOptions }
									</Select>

								:	<Ibox
								title={ (isTimecards) ? 'Timecards' : 'Timesheets'}
										show_spinner={users.time_sheets_pending}
										no_fade={true}
									>
										<div className="row">
											<div className="col-7">
												<Input
													name={'renderDate'}
													noLabel={true}
													filterDate={(moment) => {
														if (moment.day() != start_work_week) return true
													}}
													onChange={(field, date) => setRenderDate(date) }
													type="date"
													value={renderDate}
												/>
											</div>
											<div className="col-5">
												<button className={ 'btn btn-primary' } onClick={ () => {
													confirm(`Are you sure to want to Render/Re-Render this ${(isTimecards)?'Timecard':'Timesheet'}?`, handleRender);
												}}>{ (isTimecards) ? 'Render Timecard' : 'Render Timesheet' }
													{ rendering && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> }
												</button>
											</div>
										</div>

										<Table
											data={periodEntries}
											limit={5}
											chevron={true}
											active_id={startDate}
											active_field={"id"}
											columns={[
												{ name: "Date", field: "startDate", type: 'date', utc: true, format: 'ddd, MMM D, YYYY', width: 40 },
												{ name: "Rendered", field: "created", type: 'date', utc: true, format: 'MM-DD h:mma', width: 30 },
												{ name: "", field: "alert", type: 'jsx', text_align: 'center', width: 15 },
												{ name: "Time", field: "duration", text_align: 'right', width: 15 },
											]}
											order_by={{ fields: ['name'], direction: ['asc'] }}
											click_callback={ (timesheet) => {
												handleSelectTimesheet(null, moment.utc(timesheet.startDate.seconds, 'X').format('YYYY-MM-DD'));
											}}
											/>
									</Ibox>
								
							}
						</div>
					}

					{/* INDIVIDUAL TIMECARDS ----------------------------------------------------------------- */}

					<div className={ (isTimeClock) ? 'col-12' : 'col-lg-6'}>
						<div className="d-flex justify-content-between flex-wrap">

							{ isTimeClock
								?	<>
										<h2 className="mb-0 mt-1 mb-2 ml-2">Timecard:
											<small className="ml-3">{moment(startDate).format('MMM Do, YYYY')}</small>
										</h2>
										<div>
											<i className="fa-solid fa-chevron-left text-muted fa-2x mt-1 px-1" role="button" onClick={handleDec}></i>
											<i className="fa-solid fa-chevron-right text-muted fa-2x mt-1 px-1 mr-2" role="button" onClick={handleInc}></i>
										</div>
									</>
								:	<h2 className="mb-0 mt-1 mb-2">{'Timecards:'} <small className="ml-2">{moment(startDate).format('MMM Do, YYYY')}</small></h2>
							}
							
							{ ((timesheet || timecard) && !isTimeClock) && <CloseX onClick={() => setStartDate(moment().startOf('week').format('YYYY-MM-DD'))} className="mr-3 mt-0" color={ '#aaaaaa' } /> }
							{ timesheet && !isTimecards &&
								<a className="btn btn-secondary mr-5" href={ timesheet.downloadUrl } target="_blank">
									<i className="fas fa-print mr-1"></i> Print Timesheet
								</a>
							}
							{ timecard && isTimecards &&
								<a className="btn btn-secondary mr-5" href={ timecard.downloadUrl } target="_blank">
									<i className="fas fa-print mr-1"></i> Print Timecard
								</a>
							}
						</div>
						<div style={{ clear: 'both' }}></div>
						<ul className="sortable-list agile-list mt-3" id="todo">
							{ users.time_sheet_pending
								? <Spinner />
								: <Accordion  defaultActiveKey={ (isTimecards || isTimeClock)?'0': '' }>{timecards}</Accordion>
							}
							{ !timecards.length &&
								<p className="ml-3 mb-4">-- No Completed Time Entries --</p>
							}

							<li className="warning-element ui-sortable-handle mt-3 animated fadeIn animation-delay-4">
								<div className="agile-detail">
									<span className="float-right" style={{ fontSize: '18px' }}>
										{ moment.duration(totalTime, 'seconds').format("hh:mm", { trim: false }) } <small>hrs</small>
									</span>
									<h2 className="m-0">Total</h2>
								</div>
							</li>
						</ul>
					</div>
				</>
			}
		</>
	);
};

