import AddressForm from 'components/forms/address-form/address-form';
import Authorize from 'components/common/authorize';
import Breadcrumbs from 'components/common/breadcrumbs';
import CatchErrors from 'components/common/catch-errors';
// import EditCheckin from 'components/employees/employee/edit-checkin';
// import EditSchedule from 'components/employees/employee/edit-schedule';
// import EditTimeClock from 'components/employees/employee/edit-time-clock';
// import EmployeeDashboard from './employee/employee-dashboard';
// import EmployeeForm from 'components/employees/employee/employee-form/employee-form';
// import EmployeeTabs from 'components/employees/employee/employee-tabs/employee-tabs';
import EmployeesSearch from 'components/service-map/employees-search';
// import EmployeeAnalytics from 'components/employees/analytics/employee-analytics';
import React, { useEffect, useState } from 'react';
import SlidingColumn from 'components/common/sliding-column';
import SlidingColumns from 'components/common/sliding-columns';
import { PERMISSIONS } from 'components/constants';
import { Route, useRouteMatch } from "react-router-dom";
import { numPanels } from 'components/common/toolbox';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EmployeeMap from 'components/service-map/employee-map';

export default function ServiceMap(props) {

	const seg = 5;
	const match = useRouteMatch();
	const routeLocation = useLocation();
    const user_permissions = useSelector(store => store.users?.user_permissions);
	const [columnState, setColumnState] = useState(); // set by SlidingColumns
	const [showAnalytics, setAnalytics] = useState(false); // Show panel for mobile by moving to column 1

	const panelBP = {
		panels2: [99, 99, 99],
		panels1: [7, 99, 99],
	}
	const breadcrumbBP = [
		{ name: 'SERVICE MAP' },
		{ name: 'Details', segments: 6 },
		{ name: 'Service Request', segments: 10, includes: 'service_requests' },
		{ name: 'Stock Part', segments: 10, includes: 'inventory' },
		{ name: 'Work Order', segments: 10, includes: 'work_orders' },
		{ name: 'Invoice', segments: 10, includes: 'invoices' },
	];

	useEffect(() => {
		let segments = routeLocation.pathname.split('/');
		setAnalytics(segments[seg] == 'analytics'); 
	}, [routeLocation.pathname]);
	
	const swapIndex = (original, swapped, isSwapped) => {
		return (isSwapped && (numPanels(1) || numPanels(2))) ? swapped : original
	}

	return (
		<>
			<Breadcrumbs breakpoints={breadcrumbBP} columnState={columnState} />

			<SlidingColumns breakpoints={panelBP} setColumnState={setColumnState} >

				{/* --------------------------------------------------------------------------
					URL SCHEME: employees/:employee_id/form/:form_id/service_requests/:request_id/work_orders/:work_order_id/:tab
				--------------------------------------------------------------------------- */}

				{/* --------------------------------------------------------------------------
					COLUMN 1 
				--------------------------------------------------------------------------- */}

				<Route path={match.path + "/:profile_id?"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW } show403>
						<SlidingColumn index={ 1 } columnState={ columnState } { ...route_props } grid={ 'col-lg-4 col-xl-3'}>
							<CatchErrors>
								<EmployeesSearch />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				)} />

				{/* --------------------------------------------------------------------------
					COLUMN 2 - 
				-------------------------------------------------------------------------- */}

				<Route path={match.path + "/:profile_id?/:date?"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW } show403>
						<SlidingColumn index={ 2 } columnState={ columnState } { ...route_props } grid={ 'col-lg-8 col-xl-9'}>
							<CatchErrors>
								<EmployeeMap />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				)} />

				{/* <Route exact path={match.path + "/0/analytics"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW } show403>
						<SlidingColumn index={ swapIndex(2, 1, showAnalytics) } columnState={ columnState } { ...route_props }>
							<CatchErrors>
								<EmployeeAnalytics source="employees" />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				)} />
				
				<Route path={match.path + "/:profile_id/:form/:form_id/:tab?/:tab_id?"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW } show403>
						<SlidingColumn index={ 2 } columnState={ columnState }	>
							<CatchErrors>
								<EmployeeDashboard  {...props} />
							</CatchErrors>
							<CatchErrors>
								<EmployeeTabs {...route_props} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				)} />

				{ !showAnalytics &&
					<div id="column2-rel" className="col-lg-6 col-xl-4"></div>
				} */}


				{/* <Route path={match.path + "/:profile_id/:form/:form_id/:tab?/:tab_id?"} render={(route_props) => (
					<SlidingColumn index={ 3 } columnState={ columnState }>
						<h1><strong>Statistics</strong></h1>
					</SlidingColumn>
				)} /> */}


				{/* ----------------------------------------------------------------------
					MODALS
				---------------------------------------------------------------------- */}

				{/* <Route path={match.path + "/:profile_id/contact-form/:contact_id/:tab?"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_EDIT }>
						<CatchErrors modal={true}>
							<EmployeeForm {...route_props} />
						</CatchErrors>
					</Authorize>
				)} />
				<Route path={match.path + "/:profile_id/address-form/:address_id/:tab?"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_EDIT } show403>
						<CatchErrors modal={true}>
							<AddressForm {...route_props} type="employees" />
						</CatchErrors>
					</Authorize>
				)} />
				<Route path={match.path + "/:profile_id/time-clock/:startdate/:enddate"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.TIMECLOCK_EDIT }>
						<CatchErrors modal={true}>
							<EditTimeClock {...route_props} />
						</CatchErrors>
					</Authorize>
				)} />
				<Route path={match.path + "/:profile_id/check-in"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.TIMECLOCK_EDIT }>
						<CatchErrors modal={true}>
							<EditCheckin {...route_props} />
						</CatchErrors>
					</Authorize>
				)} />
				<Route path={match.path + "/:profile_id/schedule"} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.EMPLOYEES_EDIT }>
						<CatchErrors modal={true}>
							<EditSchedule {...route_props} />
						</CatchErrors>
					</Authorize>
				)} /> */}
			</SlidingColumns>
		</>
	);
};

