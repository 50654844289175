import React, { Fragment } from 'react';
import StateOptions from 'components/common/state_options';
import { APPS, ADDRESSES, CONTACTS, CUSTOMERS } from 'components/constants';

export function customer_fields(activeAppId, customerTypeId) {

	return (
		{
			'app-1': (activeAppId == '1'),
			'app-2': (activeAppId == '2'),
			displayName: '',
			customerTypeId: customerTypeId,
			companyName: '',
			phone: '',
			fax: '',
			notes: '',
			website: '',
			// Service Address Info
			addressTypeId: ADDRESSES.BILLING.id,
			addressName: '',
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
			// Main Contact Info(Billing)
			contactTypeId: CONTACTS.BILLING.id,
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			contactPhone: '',
		}
	);
}

// RESIDENTIAL CUSTOMERS -------------------------------------------------------------

export function residential_layout(existing, company) {

	var customer_type_options = Object.values(CUSTOMERS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var address_type_options = Object.values(ADDRESSES).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var contact_type_options = Object.values(CONTACTS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);

	if (existing) return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Customer Type',
					field: 'customerTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">Choose...</option>
							{customer_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Company Name',
					field: 'companyName',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: 'text',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: 'checkbox',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: 'checkbox',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: 'textarea',
				}],
			}],
		}]
	);
	if (!existing) return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Contact</h3>
					</Fragment>,
				layout: [{
					grid: 'col-6',
					label: 'First Name',
					field: 'firstName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-6',
					label: 'Last Name',
					field: 'lastName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Contact Type',
					field: 'contactTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">Choose...</option>
							{contact_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Email',
					field: 'email',
					valid: ['email'],
					inputMode: 'email',
					type: 'text',
				}, {
					grid: 'col-6',
					label: 'Mobile',
					field: 'mobile',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-6',
					label: 'Phone',
					field: 'contactPhone',
					valid: ['phone'],
					type: 'tel',
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Address</h3>
					</Fragment>,
				layout: [{
					grid: 'col-4',
					label: 'Address Type',
					field: 'addressTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							{address_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Address Name',
					field: 'addressName',
					type: 'text',
				}, {
					grid: 'col-8',
					label: 'Address',
					field: 'addressLine1',
					type: 'location-autocomplete',
					prepend: <i className="fas fa-map-marker-alt"></i>,
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Secondary Address',
					field: 'addressLine2',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'City',
					field: 'city',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'State',
					field: 'state',
					type: 'select',
					options:
						<Fragment>
							<option value="">- Select -</option>
							<StateOptions />,
						</Fragment>,
				}, {
					grid: 'col-4',
					label: 'Zip',
					field: 'zip',
					type: 'text',
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-6',
					label: 'Customer Type',
					field: 'customerTypeId',
					type: 'select',
					valid: ['required'],
					disabled: true,
					options:
						<Fragment>
							<option value="">Choose...</option>
							{customer_type_options}
						</Fragment>,
				}, {
					grid: 'col-6',
					label: 'Company Name',
					field: 'companyName',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: 'text',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: 'checkbox',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: 'checkbox',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: 'textarea',
				}],
			}],
		}]
	);
}

// COMMERCIAL CUSTOMERS -------------------------------------------------------------

export function commercial_layout(existing, company) {

	var customer_type_options = Object.values(CUSTOMERS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var address_type_options = Object.values(ADDRESSES).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var contact_type_options = Object.values(CONTACTS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);

	if (existing) return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Customer Type',
					field: 'customerTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">Choose...</option>
							{customer_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Company Name',
					field: 'companyName',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: 'text',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: 'checkbox',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: 'checkbox',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: 'textarea',
				}],
			}],
		}]
	);
	if (!existing) return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Customer Type',
					field: 'customerTypeId',
					type: 'select',
					valid: ['required'],
					disabled: true,
					options:
						<Fragment>
							<option value="">Choose...</option>
							{customer_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Company Name',
					field: 'companyName',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: 'text',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: 'checkbox',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: 'checkbox',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: 'textarea',
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Address</h3>
					</Fragment>,
				layout: [{
					grid: 'col-4',
					label: 'Address Type',
					field: 'addressTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							{address_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Address Name',
					field: 'addressName',
					type: 'text',
				}, {
					grid: 'col-8',
					label: 'Address',
					field: 'addressLine1',
					type: 'location-autocomplete',
					prepend: <i className="fas fa-map-marker-alt"></i>,
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Secondary Address',
					field: 'addressLine2',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'City',
					field: 'city',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'State',
					field: 'state',
					type: 'select',
					options:
						<Fragment>
							<option value="">- Select -</option>
							<StateOptions />,
						</Fragment>,
				}, {
					grid: 'col-4',
					label: 'Zip',
					field: 'zip',
					type: 'text',
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Contact</h3>
					</Fragment>,
				layout: [{
					grid: 'col-6',
					label: 'First Name',
					field: 'firstName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-6',
					label: 'Last Name',
					field: 'lastName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Contact Type',
					field: 'contactTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							{contact_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Email',
					field: 'email',
					valid: ['email'],
					inputMode: 'email',
					type: 'text',
				}, {
					grid: 'col-6',
					label: 'Mobile',
					field: 'mobile',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-6',
					label: 'Phone',
					field: 'contactPhone',
					valid: ['phone'],
					type: 'tel',
				}],
			}],
		}]
	);
}