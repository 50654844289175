import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { APPS, LOG, ACTION, ROLES, SERVICE_ITEMS } from 'components/constants';
import { FormBuilder, ValidateForm, ModalAlert, ModalForm } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { work_order_fields, work_order_form_layout } from 'components/work-orders/work-order/work-order-form/work-order-form-layout';

const moment = require('moment');
const _ = require('lodash');

const WorkOrderForm = (props) => {
	
	/* Hooks --------------------------*/
	
	const seg = 7;
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

	const departments = useSelector((store) => store.settings.departments);
	const employees = useSelector((store) => store.employees.employees);
	const service_request = useSelector((store) => store.serviceRequests.service_request);
	const settings = useSelector((store) => store.settings.settings);
	const workOrders = useSelector((store) => store.workOrders);

	const [formError, setFormError] = useState(null);
	const [original, setOriginal] = useState({});
	const [workOrder, setWorkOrder] = useState();
	
	const work_order = workOrders.work_orders.find((order) => order.id == params.work_order_id);

	const existing = (params.work_order_id > 0);
    const isAsset = (params.appId == APPS.ASSETS.id);

	useEffect(() => {
		if (!parseInt(params.work_order_id)) setWorkOrder(work_order_fields(settings?.work_orders?.default_duration ?? 2, service_request?.offsite ?? false))
	}, [service_request]);

	useEffect(() => {
		if (parseInt(params.work_order_id)) populateForm();
	}, [work_order]);

	/* Handlers --------------------------*/

	const handleChange = (e) => {
		let form_error = _.filter(formError, (o) => { return o.field !== e.target.name; });
		setFormError(form_error);
		setWorkOrder(prevWorkOrder => ({ ...prevWorkOrder, [e.target.name]: e.target.value }));
	};
	const handleCheckbox = (field) => {
		setWorkOrder(prevWorkOrder => ({ ...prevWorkOrder, [field]: !workOrder[field] }));
	};
	const populateForm = async () => {
		let myWorkOrder = { ...work_order,
			startDate: (work_order?.startDate) ? moment(work_order?.startDate?.toDate()).toDate() : null,
			startTime: (work_order?.startDate) ? moment(work_order?.startDate?.toDate()).toDate() : null,
			endDate: (work_order?.endDate) ? moment(work_order?.endDate?.toDate()).toDate() : null,
			duration: (work_order?.endDate)
				?	moment(work_order?.endDate?.toDate()).diff(moment(work_order?.startDate?.toDate()), 'minutes')
				:	parseFloat(settings?.work_orders?.default_duration ?? '2') * 60,
			technicianId: work_order?.technicianId,
		};
		setWorkOrder(myWorkOrder);
		setOriginal(myWorkOrder);
	};
	const handleDate = (field, formDate) => {
		var form_error = _.filter(formError, (o) => { return o.field !== field; });
		setFormError(form_error);

		let date = moment((field == 'startDate') ? formDate : workOrder.startDate).format('YYYY-MM-DD');
		let time = moment((field == 'startTime') ? formDate : workOrder.startTime).format('hh:mm A');
		let startDate = moment(`${date} ${time}`).toDate();
		setWorkOrder(prev => ({ ...prev, startDate: startDate, startTime: startDate, endDate: moment(startDate).add(settings?.work_orders?.default_duration ?? 2, 'hours').toDate() }));
	};
	const handleCleardate = (field) => {
		setWorkOrder({ ...workOrder, [field]: null });
	}
	const handleUpload = (upload) => {
		let results = actions_attachments.prepUploadedImages(upload, workOrder);
		setWorkOrder(results);
	};

	/* Actions --------------------------*/

	const confirmDelete = () => {
		ModalAlert({
			title: 'Are you sure?',
			text: "This Work Order will be Archived and not appear in work_order search! It can be restored or deleted permanently by visiting the Archive page.",
			icon: 'warning',
			show_cancel: true,
			confirm_color: '#8FBC8B',
			confirm_text: 'Yes, archive it!',
			callback_success: () => deleteWorkOrder(),
		});
	};

	const deleteWorkOrder = () => {
		dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { ...workOrder, deleted: true }, () => { 
			dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.ARCHIVED.key, params.request_id, workOrder.description ));
			history.go(-2);
		}));
	};

	const submitForm = () => {
		var form_error = ValidateForm(workOrder, form_layout);
		setFormError(form_error);

		let newWorkOrder = { ...workOrder,
			endDate: (workOrder.startDate) ? moment(workOrder.startDate).add(workOrder.duration, 'minutes').toDate() : null
		};
		if (isAsset && !existing) newWorkOrder = {
			...newWorkOrder,
			assetTypeId: service_request.assetTypeId,
			assetId: service_request.assetId,
			customerId: service_request.customerId,
		};
		delete newWorkOrder.duration;
		delete newWorkOrder.startTime;

		let imagesAlt = [];
		for (let index = 0; index < 5; index++) {
			if (workOrder['imageUrlAlt' + index]) imagesAlt.push(workOrder['imageUrlAlt' + index]);
			delete newWorkOrder['imageUrlAlt' + index]
		}
		newWorkOrder.imagesAlt = imagesAlt;

		let technician = _.find(employees, { id: newWorkOrder.technicianId });
		let price = (technician?.rateBill) ? technician.rateBill : (settings?.invoices?.defaultLaborRate) ? settings?.invoices?.defaultLaborRate : 0;

		if (!form_error?.length) {
			if (!existing) {
				dispatch(actions_work_orders.saveNewWorkOrder(params.handle, params.request_id, newWorkOrder, (workOrderId) => {
					dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.CREATED.key, workOrderId, newWorkOrder.description, newWorkOrder ));

					let location = toolbox.modifyPath(routeLocation.pathname, seg-2, 'form/0', seg+8);
					location = toolbox.modifyPath(location, seg+3, workOrderId + '/items', seg+7);
					history.goBack();
					setTimeout(() => { history.push(location); }, 250);

				}, { lineItems: [{ 
					id: "0001",
					type: SERVICE_ITEMS.LABOR.id,
					name: 'Labor',
					count: 1,
					price: price,
					calculatedPrice: (settings.invoices?.defaultLaborRate) ? parseFloat(settings?.invoices?.defaultLaborRate) : 0,
				}]}));

			} else {
				dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, newWorkOrder, () => { 
					dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.MODIFIED.key, workOrder.id, newWorkOrder.description, newWorkOrder, original ));
					history.goBack(); 
				}));
			}
		};
	};

	var technicians = _.filter(employees, (o) => { return o.roles?.includes(ROLES.TECHNICIAN.id) });

	var form_layout = work_order_form_layout({
		appId: params.appId,
		departments,
		firebase, 
		handle: params.handle, 
		technicians, 
	});

	return (
		<ModalForm {...props}
			style={1}
			size={'xl'}
			history={history}
			modal_header={`${(!existing)?'New':'Edit'} Work Order`}
			cancel_button_title="Cancel"
			save_button_title={'Save Work Order'}
			submitFormHandler={() => submitForm()}
			delete_button_title={'Archive'}
			delete_button_callback={(!existing) ? null : () => confirmDelete()}
			show_spinner={workOrders?.work_order_save_pending}
		>
			{workOrder &&
				<FormBuilder
					callbacks={{
						text: handleChange,
						select: handleChange,
						dropzone: handleUpload,
						date: handleDate,
						checkbox: handleCheckbox,
						clear: handleCleardate,
					}}
					form_error={formError}
					form={form_layout}
					record={workOrder}
				/>
			}
		</ModalForm>
	);
};

export default WorkOrderForm;