import * as actions_admin from 'actions/admin-actions';
import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { LOG, ACTION } from 'components/constants';
import { onSnapshot, updateDoc } from 'firebase/firestore';
import { constants } from 'react-smooth-dnd';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  SETTINGS
/*-----------------------------------------------*/

export function subSettings(handle) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_GLOBAL + '_PENDING' });
		var unsubscribe = firestore.collection('_mobiletrack').doc('settings').onSnapshot((doc) => {
			var settings = doc.data();
			dispatch({ type: types.SETTINGS_GLOBAL + '_FULFILLED', data: settings, unsubscribe });
		});

		dispatch({ type: types.SETTINGS + '_PENDING' });
		var unsubscribe = firestore.collection(handle).doc('settings').onSnapshot(async (doc) => {
			var settings = doc.data();
			dispatch({ type: types.SETTINGS + '_FULFILLED', data: settings, unsubscribe });
		});	

		// The following subscriptions combine into one store. The reason for 2 subscriptions is to update the store when either 'company' or 'company_settings' change.
		var unsubscribe = firestore.collection('_companies').doc(handle).onSnapshot(async (doc) => {
			var settings = doc.data();

			const snapshot = await firestore.collection(handle).doc('company').get();
			settings = { ...settings, ...snapshot.data() };

			dispatch({ type: types.SETTINGS_COMPANY + '_FULFILLED', data: settings, unsubscribe });
		});
		var unsubscribe = firestore.collection(handle).doc('company').onSnapshot(async (doc) => {
			var settings = doc.data();

			const snapshot = await firestore.collection('_companies').doc(handle).get();
			settings = { ...settings, ...snapshot.data() };

			dispatch({ type: types.SETTINGS_COMPANY + '_FULFILLED', data: settings, unsubscribe });
		});

	};
}
export function toggleMenuCollapsed() {

	return dispatch => {

		dispatch({ type: types.SETTINGS_TOGGLE_COLLAPSED });

	};
}
export function statusModalOpen(statusTable, statusId, currentId, confirmCallback) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_STATUS_MODAL_OPEN, statusTable, statusId, currentId, confirmCallback });
	};
}
export function statusModalClose() {

	return dispatch => {

		dispatch({ type: types.SETTINGS_STATUS_MODAL_CLOSE });
	};
}
export function updateSettings(handle, record, callback, { silent } = {}) {

	return dispatch => {

		// let isDualMonitorSettings = ('dualMonitors' in record || 'dualMonitors' in record || 'dualMonitor1Url' in record || 'dualMonitor2Url' in record);

		if (record) {
			dispatch({ type: types.SETTINGS + '_SAVE_PENDING' });

			firestore.collection(handle).doc('settings').set(record, { merge: true }).then(() => {
				dispatch({ type: types.SETTINGS + '_SAVE_FULFILLED' });
				// if (!isDualMonitorSettings) window.toastr.success('Settings have been saved successfully!', 'Settings Saved!');
				if (!silent) window.toastr.success('Settings have been saved successfully!', 'Settings Saved!');

				// if (isDualMonitorSettings) clearUrlSettings();
				if (typeof callback === 'function') callback();

			}).catch((error) => {
				toolbox.process_error(error, 'Settings NOT Saved!');
			});
		}

		// function clearUrlSettings() {
		// 	setTimeout(() => {
		// 		firestore.collection(handle).doc('settings').set({ dualMonitor1Url: '', dualMonitor2Url: '' }, { merge: true }).then(() => {
		// 			dispatch({ type: types.SETTINGS + '_SAVE_FULFILLED' });
		// 		}).catch((error) => {
		// 			toolbox.process_error(error, 'Settings NOT Saved!');
		// 		});
		// 	}, 1500);
		// }
	};
}
export function updateCompanySettings(handle, company, sms) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_COMPANY + '_SAVE_PENDING' });

		var batch = firestore.batch();

		// limit public company record to the following field:
		var publicFields = [
			'companyName',
			'addressLine1',
			'addressLine2',
			'city',
			'state',
			'zip',
			'geopoint',
			'logoUrl',
			'deleted',
			'modified',
		]
		batch.set(firestore.collection('_companies').doc(handle), toolbox.sanitize(company, publicFields), { merge: true });
		batch.set(firestore.collection(handle).doc('company'), { ...company, ...sms }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_COMPANY + '_SAVE_FULFILLED' });

			window.toastr.success('Settings have been saved successfully!', 'Settings Saved!');
		}).catch((error) => {
			toolbox.process_error(error, 'Settings NOT Saved!');
		});
	};
}

/*-----------------------------------------------*/
/*  DEPARTMENTS
/*-----------------------------------------------*/

export function subDepartments(handle) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENTS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/settings/departments').onSnapshot((snapshot) => {
			var departments = [];
			snapshot.docs.forEach((doc) => {
				departments.push({ ...doc.data(), id: doc.id });
			});
			dispatch({ type: types.DEPARTMENTS + '_FULFILLED', data: departments, unsubscribe });
		});
	};
}

export function saveDepartment(handle, newDepartment, departmentId, silent, callback) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENTS + '_SAVE_PENDING' });

		var batch = firestore.batch();
		newDepartment = toolbox.sanitize(newDepartment);

		if (!departmentId) departmentId = await nextDepartmentId(handle, batch);

		batch.set(firestore.collection(handle + "/settings/departments").doc(departmentId), newDepartment, { merge: true });
		batch.commit().then((doc) => {
			dispatch({ type: types.DEPARTMENTS + '_SAVE_FULFILLED' });
			if (!silent) window.toastr.success('The Department has been successfully saved/updated', 'Department Saved!');
			if (typeof callback == "function") callback(departmentId);
		});
	};
}

export function updateDepartmentPositions(handle, departments) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_PENDING' });

		var batch = firestore.batch();
		const ref = firestore.collection(handle + "/settings/departments");
		departments.forEach((department) => batch.update(ref.doc(department.id), { position: department.position, value: department.value }));

		batch.commit().then((doc) => {
			dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_FULFILLED' });
			window.toastr.success('The Departments have been successfully saved/updated', 'Departments Saved!');
		});
	};
}

async function nextDepartmentId(handle, batch) {
	const table = 'settings';
	const field = 'nextDepartmentId';
	const startingId = 100;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

/*-----------------------------------------------*/
/*  JOB CATEGORIES
/*-----------------------------------------------*/

export function subJobCategories(handle) {

	return dispatch => {

		dispatch({ type: types.JOB_CATEGORIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/job-categories").onSnapshot((snapshot) => {
			var jobCategories = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.JOB_CATEGORIES + '_FULFILLED', data: jobCategories, unsubscribe });
		});
	};
}

export function saveJobCategory(handle, jobCategory, id, callback) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENTS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		if (!id) id = await nextJobCategoryId(handle, batch);

		const ref = firestore.collection(handle + '/settings/job-categories').doc(id);

		batch.set(ref, jobCategory, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.DEPARTMENTS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Job Category has been successfully saved/updated`, 'Job Category Saved!');
			if (typeof callback === 'function') callback(id);
		});	
	};	
}

/*-----------------------------------------------*/
/*  Department Tags
/*-----------------------------------------------*/

export function subDepartmentTags(handle) {

	return dispatch => {

		dispatch({ type: types.DEPARTMENT_TAGS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/departmentTags").onSnapshot((snapshot) => {
			var departmentTags = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.DEPARTMENT_TAGS + '_FULFILLED', data: departmentTags, unsubscribe });
		});
	};
}

export function saveTag(handle, tag, id, callback) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		if (!id) id =  await nextDepartmentTagId(handle, batch);

		const stockPartsRef = firestore.collection(handle + '/settings/departmentTags').doc(id);
		batch.set(stockPartsRef, tag, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Tag has been successfully saved/updated`, 'Tag Saved!');
			if (typeof callback == "function") callback(id);
		});
	};
}
export function deleteTag(handle, tagId) {

	return async dispatch => {

		dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const ref = firestore.collection(handle + '/settings/departmentTags').doc(tagId);
		batch.delete(ref);
		batch.commit().then(() => {
			dispatch({ type: types.DEPARTMENT_TAGS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Tag has been successfully removed`, 'Tag Deleted!');
		});
	};
}

/*-----------------------------------------------*/
/*  Asset Types
/*-----------------------------------------------*/

export function subAssetTypes(handle) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_TYPES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/assetTypes").onSnapshot((snapshot) => {
			var assetTypes = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.SETTINGS_ASSET_TYPES + '_FULFILLED', data: assetTypes, unsubscribe });
		});
	};
}

export function saveAssetType(handle, newAssetType, assetTypeId, silent, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_PENDING' });

		var batch = firestore.batch();
		newAssetType = toolbox.sanitize(newAssetType);

		if (!assetTypeId) assetTypeId = await nextAssetTypeId(handle, batch);

		batch.set(firestore.collection(handle + "/settings/assetTypes").doc(assetTypeId), newAssetType, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_FULFILLED' });
			if (!silent) window.toastr.success('The Asset has been successfully saved/updated', 'Asset Saved!');
			if (typeof callback === "function") callback(assetTypeId);
		})
		.catch((error) => {
			window.toastr.error(error, 'Asset NOT Saved!');
		});
	};
}
export function updateAssetTypes(handle, assetTypes) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_PENDING' });

		var batch = firestore.batch();
		const ref = firestore.collection(handle + "/settings/assetTypes");
		assetTypes.forEach((assetType) => 
			batch.update(ref.doc(assetType.id), { deleted: (!!assetType.deleted), position: assetType.position, value: assetType.value })
		);

		batch.commit().then((doc) => {
			dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_FULFILLED' });
			window.toastr.success('The Asset Types have been successfully saved/updated', 'Asset Types Saved!');
		});
	};
}
export function archiveAssetType(handle, assetTypeId, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_PENDING' });

		var batch = firestore.batch();

		batch.update(firestore.collection(handle + "/settings/assetTypes").doc(assetTypeId), { deleted: true, position: 99 });

		// Archive Assets
		var assetsSnapshot = await firestore.collection(handle + "/assets/assets").where('assetTypeId', '==', assetTypeId).get();
		var assets = assetsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
		if (assets) {
			for (let asset of assets) {
				batch.update(firestore.collection(handle + "/assets/assets").doc(asset.id), { deleted: true, modified: new Date() })
				dispatch(actions_admin.saveActivity(handle, LOG.ASSET_TYPE.key, ACTION.ARCHIVED.key, asset.id, asset.name ));
			}
		}

		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_FULFILLED' });
			window.toastr.success('The Asset Type has been successfully archived', 'Asset Type Archived!');
			if (typeof callback === "function") callback();
		});
	};
}

// export function deleteAssetType(handle, assetTypeId, callback) {
// 	return async dispatch => {
// 		dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_PENDING' });

// 		var batch = firestore.batch();

// 		// Delete assetType
// 		batch.delete(firestore.collection(handle + "/settings/assetTypes").doc(assetTypeId.toString()));

// 		// Delete assetClassifications
// 		var assetClassificationsRef = await firestore.collection(handle + "/settings/assetClassifications").where('assetTypeId', '==', assetTypeId).get();
// 		var assetClassifications = assetClassificationsRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
// 		!!assetClassifications && assetClassifications.forEach((doc) => batch.delete(firestore.collection(handle + "/settings/assetClassifications").doc(doc.id)));

// 		// Delete partsCategories
// 		var partsCategoriesRef = await firestore.collection(handle + "/settings/partsCategories").where('assetTypeId', '==', assetTypeId).get();
// 		var partsCategories = partsCategoriesRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
// 		!!partsCategories && partsCategories.forEach((doc) => batch.delete(firestore.collection(handle + "/settings/partsCategories").doc(doc.id)));

// 		batch.commit().then(() => {
// 			dispatch({ type: types.SETTINGS_ASSET_TYPES + '_SAVE_FULFILLED' });
// 			window.toastr.success('The Asset Type has been successfully removed', 'Asset Type Deleted!');
// 			if (typeof callback === "function") callback();
// 		});
// 	};
// }


/*-----------------------------------------------*/
/*  Asset Classifications
/*-----------------------------------------------*/

export function subAssetClassifications(handle) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_CLASSIFICATIONS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/assetClassifications").onSnapshot((snapshot) => {
			var assetClassifications = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.SETTINGS_ASSET_CLASSIFICATIONS + '_FULFILLED', data: assetClassifications, unsubscribe });
		});
	};
}

export function saveClassification(handle, classification, id, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_CLASSIFICATIONS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		if (!id) id = await nextAssetClassificationId(handle, batch);

		const stockPartsRef = firestore.collection(handle + '/settings/assetClassifications').doc(id);
		batch.set(stockPartsRef, classification, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_ASSET_CLASSIFICATIONS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Classification has been successfully saved/updated`, 'Settings Saved!');
			if (typeof callback == "function") callback(id);
		});
	};
}

export function deleteClassification(handle, classificationId, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_ASSET_CLASSIFICATIONS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const snapshot = await firestore.collection(handle + '/assets/assets').where('classificationId', '==', classificationId).get();
		snapshot.docs?.forEach((doc) => {
			batch.update(doc.ref, { classificationId: '0' });
		});

		batch.delete(firestore.collection(handle + '/settings/assetClassifications').doc(classificationId));
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_ASSET_CLASSIFICATIONS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Asset Classification has been successfully removed`, 'Asset Classification Deleted!');

			if (typeof callback == "function") callback();
		});
	};
}

/*-----------------------------------------------*/
/*  Asset Parts Categories
/*-----------------------------------------------*/

export function subPartsCategories(handle) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_PARTS_CATEGORIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/partsCategories").onSnapshot((snapshot) => {
			var partsCategories = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.SETTINGS_PARTS_CATEGORIES + '_FULFILLED', data: partsCategories, unsubscribe });
		});
	};
}

export function saveCategory(handle, category, id, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_PARTS_CATEGORIES + '_SAVE_PENDING' });

		const batch = firestore.batch();

		if (!id) id =  await nextPartsCategoryId(handle, batch);

		const stockPartsRef = firestore.collection(handle + '/settings/partsCategories').doc(id);
		batch.set(stockPartsRef, category, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_PARTS_CATEGORIES + '_SAVE_FULFILLED' });
			window.toastr.success(`The Category has been successfully saved/updated`, 'Settings Saved!');
			if (typeof callback == "function") callback(id);
		});
	};
}
export function deleteCategory(handle, categoryId, callback) {

	// Removed from Stock Parts in Cloud Function

	return async dispatch => {

		dispatch({ type: types.SETTINGS_PARTS_CATEGORIES + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const ref = firestore.collection(handle + '/settings/partsCategories').doc(categoryId);
		batch.delete(ref);
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_PARTS_CATEGORIES + '_SAVE_FULFILLED' });
			window.toastr.success(`The Parts Category has been successfully removed`, 'Category Deleted!');
			if (typeof callback == "function") callback(categoryId);
		});
	};
}

/*-----------------------------------------------*/
/*  Tasks
/*-----------------------------------------------*/

export function subTasks(handle) {

	return dispatch => {

		dispatch({ type: types.SETTINGS_TASKS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + "/settings/tasks").onSnapshot((snapshot) => {
			var tasks = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch({ type: types.SETTINGS_TASKS + '_FULFILLED', data: tasks, unsubscribe });
		});
	};
}

export function saveTask(handle, task, taskId, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_TASKS + '_SAVE_PENDING' });

		const batch = firestore.batch();
		if (!taskId) taskId = await nextTaskId(handle, batch);

		const taskRef = firestore.collection(handle + '/settings/tasks').doc(taskId);
		batch.set(taskRef, task, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_TASKS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Task has been successfully saved/updated`, 'Settings Saved!');
			if (typeof callback == "function") callback(taskId);
		});
	};
}

export function deleteTask(handle, taskId, callback) {

	return async dispatch => {

		dispatch({ type: types.SETTINGS_TASKS + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const taskRef = firestore.collection(handle + '/settings/tasks').doc(taskId);
		batch.set(taskRef, { deleted: true }, { merge: true });
		batch.commit().then(() => {
			dispatch({ type: types.SETTINGS_TASKS + '_SAVE_FULFILLED' });
			window.toastr.success(`The Task has been successfully removed`, 'Task Deleted!');
			if (typeof callback == "function") callback();
		});
	};
}

async function nextAssetTypeId(handle, batch) {
	const table = 'settings';
	const field = 'nextAssetTypeId';
	const startingId = 1000;
	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextAssetClassificationId(handle, batch) {
	const table = 'settings';
	const field = 'nextAssetClassificationId';
	const startingId = 2000;
	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextPartsCategoryId(handle, batch) {
	const table = 'settings';
	const field = 'nextPartsCategoryId';
	const startingId = 3000;
	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextDepartmentTagId(handle, batch) {
	const table = 'settings';
	const field = 'nextDepartmentTagId';
	const startingId = 4000;
	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextJobCategoryId(handle, batch) {
	const table = 'settings';
	const field = 'nextJobCategoryId';
	const startingId = 5000;
	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextTaskId(handle, batch) {
	const table = 'settings';
	const field = 'nextTaskId';
	const startingId = 6000;
	return toolbox.nextId(handle, batch, table, field, startingId);
}

/*-----------------------------------------------*/
/*  LOCAL STATES
/*-----------------------------------------------*/

export function updateButton(button, update) {

	return async dispatch => {
		dispatch({ type: types.UPDATE_BUTTON, button, update });
	};
}
