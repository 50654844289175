import * as actions_admin from 'actions/admin-actions';
import * as toolbox from 'components/common/toolbox';
import TooltipOverlay from 'components/common/tooltip-overlay';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ACTION } from 'components/constants';
import { Table } from 'em-table';
import { Avatar, Ibox } from 'enspire-manager-framework';
import { imageSize, numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export default function ActivityLog(props) {
    
    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    
    const seg = 3;
    const params = useParams();
	const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    
    const employees = useSelector((store) => store.employees.employees);
    const table = useSelector((store) => store[props.store]);

    const [state, setState] = useState({
        limit: 20,
    });

    const activityLog = table?.[props.singular + '_activity_log'] ?? [];

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        dispatch(actions_admin.subActivityLog(params.handle, props.type, params[props.id], props.prefix, state.limit));

        return () => {
            let unsubscribe = table[props.singular + 'activity_log_unsubscribe'];
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [state.limit, props.id, params[props.id]]);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleUser = (userId) => {
        let employee = _.find(employees, (o) => { return o.contact?.email == userId });
		let location = toolbox.modifyPath(routeLocation.pathname, seg, `employees/${employee.id}/form/0/user-activity`, seg+4); 
		history.push({ pathname: location });
    };
    const handleMore = () => {
        setState(prev => ({ ...prev, limit: state.limit + 20 }));
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const allLoaded = activityLog.data?.length >= activityLog?.totalCount;
    
    const columns = [
        { name: 'Date', field: 'date', type: 'date', format: 'MMM D, \'YY h:mm a', nowrap: true, width: 60 },
        // { name: 'Log', field: 'log', nowrap: true },
        { name: 'Action', type: 'jsx', field: 'action', nowrap: true, width: 40 },
        { name: 'Description', field: 'description' },
        { name: '', field: 'chevron', type: 'jsx' },
    ];

    const activity_log = activityLog.data?.map((log, index) => {
        const employee = _.find(employees, (o) => { return o.contact?.email == log.userId });
        const name = (employee) ? employee.contact?.firstName + ' ' + employee.contact?.lastName : 'Unassigned';

        const userAvatar = 
            <TooltipOverlay key={index} tooltip={ log.userId }>
                <div role="button" onClick={ handleUser.bind(this, log.userId) }>
                    <Avatar className=""
                        color="white"
                        bgColor="darkOrange"
                        size={45}
                        fontSize={'20px'}
                        name={name}
                        image={employee?.contact.photoUrl ? imageSize(employee?.contact.photoUrl, 'sm') : null}
                        border='4px solid white'
                    />
                </div>
            </TooltipOverlay>

        let stripeIndex = 0;
        let textClass = '';
        if (log.action == ACTION.CREATED.key) { stripeIndex = 2; textClass = "text-success"; }
        if (log.action == ACTION.MODIFIED.key) { stripeIndex = 3; textClass = "text-info"; }
        if (log.action == ACTION.ARCHIVED.key) { stripeIndex = 4; textClass = "text-warning"; }
        if (log.action == ACTION.DELETED.key) { stripeIndex = 5; textClass = "text-danger"; }
       
        var action = <span className={ textClass }>{ log.action }</span>

        return (
            { ...log, 
                avatar: userAvatar, 
                action: action,
                stripeIndex: stripeIndex, 
                // chevron: (log.action == ACTION.MODIFIED.key) ? <i className="fas fa-chevron-right text-muted"></i> : null,
            }
        );
    }); 

    /* Render ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (

        <Ibox title={`Activity Log`}>
            <Table
                columns={columns}
                data={activity_log}
                id={'id'}
                image={'avatar'}
                imageWidth={45}
                second_line={'description'}
                second_line_clamp={ 2 }
                show_limit={false}
                show_search={true}
                stripe_color={'stripeIndex'}
                // click_callback={(log) => {
                //     console.log(log);
                // }}

            />

            { !allLoaded &&
                <button className="btn btn-default btn-block" onClick={handleMore.bind(this)} disabled={table[props.singular + '_activity_log_pending']}>
                    { (table[props.singular + '_activity_log_pending'])
                        ? <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                        : 'MORE'
                    }
                </button>
            }
        </Ibox>
    );
};




