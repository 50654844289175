import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_schedule from 'actions/schedule-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as toolbox from 'components/common/toolbox';
import CustomerForm from 'components/customers/customer/customer-form/customer-form';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { LOG, ACTION, ROLES } from 'components/constants';
import { confirmArchive, getProfileName } from 'components/common/toolbox';
import { service_request_fields, service_request_form_layout } from './service-request-form-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const _ = require('lodash');

export default function ServiceRequestForm(props) {
	
	/* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const seg = 5;
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

	const admin = useSelector((store) => store.admin);
	const employees = useSelector((store) => store.employees.employees);
	const profiles = useSelector((state) => state.profiles);
	const serviceRequests = useSelector((store) => store.serviceRequests);
	const settings = useSelector((store) => store.settings);
	const workOrders = useSelector((store) => store.workOrders);

	const [serviceFormType, setServiceFormType] = useState('service_request');
	const [focusedInput, setFocused] = useState(null);
	const [formError, setFormError] = useState(null);
	const [newCustomerName, setNewCustomerName] = useState('');
	const [original, setOriginal] = useState({});
	const [request, setRequest] = useState(service_request_fields(settings?.work_orders?.default_duration ?? 2, profiles.address_selected?.id, params.technicianId));
	
	const fromSchedule = props?.fromSchedule;
	const profile = profiles.profile;
    const segments = routeLocation.pathname.split('/').length;
	
	var serviceRequest = serviceRequests.service_requests.find((request) => request.id == params.request_id);
	if (!serviceRequest) serviceRequest = serviceRequests.service_requests_dashboard.find((request) => request.id == params.request_id);

	/* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		if (parseInt(params.request_id)) {
			populateForm();
		} else {
			if (parseInt(params.customer_id)) {
				async function setDash() {
					let customerName = await getProfileName(params.handle, params.customer_id);
					setRequest(prev => ({ ...prev, customerId: [{ target: customerName, id: params.customer_id }] }));
				}
				setDash();
				dispatch(actions_profiles.subProfile(params.handle, params.customer_id, 'PROFILE'));
			}
			handleDate('requestDate', request.requestDate);
		}
		return () => {
			let unsubscribe = profiles.profile_unsubscribe;
			if (typeof unsubscribe === "function") unsubscribe();
		};
	}, [params.request_id, params.customer_id, serviceRequest]);

	// If from schedule, set the start and end date to the dragged event
	useEffect(() => {
		if (fromSchedule && params.startDateTime && params.endDateTime) {
			let myServiceRequest = { ...request,
				startDate: moment(params.startDateTime, 'X').toDate(),
				startTime: moment(params.startDateTime, 'X').toDate(),
				endDate: moment(params.endDateTime, 'X').toDate(),
				duration: moment(params.endDateTime, 'X').diff(moment(params.startDateTime, 'X'), 'minutes'),
			};
			setRequest(myServiceRequest);
		}
	}, []);

	/* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

	const handleChange = (e, value) => {
		let newValue = (value) ? value : e.target.value;
		let form_error = _.filter(formError, (o) => { return o.field !== e.target.name; });
		setFormError(form_error);
		setRequest(prev => ({ ...prev, [e.target.name]: newValue }));
	};
	const handleDate = (field, formDate) => {
		var form_error = _.filter(formError, (o) => { return o.field !== field; });
		setFormError(form_error);
		if (field == 'requestDate') {
			setRequest(prev => ({ ...prev, requestDate: formDate }));
		} else {
			let date = moment((field == 'startDate') ? formDate : request.startDate).format('YYYY-MM-DD');
			let time = moment((field == 'startTime') ? formDate : request.startTime).format('hh:mm A');
			let startDate = moment(`${date} ${time}`).toDate();
			setRequest(prev => ({ ...prev, startDate: startDate, startTime: startDate, endDate: moment(startDate).add(settings?.work_orders?.default_duration ?? 2, 'hours').toDate() }));
		}
	};
	const handleUpload = (upload) => {
		let results = actions_attachments.prepUploadedImages(upload, request);
		setRequest(results);
	};

	/* Typeahead Handlers --------------------------*/

	const handleTypeahead = (field, result) => {
		var form_error = _.filter(formError, (o) => { return o.field !== field; });
		setFormError(form_error);
		if (result?.[0]?.customOption) {
			setNewCustomerName(result[0].target)
			openCustomerForm();
		} else if (result?.[0]?.id) {
			dispatch(actions_profiles.subProfile(params.handle, result?.[0]?.id, 'PROFILE'));
			setRequest(prev => ({ ...prev, [field]: [{ target: result?.[0]?.target, id: result?.[0]?.id }] }));
		} else {
			setRequest(prev => ({ ...prev, [field]: [] }));
		}

	};
	
	/* Actions ------------------------------------------------------------------------------------------------------------------------------------*/

	const populateForm = async () => {
		if (serviceRequest) {
			let customerName = await getProfileName(params.handle, params.customer_id);
			let vendorName = await getProfileName(params.handle, serviceRequest.vendorId);
			let myServiceRequest = { 
				...serviceRequest, 
				requestDate: serviceRequest.requestDate.toDate(),
				customerId: (serviceRequest.customerId) ? [{ target: customerName, id: serviceRequest.customerId }] : [],
				vendorId: (serviceRequest.vendorId) ? [{ target: vendorName, id: serviceRequest.vendorId }] : [],
			}
			setRequest(myServiceRequest);
			setOriginal(myServiceRequest);
		};
	};
    const openCustomerForm = () => {
        let location = toolbox.modifyPath(routeLocation?.pathname, seg+3, 'new_customer');
        history.push({ pathname: location });
    }
	const archiveRequest = () => {
		dispatch(actions_service_requests.archiveServiceRequest(params.handle, request, (serviceRequestId) => { 
			dispatch(actions_admin.saveActivity(params.handle, LOG.SERVICE_REQUEST.key, ACTION.ARCHIVED.key, params.request_id, request.description ));
			history.go((segments > seg+5) ? (-3) : (-2)); // extra back if Work Order column showing
		}));
	};
	const submitForm = () => {
		var form_error = ValidateForm(request, form_layout);
		setFormError(form_error);

		if (!form_error?.length) {
			let newServiceRequest = { ...request, 
				customerId: (request.customerId?.[0]?.id) ? request.customerId[0].id : null,
				vendorId: (request.vendorId?.[0]?.id) ? request.vendorId[0].id : null,
				type: serviceFormType,
			};
			delete newServiceRequest.duration;
			delete newServiceRequest.startTime;
	
			let imagesAlt = [];
			for (let index = 0; index < 5; index++) {
				if (request['imageUrlAlt' + index]) imagesAlt.push(request['imageUrlAlt' + index]);
				delete newServiceRequest['imageUrlAlt' + index]
			}
			newServiceRequest.imagesAlt = imagesAlt;
			
			if (fromSchedule) newServiceRequest.technicianId = workOrders.new_service_dragging?.technicianId;

			dispatch(actions_service_requests.saveServiceRequest(params.handle, newServiceRequest, (serviceRequestId) => {
				if (fromSchedule) dispatch(actions_schedule.cancelNewEvent());
				let action = (parseInt(params.request_id)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
				dispatch(actions_admin.saveActivity(params.handle, LOG.SERVICE_REQUEST.key, action, serviceRequestId, request.description, request, original ));

				if (parseInt(serviceRequestId)) {
					let location = toolbox.modifyPath(routeLocation.pathname, seg, `form/0/service_requests/${serviceRequestId}/work_orders`, seg+6);
					history.goBack();
					setTimeout(() => { history.push(location); }, 500);
				} else {
					history.goBack();
				}
			}));
		}
	};

	/* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

	const typeButtons = 
		<ButtonGroup className="d-flex my-3">
			<Button variant={ (serviceFormType == 'service_request') ? 'primary' : 'default' } onClick={ () => setServiceFormType('service_request') }>Service Request</Button>
			<Button variant={ (serviceFormType == 'service_recurring') ? 'primary' : 'default' } onClick={ () => window.toastr.warning(`This feature is Coming Soon!`) }>Recurring Maintenance</Button>
		</ButtonGroup>

	const technicians = _.filter(employees, (o) => { return o.roles?.includes(ROLES.TECHNICIAN.id) });
	const existing = (params.request_id > 0);
	const form_layout = service_request_form_layout({
		firebase, 
		handle: params.handle, 
		profile, 
		service_request: request,
		technicians,
		typeButtons,
	});

	/* Render ------------------------------------------------------------------------------------------------------------------------------------*/

	return (

		<>
			<ModalForm {...props}
				history={history}
				style={1}
				size={'xl'}
				modal_header={'Service Request'}
				cancel_button_title="Cancel"
				save_button_title={`Save Service Request`}
				submitFormHandler={submitForm}
				delete_button_title={'Archive'}
				delete_button_callback={(!existing) ? null : () => confirmArchive('Service Request', archiveRequest)}
				show_spinner={serviceRequests?.service_request_save_pending}
				focusedInput={(window.flutter) ? focusedInput : null}
				platform={admin.flutter_platform}
				keyboardPadding={admin.flutter_keyboard}
                visible={ segments[7] != 'new_customer' }
			>
				{request &&
					<FormBuilder
						callbacks={{
							focus: (event) => setFocused(event.target),
							text: handleChange,
							select: handleChange,
							typeahead: handleTypeahead,
							date: handleDate,
							dropzone: handleUpload,
						}}
						form_error={formError}
						form={form_layout}
						record={request}
					/>
				}
			</ModalForm>

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.action == 'new_customer' &&
				<CustomerForm displayName={newCustomerName} source="service_request"/>
            }
		</>
	);
}