
import * as types from 'actions/action-types';

var initialState = {
    profile: null,
    profile_saved: null,
    profile_pending: false,
    profile_save_pending: false,
    profile_unsubscribe: false,

    profile_activity_log: [],
    profile_activity_log_pending: false,
    profile_activity_log_fulfilled: false,
    profile_activity_log_unsubscribe: false,

    profile_invoices: [],
    profile_invoices_pending: false,
    profile_invoices_fulfilled: false,
    profile_invoices_unsubscribe: false,

    profiles: [],
    profiles_saved: null,
    profiles_pending: false,
    profiles_save_pending: false,
    profiles_unsubscribe: false,
    profile_unsubscribe: false,

    vendor: null,
    vendor_saved: null,
    vendor_pending: false,
    vendor_save_pending: false,
    vendor_unsubscribe: false,

    address: null,
	address_pending: false,
	address_save_pending: false,

    address_selected: null,

    contact: null,
	contact_pending: false,
	contact_save_pending: false,
};

const ProfilesReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.PROFILE + '_PENDING': pending('profile'); break;
        case types.PROFILE + '_SAVE_PENDING': save_pending('profile'); break;
        case types.PROFILE + '_SAVE_FULFILLED': save_fulfilled('profile'); break;
        case types.PROFILE + '_FULFILLED': fulfilled('profile'); break;
        case types.PROFILE + '_CLEAR': clear('profile', {}); break;

        case types.PROFILE + '_' + types.ACTIVITY_LOG + '_PENDING': pending('profile_activity_log'); break;
        case types.PROFILE + '_' + types.ACTIVITY_LOG  + '_FULFILLED': fulfilled('profile_activity_log'); break;
        case types.PROFILE + '_' + types.ACTIVITY_LOG  + '_CLEAR': clear('profile_activity_log', {}); break;
        
        case types.PROFILE + '_' + types.INVOICES + '_PENDING': pending('profile_invoices'); break;
        case types.PROFILE + '_' + types.INVOICES  + '_FULFILLED': fulfilled('profile_invoices'); break;
        case types.PROFILE + '_' + types.INVOICES  + '_CLEAR': clear('profile_invoices', {}); break;

        case types.PROFILES + '_PENDING': pending('profiles'); break;
        case types.PROFILES + '_SAVE_PENDING': save_pending('profiles'); break;
        case types.PROFILES + '_SAVE_FULFILLED': save_fulfilled('profiles'); break;
        case types.PROFILES + '_FULFILLED': fulfilled('profiles'); break;
        case types.PROFILES + '_CLEAR': clear('profiles', {}); break;

        case types.VENDOR + '_PENDING': pending('vendor'); break;
        case types.VENDOR + '_SAVE_PENDING': save_pending('vendor'); break;
        case types.VENDOR + '_SAVE_FULFILLED': save_fulfilled('vendor'); break;
        case types.VENDOR + '_FULFILLED': fulfilled('vendor'); break;
        case types.VENDOR + '_CLEAR': clear('vendor', {}); break;

		case types.ADDRESS + '_PENDING': pending('address'); break;
		case types.ADDRESS + '_SAVE_PENDING': save_pending('address'); break;
		case types.ADDRESS + '_FULFILLED': fulfilled('address'); break;
		case types.ADDRESS + '_CLEAR': state = { ...state, address: {} }; break;
		case types.ADDRESS + '_SELECTED': set('address_selected'); break;

        case types.CONTACT + '_PENDING': pending('contact'); break;
		case types.CONTACT + '_SAVE_PENDING': save_pending('contact'); break;
		case types.CONTACT + '_FULFILLED': fulfilled('contact'); break;
		case types.CONTACT + '_CLEAR': clear('contact', {}); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_save_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        };
    }
    function set(table) {
		state = { ...state,
			[table]: action.data,
		};
	}
};
export default ProfilesReducer;


