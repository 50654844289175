import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { APPS, WORKORDERS } from 'components/constants';
import { Avatar } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { imageSize } from 'components/common/toolbox';
import { navNotice } from 'components/common/toolbox';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const ResultsWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const workOrders = useSelector((store) => store.workOrders);

    const [data, setData] = useState([]);

    const orderedWorkOrders = _.orderBy(workOrders.work_orders_dashboard, ['_name'], ['asc']);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_work_orders.clearDashboardWorkOrders());
        } else {
            dispatch(actions_work_orders.subDashboardWorkOrders(params.handle, params.appId, params.widget_id));
        }
        
        return () => {
			const unsubscribe = workOrders.work_orders_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => {
        var work_orders = [];
        var currentEntityId = null;
        orderedWorkOrders?.forEach((workOrder) => {
            
            let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            let status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });

            // Add grouip heading for customer or asset
            if (entityId != currentEntityId) {
                currentEntityId = entityId;
                work_orders.push({ ...workOrder,
                    status: status?.name,
                    _jsx:
                    <div className="d-flex p-2" style={{ backgroundColor: '#cccccc' }}>
                        <Avatar className=""
                            color="white"
                            bgColor="saddleBrown"
                            size={45}
                            fontSize={'20px'}
                            name={(params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name}
                            image={imageSize(workOrder._imageUrl, 'sm')}
                            imageWidth={60}
                            border="4px solid white"
                            role={ (workOrder._imageUrl) ? "button" : null }
                            style={{ flexShrink: 0 }}
                        />
                        <div className="align-self-center">
                            <h2 className="ml-3 m-0 text-black font-weight-normal">{
                                ((params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name) + 
                                ((workOrder._unitNumber) ? ' #' + workOrder._unitNumber : '')
                            }</h2>
                            { workOrder._address &&
                                <p className="ml-3 m-0">{ workOrder._address }</p>
                            }
                        </div>
                    </div> 
                });
            }

            work_orders.push({ ...workOrder,
                status: status?.name,
                _stripe_color: status?.color,
            });
        });
        setData(work_orders);
    }, [workOrders.work_orders_dashboard]);

    /* Actions ----------------------------*/

    const openWorkOrder = async (workOrder) => {

        if (numPanels(1) && !user.dismissed?.navNotice) {
            navNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'navNotice'));
            }, () => redirect());
        } else redirect();
        
        function redirect() {
            let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            let location = toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`, 9);
            history.push({ pathname: location });
            setTimeout(() => {
                location = toolbox.modifyPath( location, 10, `${workOrder.id}/items`);
                history.push({ pathname: location });
            }, 300);
        }
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Start', field: 'startDate', type: 'timestamp', format: 'MMM Do', width: 25 },
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 75 }] : [],
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 75 }] : [],
        { name: 'Summary', field: 'description' },
    ];

    return (

        <div className="bg-white px-3 py-1">
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.tab2_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={ openWorkOrder }
                chevron={ true }
            >
            </Table>
        </div>
    );
};

export default ResultsWorkOrders;