import * as actions_timeclock from 'actions/timeclock-actions';
import React, { useEffect, useState } from 'react';
import TasksCheckin from './tasks-checkin';
import TimeCardModal from './timecard-modal';
import moment from 'moment';
import { Avatar } from 'enspire-manager-framework';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

export default function TimeClock(props) {

	const params = useParams();
    const dispatch = useDispatch();

    const settings = useSelector((store) => store.settings.settings);
    const users = useSelector((store) => store.users);
    const user = useSelector((store) => store.users.user);

    const name = user?.firstName + ' ' + user?.lastName;

    const [viewTasksCheckin, setViewTasksCheckin] = useState(false);
    const [viewTimesheet, setViewTimeCard] = useState(false);
    const [accumulated, setSeconds] = useState(0);
    const [currentTime, setCurrentTime] = useState();

    /* Effects --------------------------*/

    useEffect(() => {
        const interval = setInterval(() => {
            var seconds = users.time_clock?.secondsAccumulated ?? 0;
            if (users.time_clock?.clockedIn) {
                var newSeconds = moment().diff(moment(users.time_clock?.lastTimeIn.toDate()), 'seconds');
                setSeconds(seconds + newSeconds);
            } else {
                setSeconds(seconds);
            }
            setCurrentTime(moment());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [users.time_clock?.secondsAccumulated, users.time_clock?.clockedIn]);

    /* Actions --------------------------*/
	
    const handleClockIn = () => {
        dispatch(actions_timeclock.clockIn(params.handle, users));
	};
	const handleClockOut = () => {
		dispatch(actions_timeclock.clockOut(params.handle, users));
	};
    const timeTotal = moment.utc((accumulated) * 1000);

    return (
        <>
            <div className="row mt-3 mb-2 px-3">
                <div className="col-12 py-2" style={{ backgroundColor: '#dddddd', borderRadius: '12px' }}>
                    <div className="row">
                        <div className="col-3">
                            <Avatar className="" bgColor="darkOrange" color="white" size={70} fontSize={'30px'} name={name} image={user.photoUrl} border={'2px solid white'} />
                        </div>
                        <div className="col-5">
                            <p className="m-0 nowrap" style={{ fontSize: '24px' }}>
                                <i className="far fa-clock mr-2"></i>
                                {currentTime?.format('hh:mm') ?? '00:00'}
                                <span style={{ fontSize: '16px' }}>{currentTime?.format('a')}</span>
                            </p>
                            <p className="mb-3 mt-n2 nowrap" style={{ fontSize: '16px' }}>
                                <span className="" style={{ fontSize: '22px' }}>{ `${timeTotal?.format('H')}h ${timeTotal?.format('mm')}m ${timeTotal?.format('ss')}s` }</span>
                            </p>
                            { users.work_order_checked_in
                                ?   <p className="mb-0 mt-n2" style={{ fontSize: '14px' }}>
                                        { 'WO #' + users.work_order_checked_in.customId }
                                    </p>
                                :   <p className="mb-0 mt-n2 text-muted" style={{ fontSize: '12px' }}>
                                        { users.time_clock?.clockedIn && <>Clocked In: { moment(users.time_clock?.lastTimeIn?.toDate()).format('MMM D [at] h:mm A') }</>}
                                        { !users.time_clock?.clockedIn && <>Clocked Out: { moment(users.time_clock?.lastTimeOut?.toDate()).format('MMM D [at] h:mm A') }</>}
                                    </p>
                            }
                        </div>
                        <div className="col-4">
                            <div>
                                { !settings?.timeclock?.checkinTargetRequired
                                    ?   <>
                                            { !users.time_clock?.clockedIn &&
                                                <button className={ `btn btn-${(users.time_clock?.clockedIn)?'default':'success'} btn-sm btn-block` } 
                                                    onClick={handleClockIn}>Clock&nbsp;In</button>
                                            }
                                            { users.time_clock?.clockedIn &&
                                                <button className={ `btn btn-${(users.time_clock?.clockedIn)?'danger':'default'} btn-sm btn-block` } 
                                                    disabled={!users.time_clock?.clockedIn} onClick={handleClockOut}>Clock&nbsp;Out</button>
                                            }
                                        </>
                                    :   <div className="mt-2 pt-1" />
                                }
                                <button className={ `btn btn-info btn-sm btn-block` } onClick={() => setViewTasksCheckin(true)}>Tasks</button>
                                <button className={ `btn btn-default btn-sm btn-block` } onClick={() => setViewTimeCard(true)}>Time&nbsp;Card</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            { viewTasksCheckin &&
                <TasksCheckin setViewTasksCheckin={ setViewTasksCheckin } />
            }
            { viewTimesheet &&
                <TimeCardModal setViewTimeCard={ setViewTimeCard } />
            }
        </>

    );
};

