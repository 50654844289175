import React, { Fragment } from 'react';
import _ from 'lodash';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function service_request_fields(type, technicianId) {

	return (
		{
			assetId: '',
			assetTypeId: '',
			customerId: '0',
			description: '',
			imageUrl: '',
			imagesAlt: [],
			requestDate: new Date(),
			serviceDescription: 'See Attached Invoice',
			miles: '',
			hours: '',
			technicianId: technicianId,
			suggested: '',
			type: type,
			vendorId: [],
		}
	);
};

export function service_request_form_layout({
	assetAvatar,
	firebase, 
	handle, 
	serviceRequest, 
	technicians,
	user_permissions,
}) {

	const technician_options = _.orderBy(technicians, ['contact.lastName'], ['asc']).map((tech, index) => {
		return (
			<option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
		);
	});
	const isRequest = (serviceRequest?.type == 'asset_request');
	const serviceType = (isRequest) ? 'Request' : 'Record';

	return (
		[{
			column_class: 'col-md-7',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">{`Asset Information`}</h3>
						{ assetAvatar }
						<h3 className="mt-4 m-b">{`Service ${serviceType} Details`}</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: (isRequest) ? 'Description of Issue' : 'Service Description',
					field: 'description',
					type: 'textarea',
					valid: ['required'],
				}, {
					grid: 'col-12',
					label: `${serviceType} Date`,
					field: 'requestDate',
					type: 'date',
					dateFormat: 'MM-DD-YYYY',
					showTimeSelect: true,
					valid: ['required'],
					selectsStart: true,
					readOnly: !user_permissions.ADMINISTRATOR,
				}, {
					grid: 'col-6 col-sm-3',
					label: 'Miles',
					field: 'miles',
					append: 'mi',
					valid: ['numeric'],
					type: 'text',
				}, {
					grid: 'col-6 col-sm-3',
					label: 'Hours',
					field: 'hours',
					append: 'hrs',
					valid: ['numeric'],
					type: 'text',
				}, {
					grid: 'col-sm-6',
					label: 'Technician',
					field: 'technicianId',
					type: (isRequest) ? 'select' : 'skip',
					options:
						<Fragment>
							<option key={'none'} value={''}>- Select -</option>
							{technician_options}
						</Fragment>,
				}, {
					grid: 'col-sm-12 mt-2',
					label: 'Asset is Offsite',
					field: 'offsite',
					type: 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Service Provider / Vendor',
					field: 'vendorId',
					placeholder: 'Search for Vendor',
					type: (isRequest) ? 'skip' : 'typeahead',
					options: {
						allow_new: true,
						fields: ['displayName', 'addresses', 'contacts'],
						id: 'id',
						sort: [{ "displayName": "asc" }],
						table: 'mt-' + handle + (isDev ? '-dev' : '') + '-profiles',
						target: 'displayName',
						type_filter: 'vendor',
					},
				}, {
					grid: 'col-6',
					label: 'Service Cost',
					field: 'totalCost',
					prepend: '$',
					valid: ['numeric', 'required'],
					inputMode: 'decimal',
					type: (isRequest) ? 'skip' : 'usd',
				}, {
					grid: 'col-12',
					label: 'Suggested Solution',
					field: 'suggested',
					type: (isRequest) ? 'textarea' : 'skip',
				}],
			}]
		}, {
			column_class: 'col-md-5',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Main Service Request Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Service Request Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'service-requests',
						className: 'mb-3',
						height: "280px",
						multiple: true
					}
				}],
			}],
		}]
	);
};