import MyWorkOrders from 'components/work-orders/my-work-orders';
import React from 'react';
import ResultsWorkOrders from '../components/results-work-orders';
import { APPS, COUNTS } from 'components/constants';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Expanding } from 'components/common/expanding';
import { Widget } from 'components/dashboard/components/widget';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MyTasks(props) {

	const params = useParams();

    const counts = useSelector((store) => store.admin.dashboard_counts);
    const workOrders = useSelector((store) => store.workOrders.work_orders_technician);

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-6 p-1">
				<WidgetTask 
					active={ params.widget_id == 'my-workorders' }
					backgroundColor={ 'powderblue' } 
					badge={ workOrders?.length }
					icon={ <i className="fa-duotone fa-solid fa-file-user fa-3x"></i> }
					text={ 'My Work Orders' }
					value="my-workorders"
					onClick={ props.handleClick }
				/>
			</div>

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'my-workorders' }>
				<MyWorkOrders source="dashboard" />
			</Expanding>

		</div>
	);
};
