import React, { Fragment } from 'react';
import { createHandle, toProperCase } from 'components/common/toolbox';
import { ADDITIONAL_FIELDS } from 'components/constants';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function attachment_fields(assetTypeId) {

	return (
		{
			name: '',
			expiration: null,
			alertThreshold: '0',
			imageUrl: '',
		}
	);
}
export function attachment_form_layout({
	attachment, 
	expires,
	firebase, 
	handle, 
	handlePromote,
	invoice,
}) {

	var altImages = [];

	for (let index = 0; index < 6; index++) {
		if (attachment['imageUrlAlt' + index]) {
			altImages.push({
				grid: 'col-sm-6 col-md-12 col-xl-6 mt-3',
				label: '',
				field: 'imageUrlAlt' + index,
				type: 'dropzone',
				disabled: (!attachment.imageUrl),
				dropzone: {
					camera: true,
					storage: 'firebase',
					storageRef: firebase.storage().ref(),
					bin: handle,
					directory: 'attachment',
					className: 'mb-3',
					height: "180px",
					multiple: false,
					resultsArray: true,
					onPromote: handlePromote,
				}
			});
		}
	}

	// { name: '', field: 'imageUrls', width: '20', edit: { 
	// 	bin: params.handle,
	// 	buttonTitle: 'View',
	// 	callback: handleEditDropzone, 
	// 	camera: true,
	// 	directory: `attachments/${props.store}${props.id}`,
	// 	firebase: firebase,
	// 	lightboxCallback: toggleLightbox,
	// 	multiple: true,
	// 	type: 'dropzone',
	// 	uploadTitle: <strong className="text-nowrap">+ <i className={'fa fa-camera'}></i></strong>,
	// 	viewTitle: <i className={'fa fa-camera'}></i>,
	// }},

	return (
		[{
			column_class: 'col-xl-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Attachment Details</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Name',
					field: 'name',
					type: 'text',
					readOnly: attachment.isDefault,
					valid: ['required']
				}, {
					grid: 'col-12',
					label: 'Price',
					field: 'price',
					prepend: '$',
					type: (invoice) ? 'usd' : 'skip',
					valid: ['required']
				}, {
					grid: 'col-md-6',
					label: 'Expiration Date',
					field: 'expiration',
					type: (expires) ? 'date' : 'skip',
					clearButton: true,
				}, {
					grid: 'col-md-6',
					label: 'Alert Threshold',
					field: 'alertThreshold',
					type: (expires) ? 'select' : 'skip',
					options:
						<Fragment>
							<option value="0">No Alert</option>
							<option value="1">7 days</option>
							<option value="2">30 days</option>
							<option value="3">60 days</option>
						</Fragment>,
				}],
			}],
		}, {
			column_class: 'col-xl-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Main Asset Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Asset Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'assets',
						className: 'mb-3',
						height: "280px",
						multiple: true,
					}
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none mb-0">Additional Images</h3>
					</Fragment>,
				layout: [ ...(altImages) ],
			}],
		}]
	);
}