import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Input } from 'enspire-manager-framework';
import { numPanels } from 'components/common/toolbox';

var _ = require('lodash');
var moment = require('moment');

export default function DateRange(props) {

    const [state, setState] = useState({
        showOverlay: false,
        startDate: moment().subtract(30, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        rangeName: 'null',
    });

    useEffect(() => {
        handlePreset((props.range) ? props.range : 'last30');
    }, []);

    const handleDate = (name, date, context) => {
        setState(prev => ({ ...prev, [name]: date, fetched: false, rangeName: props.buttonTitle }));
        setState(prev => ({ ...prev, rangeName: 'Custom' }));
    }
    const handleSubmit = () => {
        if (props.callback) props.callback(state.startDate, state.endDate);
        setState(prev => ({ ...prev, showOverlay: false }));
    }
    const handlePreset = (value) => {
        var startDate;
        var endDate;
        var rangeName;

        switch (value) {
            case 'thisWeek':
                startDate = moment().startOf('week').toDate();
                endDate = moment().endOf('week').toDate();
                rangeName = 'This Week';
                break;
            case 'lastWeek':
                startDate = moment().subtract(1, 'week').startOf('week').toDate();
                endDate = moment().subtract(1, 'week').endOf('week').toDate();
                rangeName = 'Last Week';
                break;
            case 'thisMonth':
                startDate = moment().startOf('month').toDate();
                endDate = moment().endOf('month').toDate();
                rangeName = 'This Month';
                break;
            case 'lastMonth':
                startDate = moment().subtract(1, 'month').startOf('month').toDate();
                endDate = moment().subtract(1, 'month').endOf('month').toDate();
                rangeName = 'Last Month';
                break;
            case 'last30':
                startDate = moment().subtract(30, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 30 Days';
                break;
            case 'last60':
                startDate = moment().subtract(60, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 60 Days';
                break;
            case 'last90':
                startDate = moment().subtract(90, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 90 Days';
                break;
            case 'last120':
                startDate = moment().subtract(120, 'days').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 120 Days';
                break;
            case 'last6Months':
                startDate = moment().subtract(6, 'months').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 6 Months';
                break;
            case 'last12Months':
                startDate = moment().subtract(12, 'months').startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'Last 12 Months';
                break;
            case 'thisYear':
                startDate = moment().startOf('year').toDate();
                endDate = moment().endOf('year').toDate();
                rangeName = 'This Year';
                break;
            case 'yeartodate':
                startDate = moment().startOf('year').toDate();
                endDate = moment().endOf('day').toDate();
                rangeName = 'This Year to Date';
                break;
        }
        setState(prev => ({ ...prev, startDate, endDate, rangeName, showOverlay: false }));
    }

    return (

        <div className="mt-n3">
            <div className={'row animated fadeInRight animation-delay-4' + props.className}>
                <div className="col-12"><p className="mb-1">Date Range</p></div>
                <div className={'col-md-8 input-group input-group-sm date'}>
                    <Input
                        className={`pr-0 ${numPanels(1)?'col-6 px-0':''}`}
                        dateFormat="MM-DD-YYYY"
                        minDate={moment('2000-01-01').toDate()}
                        maxDate={state.endDate}
                        name={'startDate'}
                        noLabel={true}
                        onChange={handleDate}
                        prepend={ <i className="fa-regular fa-calendar-day"></i> }
                        type="date"
                        value={state.startDate}
                    />
                    <Input
                        className={`pr-0 ${numPanels(1)?'col-6 px-0':''}`}
                        dateFormat="MM-DD-YYYY"
                        minDate={state.startDate}
                        maxDate={moment('2100-01-01').toDate()}
                        name={'endDate'}
                        noLabel={true}
                        onChange={handleDate}
                        type="date"
                        value={state.endDate}
                    />
                </div>

                {props.showPresets

                    ?   <div className={'col-4'}>

                            <DropdownButton 
                                title=<span>
                                    {state.rangeName}
                                    {props.rendering &&
                                        <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                    }
                                </span> 
                                variant="primary" size="sm" className="d-inline ml-2 mb-2"
                                onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                            >
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'thisWeek', 'This Week')}>This Week</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'lastWeek', 'Last Week')}>Last Week</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'thisMonth', 'This Month')}>This Month</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'lastMonth', 'Last Month')}>Last Month</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last60', 'Last 60 Days')}>Last 60 Days</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last90', 'Last 90 Days')}>Last 90 Days</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last120', 'Last 120 Days')}>Last 120 Days</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last6Months', 'Last 6 Months')}>Last 6 Months</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last12Months', 'Last 12 Months')}>Last 12 Months</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'thisYear', 'This Year')}>This Year</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'yeartodate', 'Year to Date')}>Year to Date</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    :   <div className="col-lg-4 pt-1">
                            <button className={'btn btn-primary btn-xs'} onClick={handleSubmit}>
                                {props.buttonTitle}
                                {props.rendering &&
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                }
                            </button>
                        </div>
                }
                {/* </div> */}
            </div>

            {props.children &&
                <div>
                    {(!Array.isArray(props.children)) ? React.cloneElement(props.children, { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[0]) ? React.cloneElement(props.children?.[0], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[1]) ? React.cloneElement(props.children?.[1], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[2]) ? React.cloneElement(props.children?.[2], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[3]) ? React.cloneElement(props.children?.[3], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                </div>
            }
        </div>
    );
};