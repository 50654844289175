// import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as actions_invoices from 'actions/invoices-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import StatusKey from 'components/common/status-key';
import { APPS, INVOICES, WORKORDERS } from 'components/constants';
import { Ibox, ModalAlert } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

var _ = require('lodash');

export default function InvoicesTab(props) {
    
    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const seg = 9
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const routeLocation = useLocation();

    // const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
    const assets = useSelector((store) => store.assets.assets);
    const invoices = useSelector((store) => store.invoices);
    const settings = useSelector((store) => store.settings);
    const table = useSelector((store) => store[props.store]);
    const user_permissions = useSelector((store) => store.users.user_permissions);
    const workOrders = useSelector((store) => store.workOrders);

    const [timer, setTimer] = useState(null);
    const [canAuto, setCanAuto] = useState(true);

    const fromAssetDashboard = props.source == 'customers';
    const segments = (fromAssetDashboard) ? seg : seg+2;
    const taxRates = settings?.settings?.invoices?.tax_rates;
    const invoicesList = table?.[props.singular + '_invoices'] ?? [];

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    // Subscribe to Invoices based on different fields
    useEffect(() => {

        let field, id;
        switch(props.source) {
            case 'assets' : field = 'assetId'; id = params.asset_id; break;
            case 'service-requests' : field = 'requestId'; id = params.request_id; break;
            case 'profiles' : field = 'customerId'; id = params.customer_id; break;
        }
        dispatch(actions_invoices.subInvoicesByField(params.handle, field, id, props.prefix));

        return () => {
			const unsubscribe = table[props.singular + '_invoices_unsubscribe'];
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.asset_id, params.request_id, params.customer_id]);

    useEffect(() => { setCanAuto(true); }, [params.tab]);

    // Automatically advance to the first item
    useEffect(() => {
        if (
            numPanels(3) && canAuto &&
            props.source == 'service-requests' &&
            params.tab == 'invoices' && 
            invoicesList?.length && 
            !params.invoice_id
        ) {
            if (timer) clearTimeout(timer);
            setTimer(setTimeout(() => {
                let location = toolbox.modifyPath(routeLocation.pathname, seg, 'invoices/preview/' + invoicesList[0]?.id + '/payments');
                props.history.push({ pathname: location });
            }, 300));
        }
        setTimeout(() => { setCanAuto(false) }, 2000);
    }, [params.tab, invoicesList]);

    /* Handlers/Actions ------------------------------------------------------------------------------------------------------------------------------------*/

    const confirmDelete = (invoice) => {
        ModalAlert({
            title: 'Are you sure?',
            text: "This Invoice will be permanently deleted and associated Work Orders will be unlinked and made available for new Invoices.",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, delete it!',
            callback_success: () => handleDelete(invoice),
        });
    };

    const handleDelete = (invoice) => {
        dispatch(actions_invoices.deleteInvoice(params.handle, invoice, () => {
            let location = toolbox.modifyPath(routeLocation.pathname, seg-2, `service_requests/${invoice.requestId}/invoices`, seg);
            props.history.replace({ pathname: location });

            // if (invoice?.quickbooksId) {
            //     dispatch(actions_quickbooks.updateQuickbooks({
            //         handle: params.handle,
            //         type: 'invoice',
            //         change: 'delete',
            //         id: invoice.quickbooksId,
            //     }, quickbooksSettings));
            // }
        }));
    };

    const handleClick = (invoice) => {
        const changeHistory = (routeLocation.pathname.includes('preview'))
            ? history.replace
            : history.push;

        switch(props.source) {
            case 'assets' :
                var location = toolbox.modifyPath(routeLocation.pathname, seg-2, `service_requests/${invoice.requestId}/invoices/preview/${invoice.id}/payments`);
                changeHistory({ pathname: location });
                break;
            case 'service-requests' :
                var location = toolbox.modifyPath(routeLocation.pathname, seg+1, `preview/${invoice.id}/payments`);
                changeHistory({ pathname: location });
                break;
            case 'profiles' :
                if (params.appId == APPS.SERVICE.id) {
                    var location = toolbox.modifyPath(routeLocation.pathname, seg-2, `service_requests/${invoice.requestId}/invoices/preview/${invoice.id}/payments`);
                    changeHistory({ pathname: location });
                } else if (params.appId == APPS.ASSETS.id) {
                    var asset = _.find(assets, (a) => { return a.id == invoice.assetId})
                    var location = toolbox.modifyPath(routeLocation.pathname, seg-6, `assets/${asset.id}/form/0/service_requests/${invoice.requestId}/invoices/preview/${invoice.id}/payments`);
                    changeHistory({ pathname: location });
                }
                break;
        }
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const columns = [
        { name: 'ID', field: 'id', nowrap: true, width: 20 },
        { name: 'Modified', field: 'modified', type: 'date', format: 'MMM Do, YYYY', width: 40 },
        { name: 'Open Balance', field: 'openBalance', type: 'number', format: 'usd', text_align: 'right', width: 40 },
        { name: 'Memo', field: 'memo' },
    ];

    const data = invoicesList?.map((invoice) => ({ ...invoice,
        openBalance: parseFloat(invoice.serviceItems.reduce((a, b) => {
            return a + parseFloat(b.calculatedPrice);
        }, 0) - (invoice?.applied ?? 0)).toFixed(2),
        _stripe_color: Object.values(INVOICES).find((item) => item.id == invoice.statusId)?.color
    }));
    
    const statusStyle = { position: 'absolute', top: '-36px', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' };

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (

        <Ibox className="" title={'Invoices'} show_spinner={invoices.invoices_pending} no_fade={false}>
            <Table
                id="invoices-tab"
                data={data}
                show_limit={false}
                show_search={true}
                active_id={params.invoice_id}
                delete={user_permissions?.INVOICES_EDIT}
                on_delete={confirmDelete}
                second_line={'memo'}
                active_field={'id'}
                chevron={true}
                highlight_search={true}
                max_height={'135px'}
                columns={columns}
                click_callback={handleClick}
            />

        </Ibox>

    );
};