// AUTHENTICATION

export const APP = 'APP';
export const ACTIVITY_LOG = 'ACTIVITY_LOG';
export const ACTIVITY_LOG_USER = 'ACTIVITY_LOG_USER';

export const ADDRESS = 'ADDRESS';

export const ARCHIVED_ASSETS = 'ARCHIVED_ASSETS';
export const ARCHIVED_ASSET_TYPES = 'ARCHIVED_ASSET_TYPES';
export const ARCHIVED_PROFILES = 'ARCHIVED_PROFILES';
export const ARCHIVED_SERVICE_ITEMS = 'ARCHIVED_SERVICE_ITEMS';
export const ARCHIVED_SERVICE_REQUESTS = 'ARCHIVED_SERVICE_REQUESTS';
export const ARCHIVED_INVENTORY = 'ARCHIVED_INVENTORY';
export const ARCHIVED_WORK_ORDERS = 'ARCHIVED_WORK_ORDERS';
export const ARCHIVED_QUOTES = 'ARCHIVED_QUOTES';

export const ASSET = "ASSET";
export const ASSETS = "ASSETS";
export const ASSET_INVOICES = 'ASSET_INVOICES';
export const ASSET_TYPE = "ASSET_TYPE";

export const ATTACHMENT = 'ATTACHMENT';
export const ATTACHMENTS = 'ATTACHMENTS';
export const ATTACHMENTS_SPINNER = 'ATTACHMENTS_SPINNER';

export const BOTTOM_SHEET_SHOW = 'BOTTOM_SHEET_SHOW';

export const CHECKIN = 'CHECKIN';
export const CHECKIN_TIME = 'CHECKIN_TIME';

export const COMPANY = 'COMPANY';
export const CONTACT = 'CONTACT';
export const CONVERSATIONS = "CONVERSATIONS";

export const CUSTOMER = 'CUSTOMER';
export const CUSTOMERS = 'CUSTOMERS';
export const CUSTOMER_FROM_SEARCH = 'CUSTOMER_FROM_SEARCH';
export const CUSTOMER_MAPBOX_SEARCH = 'CUSTOMER_MAPBOX_SEARCH';
export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH';

export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_COUNTS = 'DASHBOARD_COUNTS';

export const DEPARTMENTS = 'DEPARTMENTS';
export const DEPARTMENT_TAGS = 'DEPARTMENT_TAGS';

export const EMPLOYEE = 'EMPLOYEE';
export const EMPLOYEES = 'EMPLOYEES';

export const ERROR_LOG = 'ERROR_LOG';
export const EXPIRING_DOCS = 'EXPIRING_DOCS';
export const EXTERNAL_INVOICES = 'EXTERNAL_INVOICES';

export const FLUTTER_KEYBOARD = 'FLUTTER_KEYBOARD';
export const FLUTTER_PLATFORM = 'FLUTTER_PLATFORM';

export const HELP = 'HELP';

export const INVOICE = 'INVOICE';
export const INVOICES = 'INVOICES';

export const JOB_CATEGORIES = 'JOB_CATEGORIES';

export const LIGHTBOX_INDEX = 'LIGHTBOX_INDEX';
export const LIGHTBOX_SHOW = 'LIGHTBOX_SHOW';
export const LIGHTBOX_SOURCES = 'LIGHTBOX_SOURCES';

export const LOGOUT = "LOGOUT";

export const MESSAGES = "MESSAGES";

export const NEW_WORK_ORDER = 'NEW_WORK_ORDER';
export const NEW_WORK_ORDER_DRAG_END = 'NEW_WORK_ORDER_DRAG_END';
export const NEW_WORK_ORDER_DRAG_MOVE = 'NEW_WORK_ORDER_DRAG_MOVE';
export const NEW_WORK_ORDER_DRAG_START = 'NEW_WORK_ORDER_DRAG_START';

export const PAYMENTS = 'PAYMENTS';

export const PROCUREMENT = 'PROCUREMENT';
export const PROCUREMENT_PRICING = 'PROCUREMENT_PRICING';
export const PROCUREMENT_SETTINGS = 'PROCUREMENT_SETTINGS';

export const PROFILE = 'PROFILE';
export const PROFILES = 'PROFILES';

export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const PURCHASE_ORDERS = 'PURCHASE_ORDERS';

export const QUICKBOOKS = 'QUICKBOOKS';
export const QUICKBOOKS_IMPORT = 'QUICKBOOKS_IMPORT';
export const QUICKBOOKS_SYNC = 'QUICKBOOKS_SYNC';

export const QUOTE = 'QUOTE';
export const QUOTE_APPROVED = 'QUOTE_APPROVED';
export const QUOTE_ATTACHMENTS = 'QUOTE_ATTACHMENTS';
export const QUOTE_ITEMS = 'QUOTE_ITEMS';
export const QUOTES = 'QUOTES';
export const QUOTES_DASHBOARD = 'QUOTES_DASHBOARD';

export const REQUEST_OFF = 'REQUEST_OFF';

export const SEND_EMAIL = 'SEND_EMAIL';

export const SERVICE_ITEM = 'SERVICE_ITEM';
export const SERVICE_ITEMS = 'SERVICE_ITEMS';
export const SERVICE_ITEM_CATEGORIES = 'SERVICE_ITEM_CATEGORIES';
export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_REQUEST_MAPBOX_SEARCH = 'SERVICE_REQUEST_MAPBOX_SEARCH';
export const SERVICE_REQUEST_TERMS = 'SERVICE_REQUEST_TERMS';
export const SERVICE_REQUESTS = 'SERVICE_REQUESTS';
export const SERVICE_REQUESTS_DASHBOARD = 'SERVICE_REQUESTS_DASHBOARD';

export const SETTINGS = 'SETTINGS';
export const SETTINGS_ASSET_CLASSIFICATIONS = 'SETTINGS_ASSET_CLASSIFICATIONS';
export const SETTINGS_ASSET_TYPES = 'SETTINGS_ASSET_TYPES';
export const SETTINGS_COMPANY = 'SETTINGS_COMPANY';
export const SETTINGS_GLOBAL = 'SETTINGS_GLOBAL';
export const SETTINGS_PARTS_CATEGORIES = 'SETTINGS_PARTS_CATEGORIES';
export const SETTINGS_INVENTORY = 'SETTINGS_INVENTORY';
export const SETTINGS_TOGGLE_COLLAPSED = 'SETTINGS_TOGGLE_COLLAPSED';
export const SETTINGS_STATUS_MODAL_OPEN = 'SETTINGS_STATUS_MODAL_OPEN';
export const SETTINGS_STATUS_MODAL_CLOSE = 'SETTINGS_STATUS_MODAL_CLOSE';
export const SETTINGS_TASKS = 'SETTINGS_TASKS';

export const INVENTORY_ITEM = "INVENTORY_ITEM";
export const INVENTORY = "INVENTORY";

export const TASK_CHECKED_IN = 'TASK_CHECKED_IN';

export const TIME_CARDS = 'TIME_CARDS';
export const TIME_CLOCK = 'TIME_CLOCK';
export const TIME_ENTRIES = 'TIME_ENTRIES';
export const TIME_SHEET = 'TIME_SHEET';
export const TIME_SHEETS = 'TIME_SHEETS';

export const UNREAD_MESSAGES = "UNREAD_MESSAGES";
export const UPDATE_BUTTON = 'UPDATE_BUTTON';

export const USER = 'USER';
export const USERS = 'USERS';
export const USER_ACTIVATED = 'USER_ACTIVATED';
export const USER_LOCATIONS = 'USER_LOCATIONS';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';
export const USER_RECENT_ITEMS = 'USER_RECENT_ITEMS';

export const VENDOR = "VENDOR";
export const VENDORS = "VENDORS";
export const VENDOR_SELECTED = "VENDOR_SELECTED";

export const WORK_ORDER = 'WORK_ORDER';
export const WORK_ORDER_CHECKED_IN = 'WORK_ORDER_CHECKED_IN';
export const WORK_ORDER_DRAG_END = 'WORK_ORDER_DRAG_END';
export const WORK_ORDER_DRAG_MOVE = 'WORK_ORDER_DRAG_MOVE';
export const WORK_ORDER_DRAG_START = 'WORK_ORDER_DRAG_START';
export const WORK_ORDER_INVOICES = 'WORK_ORDER_INVOICES';
export const WORK_ORDER_ITEMS = 'WORK_ORDER_ITEMS';
export const WORK_ORDER_POPUP = 'WORK_ORDER_POPUP';
export const WORK_ORDERS = 'WORK_ORDERS';
export const WORK_ORDERS_DASHBOARD = 'WORK_ORDERS_DASHBOARD';
export const WORK_ORDERS_INCOMPLETE = 'WORK_ORDERS_INCOMPLETE';
export const WORK_ORDERS_OPEN = 'WORK_ORDERS_OPEN';
export const WORK_ORDERS_TECHNICIAN = 'WORK_ORDERS_TECHNICIAN';
