import * as toolbox from 'components/common/toolbox';
import ActivityLog from 'components/activity-log/activity-log';
import AttachmentsTab from 'components/attachments/attachments-tab';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import InvoicesTab from 'components/invoices/invoices-tab';
import PaymentsTab from 'components/payments/payments-tab';
import QuotesTab from 'components/quotes/quotes-tab';
import React, { useEffect } from 'react';
import WorkOrdersTab from 'components/work-orders/work-orders-tab';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { LOG, PERMISSIONS, SERVICE_REQUEST_TERMS, QUOTES } from 'components/constants';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { numPanels } from 'components/common/toolbox';
import { useSelector } from 'react-redux';

const firestore = firebase.firestore();

export default function ServiceRequestTabs(props) {
	
	/* Handlers --------------------------*/
	
	const seg = 9;
	const match = useRouteMatch();
    const params = useParams();
	const history = useHistory();
	const routeLocation = useLocation();

	const service_request = useSelector((store) => store.serviceRequests.service_request);
	const user = useSelector(store => store.users?.user);
    const workOrders = useSelector((store) => store.workOrders);

	const segments = routeLocation.pathname.split('/').length;
	const isRecord = (service_request?.type == 'asset_record');
    const work_order = _.find(workOrders, { serviceRequestId: params.request_id });

	// If tab is not specified, select the first appropriate. If auto, send auto to next tab
	useEffect(() => {
		const checkQuotes = async () => {
			const quoteDocs = await firestore.collection(params.handle + '/quotes/quotes')
				.where('serviceRequestId', '==', service_request.id)
				.where('statusId', '==', QUOTES.PENDING.id)
				.where('deleted', '==', false).get();
			var pendingQuotes = [];
			quoteDocs.forEach((doc) => {
				pendingQuotes.push({ ...doc.data(), id: doc.id });
			});

			const tab2 = (params.tab == 'auto') ? '/auto' : '';
			const tab = (pendingQuotes.length) ? `quotes${tab2}` : `work_orders${tab2}`;

			let location = toolbox.modifyPath(routeLocation?.pathname, seg, tab, seg+1);
			history.replace({ pathname: location });	
		}
		
		if (['tab', 'auto'].includes(params.tab) && service_request?.id) checkQuotes();
	}, [params.tab, service_request]);

	function tab_active(value) {
		return (params.tab === value) ? ' active' : ''; // returns 'active' if value tab is active
	}
	function link_tab(value, event) {
		event.preventDefault();
		let num = (segments >= 12) ? -1 : 0;
		if (num) history.go(num);

		setTimeout(() => {
			history.replace(toolbox.modifyPath(props.location.pathname, seg, value, seg+1));
		}, 50);
	}

	return (
		<>
			{ !isRecord &&

				<Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW } or={ work_order?.technicianId == user?.profileId }>
					<div className="tabs-container my-4 position-relative">
						<ul className={ `nav nav-tabs underline ${(numPanels(1))?'scroll-tabs':''}` } role="tablist">
							<Authorize permKeys={ [PERMISSIONS.WORK_ORDERS_VIEW.key, PERMISSIONS.WORK_ORDERS_ASSIGNED_VIEW.key] }>
								<li><a className={'nav-link' + tab_active('work_orders')} onClick={link_tab.bind(this, 'work_orders/auto')}>Work Orders</a></li>
							</Authorize>
							<Authorize perm={ PERMISSIONS.QUOTES_VIEW }>
								<li><a className={'nav-link' + tab_active('quotes')} onClick={link_tab.bind(this, 'quotes/auto')}>Quotes</a></li>
							</Authorize>
							<Authorize perm={ PERMISSIONS.INVOICES_VIEW }>
								<li><a className={'nav-link' + tab_active('invoices')} onClick={link_tab.bind(this, 'invoices')}>Invoices</a></li>
							</Authorize>
							<Authorize perm={ PERMISSIONS.PAYMENTS_VIEW }>
								<li><a className={'nav-link' + tab_active('payments')} onClick={link_tab.bind(this, 'payments')}>Payments</a></li>
							</Authorize>
							<Authorize perm={ PERMISSIONS.ATTACHMENT_VIEW }>
								<li><a className={'nav-link' + tab_active('attachments')} onClick={link_tab.bind(this, 'attachments')}>Attachments</a></li>
							</Authorize>
							<Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
								<li><a className={'nav-link' + tab_active('activity')} onClick={link_tab.bind(this, 'activity')}>Activity</a></li>
							</Authorize>
						</ul>

						<div className="tab-content">

							<Authorize permKeys={ [PERMISSIONS.WORK_ORDERS_VIEW.key, PERMISSIONS.WORK_ORDERS_ASSIGNED_VIEW.key] }>
								<div role="tabpanel" className={'tab-pane' + tab_active('work_orders')}>
									<div className="panel-body">
										<Route path={match.path} render={(route_props) =>
											<CatchErrors>
												<WorkOrdersTab {...route_props} source={ props.source} />
											</CatchErrors>
										} />
									</div>
								</div>
							</Authorize>
							<Authorize perm={ PERMISSIONS.QUOTES_VIEW }>
								<div role="tabpanel" className={'tab-pane' + tab_active('quotes')}>
									<div className="panel-body">
										<Route path={match.path} render={(route_props) =>
											<CatchErrors>
												<QuotesTab {...route_props} source="service-requests" />
											</CatchErrors>
										} />
									</div>
								</div>
							</Authorize>
							<Authorize perm={ PERMISSIONS.INVOICES_VIEW }>
								<div role="tabpanel" className={'tab-pane' + tab_active('invoices')}>
									<div className="panel-body">
										<Route path={match.path + '/:invoice_id?'} render={(route_props) =>
											<CatchErrors>
												<InvoicesTab {...route_props}
													prefix="SERVICE_REQUEST" 
													singular='service_request' 
													source='service-requests' 
													store="serviceRequests" 
												/>
											</CatchErrors>
										} />
									</div>
								</div>
							</Authorize>
							<Authorize perm={ PERMISSIONS.PAYMENTS_VIEW }>
								<div role="tabpanel" className={'tab-pane' + tab_active('payments')}>
									<div className="panel-body">
										<Route path={match.path + '/:payment_id?'} render={(route_props) =>
											<CatchErrors>
												<PaymentsTab {...route_props} source='service-requests' />
											</CatchErrors>
										} />
									</div>
								</div>
							</Authorize>
							<Authorize perm={ PERMISSIONS.ATTACHMENT_VIEW }>
								<div role="tabpanel" className={'tab-pane' + tab_active('attachments')}>
									<div className="panel-body">
										<Route path={match.path} render={(route_props) =>
											<CatchErrors>
												<AttachmentsTab {...route_props} 
													id={ params.request_id } 
													columnId={ (props.source == 'service')? "column2" : "column3"}
													name="Service Request" 
													prefix="SERVICE_REQUEST"
													singular="service_request" 
													source='service-requests' 
													store="serviceRequests" 
													setFocused={ props.setFocused }
												/>
											</CatchErrors>
										} />
									</div>
								</div>
							</Authorize>
							<Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
								<div role="tabpanel" className={'tab-pane' + tab_active('activity')}>
									<div className="panel-body">
										<Route path={props.match.path} render={(route_props) =>
											<CatchErrors>
												<ActivityLog {...route_props} 
													id="request_id" 
													prefix="SERVICE_REQUEST" 
													singular="service_request"
													store="serviceRequests" 
													type={ `${LOG.SERVICE_REQUEST.key}` } 
												/>
											</CatchErrors>
										} />
									</div>
								</div>
							</Authorize>
						</div>
					</div>
				</Authorize>
			}
		</>
	);
};