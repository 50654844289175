import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { APPS, QUOTES } from 'components/constants';
import { Avatar } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { imageSize } from 'components/common/toolbox';
import { navNotice, numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

var moment = require('moment');

const ResultsQuotes = (props) => {
    
    /* Hooks -----------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const profiles = useSelector((store) => store.profiles);
    const quotes = useSelector((store) => store.quotes);;
    const settings = useSelector((store) => store.settings.settings);;
    const user = useSelector((store) => store.users.user);

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_quotes.clearDashboardQuotes());
        } else {
            dispatch(actions_quotes.subDashboardQuotes(params.handle, params.widget_id));
        }
        
        return () => {
			const unsubscribe = quotes.quotes_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => {
		if (parseInt(params.customer_id)) dispatch(actions_profiles.subProfile(params.handle, params.customer_id, 'PROFILE'));
		return () => {
			let unsubscribe = profiles.profile_unsubscribe;
			if (typeof unsubscribe === "function") unsubscribe();
			dispatch(actions_profiles.clearProfile());
		};
	}, [params.customer_id]);

    useEffect(() => {    
        var quotes_array = [];
        var currentEntityId = null;
        quotes.quotes_dashboard?.forEach((quote) => {
            
            let entityId = (params.appId == APPS.SERVICE.id) ? quote.customerId : quote.assetId;
            let status = _.find(Object.values(QUOTES), { id: quote.statusId });

            // Add grouip heading for customer or asset
            if (entityId != currentEntityId) {
                currentEntityId = entityId;
                quotes_array.push({ ...quote,
                    status: status?.name,
                    _jsx:
                    <div className="d-flex p-2" style={{ backgroundColor: '#cccccc' }}>
                        <Avatar className=""
                            color="white"
                            bgColor="saddleBrown"
                            size={45}
                            fontSize={'20px'}
                            name={(params.appId == APPS.SERVICE.id) ? quote._displayName : quote._name}
                            image={imageSize(quote._imageUrl, 'sm')}
                            imageWidth={60}
                            border="4px solid white"
                            role={ (quote._imageUrl) ? "button" : null }
                            style={{ flexShrink: 0 }}
                        />
                        <div className="align-self-center">
                            <h2 className="ml-3 m-0 text-black font-weight-normal">{
                                ((params.appId == APPS.SERVICE.id) ? quote._displayName : quote._name) + 
                                ((quote._unitNumber) ? ' #' + quote._unitNumber : '')
                            }</h2>
                            { quote._address &&
                                <p className="ml-3 m-0">{ quote._address }</p>
                            }
                        </div>
                    </div> 
                });
            }
            
            quotes_array.push({ ...quote,
                status: status?.name,
                _stripe_color: status?.color,
                expiration: moment(quote.effective?.seconds, 'X').add(settings?.invoices?.quote_days_to_expiration ?? 30, 'days').toDate(),
            });
        });
        setData(quotes_array);
    }, [quotes.quotes_dashboard]);

    /* Actions ----------------------------*/

    const openQuote = async (quote) => {

        if (numPanels(1) && !user.dismissed?.navNotice) {
            navNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'navNotice'));
            }, () => redirect());
        } else redirect();
        
        function redirect() {
            let entityId = (params.appId == APPS.SERVICE.id) ? quote.customerId : quote.assetId;
            let location = toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${quote.serviceRequestId}/quotes`, 9);
            history.push({ pathname: location });
            setTimeout(() => {
                location = toolbox.modifyPath( location, 10, `${quote.id}/items`);
                history.push({ pathname: location });
            }, 300);
        }
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Expires', field: 'expiration', type: 'timestamp', format: 'MMM Do', width: 25 },
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 75 }] : [],
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 75 }] : [],
        { name: 'Summary', field: 'description' },
    ];

    return (

        <div className="bg-white px-3 py-1">
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.tab2_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={ openQuote }
                chevron={ true }
            >
            </Table>
        </div>
    );
};

export default ResultsQuotes;