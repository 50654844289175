import React, { useState, useEffect, useCallback } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import _ from 'lodash';
import useDetectScroll from "@smakss/react-scroll-direction";
import { numPanels } from 'components/common/toolbox';

export default function SpeedDialButton(props) {

    const { scrollDir, scrollPosition } = useDetectScroll({target: props.columnRef?.current});
    const [ show, setShow ] = useState(true);
    const [ open, setOpen ] = useState(false);
    
    useEffect(() => {
        if (['still'].includes(scrollDir)) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [scrollDir]);

    useEffect(() => {
        debounce(() => setShow(true));
    }, [scrollPosition.top]);

    const debounce = useCallback(
        _.debounce(callback => callback(), 250), [], // will be created only once initially
    );

    return (
        <> 
            { show &&
                <>
                    <SpeedDial
                        ariaLabel="Action"
                        sx={{ position: 'fixed', bottom: (numPanels(1)) ? 32 : 52, right: 32 }}
                        icon={<SpeedDialIcon />}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        >
                        {props.actions?.map((action) => (
                            <SpeedDialAction
                            key={action.name}
                            className={action.className}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipOpen
                            onClick={(actionButton) => {
                                if (action.className != 'disabled') {
                                    props.onClick(action.value, actionButton);
                                    setOpen(false);
                                }
                            }}
                            />
                        ))}
                    </SpeedDial>
                    <div className={`modal-backdrop fade ${(open)?'show':''}`} role="presentation" style={{ zIndex: '1040', 
                        ...(!open) ? { pointerEvents: 'none' } : {} 
                    }}></div>
                </>
            }
        </>
    );
};
