import React from 'react';
import { ModalForm } from 'enspire-manager-framework';
import Timesheets from 'components/employees/analytics/time-sheets';

var _ = require('lodash');

const TimeCardModal = (props) => {

	return (

		<ModalForm {...props}
			cancel_button_callback={() => props.setViewTimeCard(false)}
			cancel_button_title="Cancel"
			modal_header={'Time Card'}
			no_padding={ true }
			save_button_title={'FINISHED'}
			size={'md'}
			submitFormHandler={() => props.setViewTimeCard(false)}
		>
			<div className="mt-3"/>
			<Timesheets source="timeclock" />
		</ModalForm>
	);
};

export default TimeCardModal;
