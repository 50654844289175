import * as toolbox from 'components/common/toolbox';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ROLES } from 'components/constants';
import { Table } from 'em-table';
import { elasticSearch, Ibox } from 'enspire-manager-framework';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export default function EmployeesSearch(props) {
	
	/* Hooks --------------------------*/
	
	const seg = 3;
	const params = useParams();
	const history = useHistory();
	const routeLocation = useLocation();
	
	const employees = useSelector((store) => store.employees);
	const departments = useSelector((store) => store.settings.departments);
	const profilesIndex = useSelector((store) => store.settings.settings?.values?.profilesIndex);
	const user_permissions = useSelector(store => store.users?.user_permissions);

	const [state, setState] = useState({
		loading: false,
		search: "",
		hits: [],
		timeout: null,
	});

	/* Effects --------------------------*/

	useEffect(() => { updateSearch(state.search); }, []);

	useEffect(() => {
		setTimeout(() => {
			updateSearch(state.search);
		}, 500);
	}, [profilesIndex, employees]);

	/* Handlers --------------------------*/


	/* Actions --------------------------*/

	const updateSearch = async (search) => {
		try {
			// If new search is typed, cancel timers
			if (search != "" && state.timeout) {
				clearTimeout(state.timeout);
				setState(prev => ({ ...prev, search, timeout: null }));
			}
			setState(prev => ({ ...prev, search, loading: true }));

			const indexHandle = 'mt-' + params.handle + ((isDev) ? '-dev' : '');
			var config = {
				table: indexHandle + "-profiles",
				fields: ["addresses", "contacts"],
				sort: [{ modified: "desc" }, { displayName: "asc" }],
				type: 'employee',
			};
			var hits = await elasticSearch(search, config);

			hits = hits?.map((employee) => {
				let department = _.find(departments, { id: employee?.departmentId });
				return ({ 
					...employee, 
					name: Object.values(employee.contacts)[0]?.firstName + " " + Object.values(employee.contacts)[0]?.lastName, 
					departmentName: department?.value ?? 'NO DEPARTMENT',
					departmentOrder: department?.position?.toString(),
					_stripe_color: department?.color,
					roles: employee.roles?.map((role) => 
						({ value: Object.values(ROLES).find((o) => o.id == role)?.name }))
				}) 
			});
			setState(prev => ({ ...prev, hits, loading: false }));

		} catch (error) {
			window.toastr.error(error, "Error Searching Employees");
		}

		return false; // prevent <Enter> key from reloading
	};

	/* Constants --------------------------*/

	return (
		<>
			<h2 className="ml-2 mt-0"><i className="fas fa-search mr-1"></i> Service Map</h2>
			<Ibox
				className="mt-3"
				title={"Employees"}
				show_spinner={(state.loading && state.search) || employees.employees_pending}
				no_fade={true}
			>
				<Table
					data={state.hits}
					show_limit={false}
					show_search={true}
					highlight_search={true}
					active_id={params.profile_id}
					active_field={"id"}
					search_query={state.search}
					search_callback={updateSearch}
					columns={[
						{ name: "Employee", field: "name", width: 100 },
					]}
					order_by={{ fields: ['name'], direction: ['asc'] }}
					group_by={{ fields: ['departmentOrder'], direction: ['asc'], display: ['departmentName'], collapsible: false, }}
					click_callback={ (employee) => {
						toolbox.listLink(history, routeLocation?.pathname, seg+1, employee.id, null, seg+4)
					}}
					container_id={"column1"}
					container_margin={265}
				/>
			</Ibox>
		</>
	);
}
