import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

export const VirtualKeyboardSupport = (props) => {

	useEffect(() => {
		if (props.focusedInput) {
			var pos = (document.getElementById(`vks-content${props.col}`).scrollTop + props.focusedInput.getBoundingClientRect().top) - 160;
			scroll.scrollTo(pos, { duration: 500, delay: 0, smooth: 'easeInOutQuart', containerId: `vks-content${props.col}` });
		}
	}, [props.focusedInput]);

	return (
		<>
			{ props.container_margin
				?	<div id={ `vks-content${props.col}` } ref={ props.columnRef } className={ props.className } style={ { maxHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' } }>
						{ props.children }
			
						{ props.virtualKeyboard &&
							<div style={{ paddingBottom: '55vh' }}></div>
						}
					</div>
				:	<>
						{ props.children }
					</>
			}
		</>
	);
}
