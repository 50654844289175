import * as actions_timeclock from 'actions/timeclock-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import React from 'react';
import { ConditionalWrapper } from 'components/common/toolbox';
import { ModalForm } from 'enspire-manager-framework';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'components/test/table';

var _ = require('lodash');

const TasksCheckin = (props) => {

	const params = useParams();
    const dispatch = useDispatch();
	
    const settings = useSelector((store) => store.settings.settings);
    const tasks = useSelector((store) => store.settings.settings_tasks);
    const user = useSelector((store) => store.users.user);
    const users = useSelector((store) => store.users);
	
	const isCheckedIn = (props.source == 'checkedIn');
	const userTasks = (isCheckedIn)
	?	_.filter(tasks, (task) => {
			return task.checkedIn?.includes(user.email);
		})
	:	_.filter(tasks, (task) => {
			const isEmployee = _.find(task.employees, (emp) => { return emp.value == user.profileId });
			return !task.employees?.length || isEmployee;
		});
		
	const handleCheckIn = (type, task) => {
		if (typeof props.setViewTasksCheckin === 'function') props.setViewTasksCheckin(false);
		dispatch(actions_work_orders.updateTaskCheckin(params.handle, task, user, type, () => {
            if (type == 'checkin') {
                dispatch(actions_timeclock.clockIn(params.handle, users));
            } else if (settings?.timeclock?.checkinTargetRequired) {
                dispatch(actions_timeclock.clockOut(params.handle, users));
            }
        }));
    }

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const tasksColumns = [
        { name: 'name', field: 'name', width: 70 },
        { name: 'Check In', field: 'checkin', type: 'jsx', width: 30 },
    ];

	const data = userTasks.map((task) => ({ ...task,
		heading: 'Current Task',
		checkin: (task.checkedIn?.includes(user.email))
		?   <button className="btn btn-warning btn-sm" onClick={() => handleCheckIn('checkout', task)}>Check Out</button>
		:   <button className="btn btn-info btn-sm" onClick={() => handleCheckIn('checkin', task)}>Check In</button>
	}));

	return (

		<ConditionalWrapper
			condition={ !isCheckedIn }
			wrapper={ children => 
				<ModalForm {...props}
					cancel_button_callback={() => props.setViewTasksCheckin(false)}
					cancel_button_title="Cancel"
					modal_header={'Tasks Check In'}
					bodyClassName="px-2 py-4"
					save_button_title={'FINISHED'}
					size={'md'}
					submitFormHandler={() => props.setViewTasksCheckin(false)}
				>
					{ children }
				</ModalForm>
			}
		>
            <ConditionalWrapper
                condition={ props.source == 'checkedIn'}
                wrapper={ children => <div className="col-12">{ children }</div> }
            >
                { !!data.length &&
					<Table
						className="mb-0"
						data={data}
						columns={tasksColumns}
						order_by={{ fields: ['name'], direction: ['asc'] }}
						show_search={!isCheckedIn}
						hide_header={isCheckedIn}
						// group_by={{ fields: ['name'], direction: ['asc'], display: ['heading'] }}
					/>
				}

			</ConditionalWrapper>
		</ConditionalWrapper>
	);
};

export default TasksCheckin;
