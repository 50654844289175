import * as toolbox from 'components/common/toolbox';
import ActivityLog from 'components/activity-log/activity-log';
import AttachmentsTab from 'components/attachments/attachments-tab';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import LineItemsTab from 'components/line-items/line-items-tab/line-items-tab';
import React from 'react';
import { Ibox } from 'enspire-manager-framework';
import { LOG, PERMISSIONS } from 'components/constants';
import { Route, Link, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

export default function QuoteTabs(props) {

	/* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
	const users = useSelector(store => store.users);

	/* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

	function tab_active(value) {
		return (props.match.params.tab === value) ? ' active' : ''; // returns 'active' if value tab is active
	}
	function tab_url(value) {
		return toolbox.modifyPath(props.location.pathname, seg, value, seg);
	}

	/* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

	const fromCustomerDashboard = props?.fromCustomerDashboard;
	const seg = 11;

	/* Render ------------------------------------------------------------------------------------------------------------------------------------*/

	return (
		<>
			<Route path={props.match.path} render={(route_props) =>

				<div className="tabs-container" style={{ marginTop: '20px' }}>
					<ul className="nav nav-tabs underline" role="tablist">

						<li><Link className={'nav-link' + tab_active('items')} to={tab_url('items')} replace={true}>Items</Link></li>
						<li><Link className={'nav-link' + tab_active('attachments')} to={tab_url('attachments')} replace={true}>Attachments</Link></li>
						<Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
							<li><Link className={'nav-link' + tab_active('activity-log')} to={tab_url('activity-log')} replace={true}>Activity Log</Link></li>
						</Authorize>

					</ul>

					<div className="tab-content">

						<div role="tabpanel" className={'tab-pane' + tab_active('items')}>
							<div className="panel-body">
								<Route path={props.match.path} render={(route_props) =>
									<CatchErrors>
										<LineItemsTab {...route_props} source="quotes" setFocused={ props.setFocused } />
									</CatchErrors>
								} />
							</div>
						</div>
						<div role="tabpanel" className={'tab-pane' + tab_active('attachments')}>
							<div className="panel-body">
								<Route path={props.match.path} render={(route_props) =>
									<CatchErrors>
										<AttachmentsTab {...route_props} 
											id={ params.quote_id } 
											columnId="column4"
                                            name="Quote" 
											source="quotes" 
											singular="quote" 
											store="quotes" 
											prefix="QUOTE" 
                                            setFocused={ props.setFocused }
										/>
									</CatchErrors>
								} />
							</div>
						</div>
						<Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
							<div role="tabpanel" className={'tab-pane' + tab_active('activity-log')}>
								<div className="panel-body">
									<Route path={props.match.path} render={(route_props) =>
										<CatchErrors>
											<ActivityLog {...route_props} 
												id="quote_id" 
												prefix="QUOTE" 
												singular="quote"
												store="quotes" 
												type={ `${LOG.QUOTE.key}` } 
											/>
										</CatchErrors>
									} />
								</div>
							</div>
						</Authorize>

					</div>
				</div>
			} />
		</>
	);
};