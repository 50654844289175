import React from 'react';
import { numPanels } from 'components/common/toolbox';
import { useParams } from 'react-router-dom';

export default function SlidingColumn(props) {

    const params = useParams();
    const { index, columnState, children} = props;

    const findLeft = (index) => {
        let result = 0;
        let i = index;
        let ii = 0;
        do {
            if (columnState?.['panelSlide'+index] == ii) {
                result = columnState['panelPos'+i];
                break;
            }
            i--; ii ++;
        } while (i > 1);
        return result;
    }

    const style = { 
        left: findLeft(index),
        // Safari requires that zIndex not be set for Modal to display outside of Column 4
        ...(index == 4 && (params.form == 'line-items' || params.form == 'send-email')) ? { zIndex: 'auto' } : {}, 
    };
    const overhead = (window.flutter) ? '0px' : (numPanels(1)) ? '53px' : '114px';

    var shiftUp = true;
    if (index == 2 && columnState?.panelSlide2 >= 1) shiftUp = false;
    if (index == 3 && columnState?.panelSlide3 >= 2) shiftUp = false;

    const shift = (props.shift) ? props.shift : 'mt-n4';
    const isLeftColumn = (index - columnState?.['panelSlide'+index] <= 1);

    return (
        <>
            { index == 0
                ?   <></>
                :   index == 1
                    ?   <div id={`column${index}`} ref={ props.columnRef } className={`column ${(props.grid)?props.grid: 'col-lg-4'} px-2 px-lg-3 animate-opacity`} 
                            style={{overflowY: 'auto', overflowX: 'hidden', height: `calc(100vh - ${overhead})` }}>
                            { children }
                            { window.flutter && <div style={{ marginBottom: '110px' }}></div> }
                        </div>
                    :   <div id={`column${index}`}  ref={ props.columnRef } className={`column ${(isLeftColumn)?'col-lg-4':(props.grid)?props.grid: 'col-lg-8'} px-2 px-lg-3 animate-left ${(!numPanels(1) && shiftUp)?shift:''}`} 
                            style={{ ...style, overflowY: 'auto', overflowX: 'hidden', height: `calc(100vh - ${overhead})` }}>
                            { children }
                            { window.flutter && <div style={{ marginBottom: '110px' }}></div> }
                        </div>
            }
        </>
    );
};
