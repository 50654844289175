import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
// import StatusSelect from 'components/common/status-select';
import _ from 'lodash';
import { APPS, WORKORDERS } from 'components/constants';
import { Avatar, Ibox } from 'enspire-manager-framework';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ConditionalWrapper, navNotice, numPanels } from 'components/common/toolbox';
import { Table } from 'em-table';
import { imageSize } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const moment = require('moment');

const MyWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
	const seg = 4;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const users = useSelector((store) => store.users);
    const user = useSelector((store) => store.users.user);
    const employees = useSelector((store) => store.employees.employees);
    const techWorkOrders = useSelector((store) => store.workOrders.work_orders_technician);
    const workOrders = useSelector((store) => store.workOrders);

    const [filter, setFilter] = useState('today');
    const [badges, setBadges] = useState([0, 0, 0]);
    const [data, setData] = useState([]);

	const segments = routeLocation.pathname.split('/').length;

    /* UseEffect --------------------------*/

    useEffect(() => {
        const wo_today = _.filter(techWorkOrders, (workOrder) => moment(workOrder.startDate.seconds, 'X').startOf('day') == moment().startOf('day')).length;
        const wo_week =  _.filter(techWorkOrders, (workOrder) => 
                moment(workOrder.startDate.seconds, 'X').startOf('day') >= moment().startOf('week') &&
                moment(workOrder.startDate.seconds, 'X').endOf('day') <= moment().endOf('week')
            ).length;
        const wo_all = techWorkOrders.length;
        setBadges([ wo_today, wo_week, wo_all ]);

        if (wo_today) setFilter('today')
        else if (wo_week) setFilter('week')
        else if (wo_all) setFilter('all');
    }, [techWorkOrders]);

    useEffect(() => {     
        var work_orders = [];
        var currentEntityId = null;
        var filteredWorkOrders = (props.source == 'checkedIn')
            ?   _.filter(techWorkOrders, (workOrder) =>
                    [WORKORDERS.ONSITE.id, WORKORDERS.ENROUTE.id, WORKORDERS.INPROGRESS.id].includes(workOrder.statusId)
                ) : (filter == 'today')
            ?   _.filter(techWorkOrders, (workOrder) => 
                    moment(workOrder.startDate.seconds, 'X').startOf('day') == moment().startOf('day')
                ) : (filter == 'week')
            ?   _.filter(techWorkOrders, (workOrder) =>
                    (moment(workOrder.startDate.seconds, 'X').startOf('day') >= moment().startOf('week') &&
                    moment(workOrder.startDate.seconds, 'X').endOf('day') <= moment().endOf('week'))
                ) : techWorkOrders;

        filteredWorkOrders?.forEach((workOrder) => {
            
            let employee = employees?.find((o) => o.id == workOrder.technicianId);
            let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            let status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });
            
            // Add grouip heading for customer or asset
            if (entityId != currentEntityId && props.source != 'checkedIn') {
                currentEntityId = entityId;
                work_orders.push({ ...workOrder,
                    status: status?.name,
                    _jsx:
                    <div className="d-flex p-2" style={{ backgroundColor: '#cccccc' }}>
                        <Avatar className=""
                            color="white"
                            bgColor="saddleBrown"
                            size={45}
                            fontSize={'20px'}
                            name={(params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name}
                            image={imageSize(workOrder._imageUrl, 'sm')}
                            imageWidth={60}
                            border="4px solid white"
                            role={ (workOrder._imageUrl) ? "button" : null }
                            style={{ flexShrink: 0 }}
                        />
                        <div className="align-self-center">
                            <h2 className="ml-3 m-0 text-black font-weight-normal">{
                                ((params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name) + 
                                ((workOrder._unitNumber) ? ' #' + workOrder._unitNumber : '')
                            }</h2>
                            { workOrder._address &&
                                <p className="ml-3 m-0">{ workOrder._address }</p>
                            }
                        </div>
                    </div> 
                });
            }

            work_orders.push({ ...workOrder,
                name: ((employee)
                    ? employee?.contact?.firstName + ' ' + employee?.contact?.lastName
                    : (workOrder.statusId == WORKORDERS.UNASSIGNED.id) ? 'Unassigned' : 'Unknown'),
                status: ((props.source == 'checkedIn')?'Currently ':'') + status?.name,
                _stripe_color: status?.color,
            });
        });
        setData(work_orders);
    }, [techWorkOrders, filter]);
    
    /* Actions ----------------------------*/

    const openWorkOrder = (workOrder) => {
        let num = (segments >= 12) ? -2 : (segments >= 10) ? -1 : 0;
		if (num) history.go(num);

        if (numPanels(1) && !user.dismissed?.navNotice) {
            navNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'navNotice'));
            }, () => redirect());
        } else redirect();

        function redirect() {
            let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            let location = toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`, 9);
            history.push({ pathname: location });
            setTimeout(() => {
                location = toolbox.modifyPath( location, 10, `${workOrder.id}/items`);
                history.push({ pathname: location });
            }, 300);
        }
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Start', field: 'startDate', type: 'timestamp', format: 'MMM Do', width: 25 },
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 75 }] : [],
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 75 }] : [],
        { name: 'Summary', field: 'description' },
    ];

    const title = <ButtonGroup className="d-flex my-3">
        <Button variant={(filter == 'today') ? 'primary' : 'default'} onClick={ () => setFilter('today') }>
            Today
            { !!badges[0] && <span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '6px', top: '3px', borderRadius: '0.5rem' }}>{badges[0]}</span> }
        </Button>
        <Button variant={(filter == 'week') ? 'primary' : 'default'} onClick={ () => setFilter('week') }>
            This Week
            { !!badges[1] && <span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '6px', top: '3px', borderRadius: '0.5rem' }}>{badges[1]}</span> }
        </Button>
        <Button variant={(filter == 'all') ? 'primary' : 'default'} onClick={ () => setFilter('all') }>
            All Scheduled
            { !!badges[2] && <span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '6px', top: '3px', borderRadius: '0.5rem' }}>{badges[2]}</span> }
        </Button>
    </ButtonGroup>
    
    return (

        <ConditionalWrapper
            condition={ props.source != 'checkedIn'}
            wrapper={ children => <Ibox className="mt-3 mb-0" title={title} show_spinner={workOrders.work_orders_pending} no_fade={true}>{ children }</Ibox> }
        >
            <ConditionalWrapper
                condition={ props.source == 'checkedIn'}
                wrapper={ children => <div className="col-12">{ children }</div> }
            >
                { !!data.length &&
                    <Table
                        data={data}
                        hide_header={ props.source == 'checkedIn' }
                        columns={columns}
                        active_field={'id'}
                        active_id={params.tab2_id}
                        second_line={'description'}
                        group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                        click_callback={openWorkOrder}
                        chevron={ true }
                    />
                }
            </ConditionalWrapper>
        </ConditionalWrapper>
    );
};

export default MyWorkOrders;