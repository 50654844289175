import React from 'react';
import MyTasks from './tasks-my-tasks';
import WorkOrdersTasks from './tasks-work-orders';
import ServiceRequestsTasks from './tasks-service-requests';
import QuotesTasks from './tasks-quotes';
import MyWorkOrders from 'components/work-orders/my-work-orders';
import TasksCheckin from 'components/time-clock/tasks-checkin';
import { PERMISSIONS } from 'components/constants';
import Authorize from 'components/common/authorize';

export default function DashboardTasks(props) {

    return (
		<>
			<Authorize permKeys={ [PERMISSIONS.WORK_ORDERS_ASSIGNED_VIEW.key, PERMISSIONS.WORK_ORDERS_ASSIGNED_EDIT.key] }>
				<h2 className="col-12 ml-2">My Dashboard</h2>
				<MyTasks handleClick={ props.handleClick } />
			</Authorize>

			{/* Show Currently Checked In */}
			<MyWorkOrders source="checkedIn" />
			<TasksCheckin source="checkedIn" />


			<Authorize permKeys={[
				PERMISSIONS.SERVICE_REQUESTS_VIEW.key,
				PERMISSIONS.WORK_ORDERS_VIEW.key,
				PERMISSIONS.QUOTES_VIEW.key,
			]}>
				<div className="middleOfLine text-muted mt-4">
					<span className="fa-stack fa-2x">
						<i className="fas fa-circle fa-stack-2x"></i>
						<i className="fa-solid fa-user-gear fa-stack-1x fa-inverse"></i>
					</span>
				</div>
			</Authorize>


			<Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW }>
				<h2 className="col-12 ml-2">Service Requests</h2>
				<ServiceRequestsTasks handleClick={ props.handleClick } />
			</Authorize>

			<Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW }>
				<h2 className="col-12 ml-2">Work Orders</h2>
				<WorkOrdersTasks handleClick={ props.handleClick } />
			</Authorize>

			<Authorize perm={ PERMISSIONS.QUOTES_VIEW }>
				<h2 className="col-12 ml-2">Quotes</h2>
				<QuotesTasks handleClick={ props.handleClick } />
			</Authorize>

		</>
	);
};
