import React from 'react';
import _ from 'lodash';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { INVOICES, PAYMENTS, WORKORDERS } from 'components/constants';

export default function StatusSelect(props) {

    var statuses = props.statusTypes.map((status, index) => {

        let disabled = status.disabled;
        if (props.statusTable == 'WORKORDERS') {
            if (status.id == WORKORDERS.ASSIGNED.id) {
                disabled = (props.selectedStatusId == WORKORDERS.UNASSIGNED.id);
            }
            if (parseInt(status.id) > parseInt(WORKORDERS.ASSIGNED.id) && (props.selectedStatusId == WORKORDERS.UNASSIGNED.id)) {
                disabled = true;
            }
        }

        return (
            <Dropdown.Item key={'Status:' + status.id} 
                disabled={ disabled || status.id == props.selectedStatusId}
                style={{ backgroundColor: (status.id == props.selectedStatusId)
                    ? 'lightSteelBlue'
                    : (disabled) ? '#eeeeee' : 'white' }} 
                onClick={ (typeof props.onClick === 'function') && props.onClick.bind(this, props.statusTable, status.id, props.id)}
            >
                <span style={{ color: status?.color }}>
                    <i className="fa fa-circle"></i>
                </span> &nbsp;
                {status?.name}
            </Dropdown.Item>
        );
    });

    const selectedStatus = _.find(props.statusTypes, { id: props.selectedStatusId });
    let statusName = selectedStatus?.name.toUpperCase();
    if (!selectedStatus) statusName = "N/A";

    return (
        <>
            {props.static
                ?   <Button style={{ cursor: 'default' }} className={props.className} variant="default" size="sm" 
                        onClick={(typeof props.onClick === 'function') 
                            ?   props.onClick.bind(this, props.statusTable, selectedStatus?.id) 
                            :   (e) => e.stopPropagation()
                        }
                    >
                        <><span style={{ color: selectedStatus?.color }}><i className="fa fa-circle"></i></span> &nbsp; {statusName} </>
                    </Button>
                :   <DropdownButton 
                        className={'d-inline ' + props.className } 
                        size="sm"
                        title={
                            <>
                                <span style={{ color: selectedStatus?.color }}>
                                    {selectedStatus &&
                                        <i className="fa fa-circle"></i>
                                    }
                                </span> &nbsp; {statusName} &nbsp;
                            </>
                        }
                        onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                        variant={ 'default' }
                        drop={'down'}
                    >
                        {(!props.static) ? statuses : null}
                    </DropdownButton>
            }
        </>
    );
};
