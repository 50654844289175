import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import * as actions_quickbooks from './quickbooks-actions';
import * as actions_invoices from './invoices-actions';
import firebase from 'firebase/compat/app';
import { APPS, QUOTES, SERVICE_ITEMS } from 'components/constants';
import { toastr } from 'react-redux-toastr';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  QUOTES
/*-----------------------------------------------*/

export function subQuoteById(handle, id, callback) {

	return dispatch => {

		dispatch({ type: types.QUOTE + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/quotes/quotes').doc(id).onSnapshot((doc) => {
			var quote = { ...doc.data(), id: doc.id };

			dispatch({ type: types.QUOTE + '_FULFILLED', data: quote, unsubscribe });
			if (typeof callback === 'function') callback(quote, unsubscribe);
		});
	};
}

export function subQuotesByField(handle, field, value) {

	return dispatch => {

		dispatch({ type: types.QUOTES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/quotes/quotes').where('deleted', '==', false).where(field, '==', value).onSnapshot((querySnapshot) => {

			var quotes = [];
			querySnapshot.forEach((doc) => {
				quotes.push({ ...doc.data(), id: doc.id });
			});
			quotes = _.orderBy(quotes, ['created'], ['desc']);

			dispatch({ type: types.QUOTES + '_FULFILLED', data: quotes, unsubscribe });
		});
	};
}
export function subDashboardQuotes(handle, widget_id) {

	return async dispatch => {

		dispatch({ type: types.QUOTES_DASHBOARD + '_PENDING' });

		var query = firestore.collection(handle + '/quotes/quotes').where('deleted', '==', false);

		if (widget_id == 'quote-drafts') query = query.where('statusId', '==', QUOTES.DRAFT.id);
		if (widget_id == 'quote-pending') query = query.where('statusId', '==', QUOTES.PENDING.id);
		if (widget_id == 'quote-declined') query = query.where('statusId', '==', QUOTES.REJECTED.id);
		if (widget_id == 'quote-accepted') query = query.where('statusId', '==', QUOTES.ACCEPTED.id);

		var unsubscribe = query.onSnapshot(async(querySnapshot) => {
			var quotes = [];
			querySnapshot.forEach((doc) => {
				quotes.push({ ...doc.data(), id: doc.id });
			});
			quotes = _.orderBy(quotes, ['requestDate'], ['desc']);

			dispatch({ type: types.QUOTES_DASHBOARD + '_FULFILLED', data: quotes, unsubscribe });
		});
	};
}
export function clearDashboardQuotes() {

	return dispatch => {
		dispatch({ type: types.QUOTES_DASHBOARD + '_CLEAR' });
	};
}
export function createQuotePdf(options, callback) {
	return async dispatch => {
			// firebase.functions().useEmulator('localhost', 5001);
			firebase.functions().httpsCallable('createQuotePdf')(options).then((result) => {
			if (typeof callback === 'function') callback(result);
		}).catch((error) => {
			window.toastr.error('There was an error generating this Quote.', 'Quote Copy could not be generated');
		});
	};
}
export function subAcceptedQuoteByServiceRequestId(handle, id) {

	return dispatch => {

		dispatch({ type: types.QUOTE_APPROVED + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/quotes/quotes').where('statusId', '==', QUOTES.ACCEPTED.id).where('deleted', '==', false).where('serviceRequestId', '==', id).onSnapshot((querySnapshot) => {

			var quotes = [];
			querySnapshot.forEach((doc) => {
				quotes.push({ ...doc.data(), id: doc.id });
			});
			quotes = _.orderBy(quotes, ['created'], ['desc']);

			dispatch({ type: types.QUOTE_APPROVED + '_FULFILLED', data: quotes[0], unsubscribe });
		});
	};
}
export function clearQuotes() {

	return dispatch => {

		dispatch({ type: types.QUOTES + '_CLEAR' });
	};
}
export function saveNewQuote(handle, quote, request, settings, callback) {

	return async dispatch => {

		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		const user = firebase.auth().currentUser;
		const batch = firestore.batch();
		var quoteId = await nextQuoteId(handle, batch);

		quote._displayName = request?._displayName;
		quote._address = request?._address;
		quote.customId = request.customId;
		quote.serviceRequestId = request.id;
		quote.customerId = request.customerId;
		quote.options = [{ name: 'Original', index: 0 }];
		quote.serviceItems = [{ 
			id: "0001",
			option: 0,
			type: SERVICE_ITEMS.LABOR.id,
			name: 'Labor',
			description: 'Description of Labor',
			count: 1,
			price: settings?.invoices?.defaultLaborRate ?? 0,
			calculatedPrice: (settings?.invoices?.defaultLaborRate) ? parseFloat(settings?.invoices?.defaultLaborRate) : 0,
		}];

		quote.statusId = QUOTES.DRAFT.id;
		quote.type = request.type;
		quote.deleted = false;
		quote.created = new Date();
		quote.modified = new Date();
		quote.ownerId = user.email;

		batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully created', 'Quote Created!');
            if (typeof callback === 'function') callback(quoteId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	}
}
export function updateQuote(handle, quoteId, quote, callback) {

	return async dispatch => {
		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		const batch = firestore.batch();
		quote.modified = new Date();

		batch.update(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully saved/updated', 'Quote Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function deleteQuote(handle, quoteId, callback) {

	return async dispatch => {

		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		const batch = firestore.batch();
		batch.update(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { deleted: true, modified: new Date() });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully archived', 'Quote Archived!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
// export function convertWorkOrder(handle, quoteId, callback) {

// 	return async dispatch => {

// 		dispatch({ type: types.WORK_ORDER + '_SAVE_PENDING' });

// 		const doc = await firestore.collection(handle + '/quotes/quotes').doc(quoteId).get();
// 		if (doc.exists) {
// 			const quote = { ...doc.data(), id: doc.id };




// 		}


// 		const batch = firestore.batch();
// 		batch.update(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { deleted: true, modified: new Date() });

// 		batch.commit().then(() => {
// 			dispatch({ type: types.WORK_ORDER + '_SAVE_FULFILLED' });
// 			window.toastr.success('The Quote has been successfully archived', 'Quote Archived!');
// 			if (typeof callback === 'function') callback();

// 		}).catch((error) => {
// 			toolbox.process_error(error, 'Record NOT Saved!');
// 		});
// 	};
// }
export function createChangeOrder(handle, serviceRequestId, callback) {

	return async dispatch => {

		// Copy all line items from quote & Work Orders
		// Begin list of changed items
		// List any line items removed with negative and field `changeOrder: "removed"`
		// List any new line items with field `changeOrder: "added"`

		dispatch({ type: types.QUOTE + '_SAVE_PENDING' });

		var doc = await firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId).get();
		var serviceRequest = { ...doc.data(), id: doc.id };

		// Search for existing quote - copy line items
		var querySnapshot = await firestore.collection(handle + '/quotes/quotes').where('serviceRequestId', '==', serviceRequest.id).get();
		var quoteRef;
		querySnapshot.forEach((doc) => {
			if (!doc.data()?.changeOrder && doc.data()?.statusId == QUOTES.ACCEPTED.id) quoteRef = doc;
		});
		querySnapshot.forEach((doc) => {
			if (doc.data()?.changeOrder && doc.data()?.statusId == QUOTES.CHANGES_REQUESTED.id) quoteRef = doc;
		});
		var quoteItems = (quoteRef) ? quoteRef.data()['serviceItems'].filter((item) => item.option == quoteRef.data().approvedOption).map((item) => {
			return { ...item, count: parseInt(item.count) };
		}) : [];

		// Add line items from any Work Orders & show changes on Change Order
		const workOrderSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', serviceRequestId).where('deleted', '==', false).get();

		var workOrders = [];
		var serviceItems = [];

		workOrderSnapshot.forEach((doc) => {
			workOrders.push({ ...doc.data(), id: doc.id });
		});

		var startDate;
		var endDate;
		var description = '';

		if (quoteRef) {
			startDate = quoteRef.data().startDate;
			endDate = quoteRef.data().endDate;
			description = quoteRef.data().description;
			serviceItems = quoteRef.data()['serviceItems'];
			if (!quoteRef.data().changeOrder) serviceItems = serviceItems.filter((item) => item.option == quoteRef.data().approvedOption);
			serviceItems = serviceItems.map((item) => {
				return { ...item, count: parseInt(item.count) };
			});
		} else {
			for (let workOrder of workOrders) {
				if (!startDate || workOrder.startDate < startDate) startDate = workOrder.startDate;
				if (!endDate || workOrder.endDate > endDate) endDate = workOrder.endDate;
				description += workOrder.description;

				let doc = await firestore.collection(handle + '/work-orders/work-orders/' + workOrder.id + '/serviceItems').doc(workOrder.id).get();
				if (doc.exists) {
					doc.data()['serviceItems'].forEach((serviceItem) => {
						const existing = serviceItems.findIndex((item) => (item.id == serviceItem.id) && (item.customPricePerUnit == serviceItem.customPricePerUnit) && (item.description == serviceItem.description));
						if (existing >= 0) {
							serviceItems[existing].count = parseInt(serviceItems[existing].count) + parseInt(serviceItem.count);
						} else {
							serviceItems.push(serviceItem);
						}
					});
				}
			}
		}

		var changedItems = [];

		// If there are no Work Order Line Items, don't show any changes on Change Order
		if (serviceItems.length) {
			serviceItems.forEach((item) => {
				const existing = quoteItems.find((qi) => (qi.id == item.id) && (qi.customPricePerUnit == item.customPricePerUnit) && (qi.description == item.description));
				if (existing) {
					if (parseInt(item.count) > parseInt(existing.count)) {
						changedItems.push({ ...item, count: parseInt(item.count) - parseInt(existing.count), changeOrder: 'added' });
					} else if (parseInt(item.count) < parseInt(existing.count)) {
						changedItems.push({ ...item, count: parseInt(item.count) - parseInt(existing.count), changeOrder: 'removed' });
					}
				} else {
					changedItems.push({ ...item, changeOrder: 'added' });
				}
			});
			quoteItems.forEach((qi) => {
				const existing = changedItems.find((ci) => (ci.id == qi.id) && (ci.customPricePerUnit == qi.customPricePerUnit) && (ci.description == qi.description));
				if (!existing) {
					const itemIndex = serviceItems.findIndex((si) => (si.id == qi.id) && (si.customPricePerUnit == qi.customPricePerUnit) && (si.description == qi.description));
					if (itemIndex < 0) {
						changedItems.push({ ...qi, count: -qi.count, changeOrder: 'removed' });
					}
				}
			});
		}

		const batch = firestore.batch();

		var quoteId = null;
		var querySnapshot = await firestore.collection(handle + '/quotes/quotes').where('serviceRequestId', '==', serviceRequest.id).where('changeOrder', '==', true).get();
		querySnapshot.forEach((doc) => { if (doc.data()['statusId'] == QUOTES.DRAFT.id) quoteId = doc.id; });

		if (!quoteId) quoteId = await nextQuoteId(handle, batch);

		var quote = {
			_displayName: serviceRequest._displayName,
			customId: serviceRequest.customId,
			serviceRequestId: serviceRequest.id,
			profileId: serviceRequest.profileId,
			addressId: serviceRequest.addressId,
			summary: serviceRequest.summary,
			startDate: startDate,
			endDate: endDate,
			description: description,
			statusId: QUOTES.DRAFT.id,
			changeOrder: true
		};
		quote.deleted = false;
		// quote.expiration = moment().add(30, 'days').toDate();
		quote.created = new Date();
		quote.effective = new Date();
		quote.modified = new Date();

		batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote, serviceItems, changedItems, quoteItems });
		// batch.set(firestore.collection(handle + '/quotes/quotes/' + quoteId + '/serviceItems').doc(quoteId), { serviceItems, changedItems, quoteItems });

		batch.commit().then(() => {
			dispatch({ type: types.QUOTE + '_SAVE_FULFILLED' });
			window.toastr.success('The Quote has been successfully saved/updated', 'Quote Saved!');
			if (typeof callback === 'function') callback(quoteId, serviceRequest.customId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
async function nextQuoteId(handle, batch) {
	const table = 'quotes';
	const field = 'nextQuoteId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
