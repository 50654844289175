// import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as actions_admin from 'actions/admin-actions';
import * as actions_invoices from 'actions/invoices-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import LineItemsBuilder from 'components/line-items/line-items-builder';
import React, { useEffect, useState } from 'react';
import SendEmailForm from 'components/send-email-form/send-email-form';
import StatusSelect from 'components/common/status-select';
import firebase from 'firebase/compat/app';
import { ADDRESSES, CUSTOMERS, INVOICES, SERVICE_ITEMS } from 'components/constants';
import { CloseX, Ibox, ModalAlert, Select, Spinner } from 'enspire-manager-framework';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Table } from 'em-table';
import { confirmDelete, checkPopupBlocker } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const _ = require('lodash');
const moment = require('moment');

const InvoiceDashboard = (props) => {
    
    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    
    const seg = 5;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();

    const company = useSelector(store => store.company.company);
    const customer = useSelector(store => store.profiles.profile);
    const invoices = useSelector(store => store.invoices);
    const profile = useSelector((store) => store.profiles.profile);
    const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
    const serviceItems = useSelector(store => store.serviceItems);
    const settings = useSelector(store => store.settings);
    const user_permissions = useSelector((store) => store.users.user_permissions);
    const user = useSelector((store) => store.users.user);

    const synced = !!quickbooksSettings?.tokens?.access_token;

    const [highlight, setHighlight] = useState({});
    const [hasType, setHasType] = useState({});
    const [rendering, setRendering] = useState(false);
    const [state, setState] = useState({
        selected_item: null,
    });

    const invoice = invoices.invoice;
    const permitted = user_permissions?.INVOICES_EDIT;

    /* Effects ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Subscribe to active invoice
    useEffect(() => {
        dispatch(actions_invoices.subInvoice(params.handle, params.invoice_id, serviceItems));

        return () => {
            let unsubscribe = invoices.invoice_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.invoice_id]);

    useEffect(() => {
        Object.values(SERVICE_ITEMS).forEach((type) => {
            setHasType(Object.assign(hasType, { [type.id]: (!!_.find(invoice?.serviceItems, (i) => i.type == type.id)) }));
        });
    }, [invoice]);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleEdit = (field, updatedItem, newValue) => {
        let hasUpdated = false; // ensure only one gets updated
        let newLineItems = invoice.serviceItems.map((item, index) => {
            if (!hasUpdated && actions_work_orders.compareServiceItems(item, updatedItem)) {
                let count = (field != 'count') ? item.count : newValue;
                let price = (field != 'price') ? item.price : newValue;
                hasUpdated = true;
                return { ...item, [field]: newValue, calculatedPrice: price * count };
            } else {
                return item;
            }
        });
        saveUpdates(newLineItems);
    };
    const handleCheckbox = (updatedItem, e) => {
        let newLineItems = invoice.serviceItems.map((item, index) => {
            if (item.id == updatedItem.id && ((item.type != 'labor' && item.type != 'travel') || item.description == updatedItem.description)) {
                let taxable = !item.taxable;
                return { ...item, taxable };
            } else {
                return item;
            }
        });
        saveUpdates(newLineItems);
    }
    const handleDelete = (deletedItem) => {
        let hasDeleted = false;
        let newLineItems = [];
        invoice.serviceItems?.forEach((item, index) => {
            if (hasDeleted || !actions_work_orders.compareServiceItems(item, deletedItem)) {
                newLineItems.push(item);
            } else {
                hasDeleted = true;
            }
        });
        saveUpdates(newLineItems);
    };

    const AddItem = (item, event) => {
        if (event?.stopPropagation) event?.stopPropagation();

        // TODO - see if we can use actions_work_orders.addItemToLineItems as in line-items-tab;

        let exists = false;
        let newLineItems = Object.assign([], invoice.serviceItems);
        let count = item.count;
        delete newLineItems.count;

        newLineItems.forEach((lineItem, index) => {
            if (lineItem.id == item.id && lineItem.description == item.description) {
                newLineItems[index] = { ...lineItem, count: parseInt(newLineItems[index].count) + count };
                exists = true;
            }
        });

        let price = (item.type == 'labor' || item.type == 'travel') 
            ? item?.price ?? 0 
            : (item?.priceOverride) 
                ? item.priceOverride
                : (item?.averageCost) ? item.averageCost : 0;

        let taxable = (profile?.customerTypeId == CUSTOMERS.RESIDENTIAL.id)
        ?   (item.type == 'labor' || item.type == 'travel') 
            ? settings?.invoices?.taxPrefs?.laborRes : settings?.invoices?.taxPrefs?.materialsRes
        :   (item.type == 'labor' || item.type == 'travel') 
            ? settings?.invoices?.taxPrefs?.laborCom : settings?.invoices?.taxPrefs?.materialsCom;
    
        if (!exists) {
            let newItem = {
                id: item.id,
                type: item.type,
                name: item.name,
                description: item.description ?? '',
                count: count,
                price: price,
                calculatedPrice: count * price,
                taxable: taxable,
            };
            newLineItems.push(newItem);
        }

        dispatch(actions_invoices.updateInvoice(params.handle, params.invoice_id, { serviceItems: newLineItems}));
        history.goBack();
        
        const user = firebase.auth().currentUser;
        if (item.type == SERVICE_ITEMS.NON_STOCK_PARTS.id) dispatch(actions_users.addItemToRecent(params.handle, item, user.email));
    }

    /* Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const setTaxRate = (e) => {
        dispatch(actions_invoices.updateInvoice(params.handle, params.invoice_id, { taxRateId: e.target.value }, () => {
            // dispatch(actions_quickbooks.updateQuickbooks({
            //     handle: params.handle,
            //     type: 'invoice',
            // }, quickbooksSettings));
        }));
    };
    const saveUpdates = (newLineItems) => {
        dispatch(actions_invoices.updateInvoice(params.handle, params.invoice_id, { serviceItems: newLineItems}));
    }
    const handleAction = (action) => {
        if (action == 'print') {
            createInvoicePdf(null);
        } else if (action == 'email') {
            let location = toolbox.modifyPath(routeLocation.pathname, seg, 'send-email/0', seg+7);
            history.push({ pathname: location });
        }
    };
    const handleEditInvoice = () => {
        let location = toolbox.modifyPathIfEmpty(routeLocation.pathname, seg, '0'); // set invoice_id to 0 if not already set
        location = toolbox.modifyPath(location, seg, 'line-items/0', seg+7);
        history.push({ pathname: location });
    }
    const sendEmail = (email) => {
        createInvoicePdf(email);
    };
    const handleStatus = (statusTable, statusId) => {
        dispatch(actions_settings.statusModalOpen(statusTable, statusId, invoice.id, onChangeStatus));
    };
    const handleReorder = (reorderedItem) => {
        var newItems = [];
        invoice?.serviceItems.forEach((item, index) => {
            if (actions_work_orders.compareServiceItems(item, reorderedItem) && index) {
                let prevItem = newItems.pop();
                newItems.push(reorderedItem);
                newItems.push(prevItem);
            } else {
                newItems.push(item);
            }
        });
        saveUpdates(newItems);

        setHighlight({ id: reorderedItem.id, fade: 'in' });
        setTimeout(() => {
            setHighlight({ id: reorderedItem.id, fade: 'out' });
            setTimeout(() => {
                setHighlight({});
            }, 350);
        }, 350);
    }

    /* Actions ------------------------------------------------------------------------------------------------------------------------------------*/

    const openHelpPanel = () => {
        if (window.flutter) {
            window.flutter.callHandler('helpPanel', '/servicing-assets.md/work-orders#add-a-locale-dropdown'); 
        } else {
            dispatch(actions_admin.showHelp(true, '/servicing-assets.md/work-orders#add-a-locale-dropdown'));
        }
	}
    const onChangeStatus = (statusId) => {
        dispatch(actions_invoices.updateInvoice(params.handle, params.invoice_id, { statusId }, () => {
            dispatch(actions_settings.statusModalClose());
        }));
    };
    const createInvoicePdf = (email) => {
        var update = {};

        if (parseInt(invoice.statusId) < parseInt(INVOICES.SENT.id)) {
            if (!email) {
                ModalAlert({
                    title: 'Change Status?',
                    text: "Do you want to change status to Sent?",
                    icon: 'question',
                    confirm_color: '#8FBC8B',
                    confirm_text: 'Yes',
                    deny_text: 'No, keep as Draft',
                    deny_color: '#cccccc',
                    show_deny: true,
                    callback_success: () => { 
                        update.statusId = INVOICES.SENT.id;
                        continueCreate(); 
                    },
                    callback_denied: () => continueCreate()
                });
            } else {
                update.statusId = INVOICES.SENT.id;
                continueCreate(); 
            }
        } else continueCreate();

        function continueCreate() {
            history.goBack();
            setRendering(true);

            dispatch(actions_invoices.createInvoicePdf({
                handle: params.handle,
                shadeColor: '#116a75',
                profileId: params.customer_id,
                invoiceId: params.invoice_id,
                emails: (!email) ? null : (email?.to?.length) ? email.to.map((to) => to.value) : [], //email.to,
                subject: (!email) ? null : email.subject,
                body: (!email) ? null : email.body.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                from: (company.email) ? company.companyName + ' <' + company.email + '>' : 'Mobile Track Systems <no-reply@mobiletrack.systems>',
            }, (result) => {

                dispatch(actions_invoices.updateInvoice(params.handle, params.invoice_id, update, null, true));
                setRendering(false);

                if (result) {
                    if (!email) {
                        window.open(result.data.downloadUrl, '_blank');

                        if (!user.dismissed?.checkPopupBlocker) {
                            checkPopupBlocker((isDismissed) => {
                                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'checkPopupBlocker'));
                            }, () => {});
                        }

                    } else {
                        window.toastr.success('This Invoice has been generated and emailed to ' + email.to.map((to) => to.value).join(', '), 'Invoice Emailed!');
                    }
                } else {
                    window.toastr.error('Error generating Invoice', 'Error');
                }
                // dispatch(actions_quickbooks.updateQuickbooks({
                //     handle: params.handle,
                //     type: 'invoice',
                // }, quickbooksSettings, (result) => {      
            }));
        }
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const taxRate = (synced)
        ? settings?.settings?.invoices?.tax_rates?.find((rate) => rate.rateId == invoice?.taxRateId)?.rate ?? 0
        : settings?.settings?.invoices?.tax_rates?.[invoice?.taxRateId]?.rate ?? 0;

    const depositItem = invoice?.serviceItems?.[0];

    if (invoice) {
        var invoice_items = invoice.serviceItems;
        var memo = invoice.memo;

        // Invoice ID, Invoice Date, Invoice Due Date
        var invoice_due_date = (invoice.dueDate?.seconds) ? moment(invoice.dueDate?.seconds, 'X').format('MM/DD/YY') : null;
        var invoice_date_created = moment(invoice.created?.seconds, 'X').format('MM/DD/YY');

        /*------------------------------------
                TOTALS MATH
        -------------------------------------*/
        var sub_total = 0;
        var tax_total = 0;
        if (invoice_items) {
            invoice_items.map((item => { if (item.taxable) tax_total += (item.price * item.count) * taxRate/100; }));
            invoice_items.map(item => { sub_total += (item.price * item.count); });
        }

        var paid = 0;
        // invoice_payments.map(item => { paid += item.amount });
        var grand_total = (tax_total + sub_total);

        var totals = {
            sub_total: (invoice?.isDeposit) ? depositItem.calculatedPrice : sub_total.toFixed(2),
            tax_total: tax_total.toFixed(2),
            grand_total: (invoice?.isDeposit) ? depositItem.calculatedPrice : (tax_total + sub_total).toFixed(2),
            paid: paid.toFixed(2),
            balance: (invoice?.isDeposit) ? (depositItem.calculatedPrice - (invoice?.applied ?? 0)).toFixed(2) : (grand_total - paid).toFixed(2)
        };

        /*------------------------------------
                CUSTOMER INFO
        -------------------------------------*/

        const billing_address = _.find(customer?.addresses, { addressTypeId: ADDRESSES.BILLING.id });
        const service_address = _.find(customer?.addresses, { addressTypeId: ADDRESSES.SERVICE.id });
        const bill_to = (billing_address) ? billing_address : service_address;
        var billing_info = toolbox.format_address(bill_to?.addressLine1, bill_to?.addressLine2, bill_to?.city, bill_to?.state, bill_to?.zip, null, null, true);
        var service_info = toolbox.format_address(service_address?.addressLine1, service_address?.addressLine2, service_address?.city, service_address?.state, service_address?.zip, null, null, true);
    }

    const columns = [
        { name: 'Name', field: 'name', width: 35 },
        { name: 'Qty', field: 'count', width: 10, edit: { type: 'text', callback: handleEdit }},
        { name: 'Price', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: 15, edit: { type: 'usd', callback: handleEdit }},
        { name: 'Total', field: 'calculatedPrice', type: 'number', format: 'usd', text_align: 'right', width: 20 },
        { name: 'Tax', field: 'taxable', type: 'checkbox', format: 'usd', callback: handleCheckbox, width: 10},
        { name: '', field: 'id', type: 'button', text_align: 'center', button: { name: <i className="fas fa-arrow-up"></i>, className: 'btn-secondary btn-xs', callback: handleReorder }, width: 10},
        { name: 'Description', field: 'description', edit: { type: 'text', placeholder: '(description)', callback: handleEdit } }, // 2nd Line
    ];

    var currentType = '';
    const data = invoice?.serviceItems?.map((item) => {
        let details = { ...item }
        if (currentType != item.type) {
            details._no_button = true;
            currentType = item.type;
        }
        let type = _.find(Object.values(SERVICE_ITEMS), { id: item.type });
        if (type) details = { ...details, _position: type.position.toString(), _display: type.name }
        return details
    }) ?? [];

    return (

        <>
            {invoice
                ?   <>
                        <CloseX onClick={() => { props.history.goBack(); }} style={{ right: '30px', marginTop: '20px' }} />
                        <Ibox className="mt-3" title={'INVOICE #' + invoice.id} no_fade={false}>

                            <div className="row">
                                {parseInt(invoice.statusId) > parseInt(INVOICES.PAID.id) && 
                                    <div style={{ position: 'absolute', zIndex: 101, left: '38%', top: 10 }}>
                                        <img src={'images/paid-stamp.png'} style={{ width: '125px', height: 'auto', }} />
                                    </div>
                                }
                                <h1 className="col-6 title">INVOICE</h1>
                                <div className="col d-flex justify-content-end" >
                                    <div className="text-right mr-3">
                                        <div><span className="r-titles">DATE:</span></div>
                                        <div><span className="r-titles">PAID:</span></div>
                                        <div><span className="r-titles">INVOICE&nbsp;#</span></div>
                                    </div>
                                    <div>
                                        <div><span className="r-titles-sub">{moment(invoice?.invoiceDate?.toDate()).format('MM/DD/YYYY')}<br /></span></div>
                                        <div><span className="r-titles-sub">{invoice_due_date}<br /></span></div>
                                        <div><span className="r-titles-sub">{invoice.id}</span></div>
                                    </div>
                                </div>
                            </div>
                            
                            <DropdownButton 
                                title={<>
                                    Invoice Actions
                                    {rendering && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>}
                                </>}
                                variant="primary" size="sm" className="d-inline mr-2"
                                onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                            >
                                {!invoice?.isDeposit && permitted && props.match.isExact &&
                                    <Dropdown.Item eventKey="1" onClick={handleEditInvoice}>Add Line Items</Dropdown.Item>
                                }
                                <Dropdown.Item eventKey="2" onClick={handleAction.bind(this, 'print')}>Print</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={handleAction.bind(this, 'email')}>Email</Dropdown.Item>
                            </DropdownButton>

                            <StatusSelect
                                className={'col-3'}
                                selectedStatusId={invoice.statusId}
                                statusTable={'INVOICES'}
                                statusTypes={Object.values(INVOICES)}
                                onClick={handleStatus}
                            />
                            <i className="fas fa-info-circle fa-lg text-info ml-3" onClick={openHelpPanel} role="button"></i>

                            <div className="row spacer">&nbsp;</div>
                            <div className="row">
                                <div className="col-6">
                                    {!!billing_info.length &&
                                        <>
                                            <div className="title-bar">BILL TO: </div>
                                            <div className="p-2">
                                                <div className="font-bold">{ customer?.displayName }</div>
                                                <div>{billing_info}</div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="col-6">
                                    {!!service_info.length &&
                                        <>
                                            <div className="title-bar">SERVICE TO:</div>
                                            <div className="p-2">
                                                <div className="font-bold">{ customer?.displayName }</div>
                                                <div>{service_info}</div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Table
                                        data={data}
                                        columns={columns}
                                        second_line={'description'}
                                        active_field={'id'}
                                        delete={user_permissions?.WORK_ORDERS_EDIT}
                                        on_delete={(item) => confirmDelete('Line Item', handleDelete.bind(this, item), { soft: true })}
                                        on_focus={(target) => { props.setFocused(target) }}
                                        group_by={{ fields: ['_position'], direction: ['asc'], display: ['_display'], collapsible: true, defaultOpen: true }}
                                        highlight_search={true}
                                        highlight={ highlight }
                                    >
                                    </Table>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-6 justify-content-start align-items-end'>
                                    { !invoice?.isDeposit && 
                                        <>
                                            <Select
                                                label={'Tax Rate:'}
                                                name={'taxRateId'}
                                                onChange={setTaxRate}
                                                value={invoice?.taxRateId}
                                                readOnly={!permitted}
                                            >
                                                <option value="">None</option>
                                                {settings?.settings?.invoices?.tax_rates?.map((rate, index) => {
                                                    return <option key={rate.name} value={rate.rateId ?? index}>{rate?.name} - {rate.rate}%</option>
                                                })}
                                            </Select>
                                            <h5 className="mt-3">Memo:</h5>
                                            {memo}
                                        </>
                                    }
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <div className="p-1">
                                        <table className="subtotal">
                                            <tbody>
                                                <tr>
                                                    <td align="right">SUBTOTAL: </td>
                                                    <td width="5"></td>
                                                    <td align="right">{toolbox.formatNumber(totals.sub_total, 2, true)}</td>
                                                </tr>
                                                {!invoice?.isDeposit && <tr>
                                                    <td align="right">TAX ({(taxRate ?? 0)}%):</td>
                                                    <td width="5"></td>
                                                    <td align="right">{toolbox.formatNumber(totals.tax_total, 2, true)}</td>
                                                </tr>}
                                                <tr>
                                                    <td></td>
                                                    <td width="5"></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td align="right" className="font-weight-bold">GRAND TOTAL: </td>
                                                    <td width="5"></td>
                                                    <td align="right">{ toolbox.formatNumber(totals.grand_total, 2, true) }</td>
                                                </tr>
                                                <tr>
                                                    <td align="right" className="font-weight-bold">PAID: </td>
                                                    <td width="5"></td>
                                                    <td align="right">{toolbox.formatNumber(totals.paid, 2, true)}</td>
                                                </tr>
                                                <tr>
                                                    <td align="right" className="font-weight-bold">BALANCE: </td>
                                                    <td width="5"></td>
                                                    <td align="right">{toolbox.formatNumber(totals.balance, 2, true)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Ibox>
                    </>
                : <Spinner center />
            }

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.form == 'send-email' &&
                <SendEmailForm
                    contacts={customer.contacts}
                    submit_callback={sendEmail}
                    type={'Invoice'}
                    id={params.invoice_id}
                />
            }
            { params.form == 'line-items' &&
                <LineItemsBuilder addItem={ AddItem } source={props.source} />
            }
        </>
    );
};

export default InvoiceDashboard;