import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import * as toolbox from 'components/common/toolbox';
import AttachementPhotos from 'components/attachments/attachment-gallery';
import AttachmentForm from 'components/attachments/attachment-form/attachment-form';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Ibox } from 'enspire-manager-framework';
import { PERMISSIONS } from 'components/constants';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Table } from 'em-table';
import { imageSize } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';

const ExternalInvoicesTab = (props) => {

    // props.id - id for attachment doc
    // props.source - path to doc e.g. 'assets' to read/write '[path]/[path]/[id]' 
    // props.prefix - prefix for Action Type e.g. [PREFIX]_ATTACHMENTS
    // props.field - for changing from default "attachments"
    // props.store & props.singular for accessing store e.g. useSelector((store) => [store].[singular]);

	const seg = 2;
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
	const routeLocation = useLocation();
    const pathname = routeLocation.pathname;

	const admin = useSelector((store) => store.admin);
    const workOrders = useSelector((store) => store.workOrders);
    const user_permissions = useSelector(store => store.users?.user_permissions);
    
    const field = (props.field) ? props.field : 'attachments';
    const attachments = workOrders.work_order_external_invoices ?? [];

    const [ data, setData ] = useState([]);
    const [ photos, setPhotos ] = useState([]);
    const [ tabView, setTabView ] = useState('list');


    useEffect(() => {
		dispatch(actions_attachments.subAttachments(params.handle, props.id, props.source, props.prefix, { field }));
		return () => {
			const unsubscribe = workOrders.work_order_external_invoices_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
	}, [params.handle, props.id, props.source]);

	useEffect(() => {
        var newPhotos = [];

        attachments.forEach((attach, i) => {
            if (attach.imageUrl) newPhotos.push(attach.imageUrl);
            if (attach.imagesAlt) attach.imagesAlt.forEach((image) => {
                newPhotos.push(image);
            });
        });
        setPhotos(newPhotos);

	}, [attachments]);

	useEffect(() => {
        var newData = [];
        attachments.forEach((attach, i) => {
            newData.push({ ...attach, index: i, images: (attach.imageUrl) ? <i className="fa-solid fa-camera fa-xl" role="button" onClick={ () => toggleLightbox(attach) }></i> : null });
        });
        setData(newData)
	}, [attachments]);

    /* Handlers --------------------------*/

    const handleAddNew = () => {
		let location = toolbox.modifyPath(pathname, seg+3, "attachment-form/0");
		history.push({ pathname: location });
    };

    /* Actions --------------------------*/
    
	const openForm = (item) => {
        let location = toolbox.modifyPath(pathname, seg+3, `attachment-form/${item.index + 1}`);
        history.push({ pathname: location });
	};

	const toggleLightbox = (attach) => {
		let imagesAlt = attach.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(attach.imageUrl, 'lg') },
			...(attach.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}

    /* Constants --------------------------*/

    const columns = [
        { name: 'Name', field: 'name', width: 45},
        { name: 'Price', field: 'price', type: 'number', format: 'usd', width: 35 },
        { name: 'Image', field: 'images', type: 'jsx', width: 10 },
        { name: '', field: 'id', type: 'button', button: { name: <i className="fa-regular fa-gear fa-lg text-muted"></i>, className: 'btn-default btn-xs float-right', callback: openForm }, width: 10 }
    ];

    return (
        <>
            <Ibox 
                jsx = {
                    <ButtonGroup className="mt-n1" style={{ marginRight: '120px' }}>
                        <Button variant={ (tabView == 'list') ? 'secondary' : 'default' } onClick={ () => setTabView('list') }>List View</Button>
                        <Button variant={ (tabView == 'gallery') ? 'secondary' : 'default' } onClick={ () => setTabView('gallery') }>Gallery View</Button>
                    </ButtonGroup>
                }
                title={' '}
                button={(props.button) ? props.button : '+ Attachment'}
                button_callback={handleAddNew}
                button_disabled={ (!user_permissions.ASSETS_CREATE && !user_permissions.ASSETS_EDIT) }
            >
                { tabView == 'list'
                    ?   <Table id="attachments-tab"
                            data={ data }
                            columns={columns}
                            on_focus={(target) => props.setFocused(target)}
                            // order_by={{ fields: ['name'], direction: ['asc'] }}
                            style={{ minHeight: '330px' }}
                        />
                    :   <>
                            <AttachementPhotos photos={ photos } />
                        </>
                }

                { admin.flutter_keyboard &&
                    <div style={{ paddingBottom: '55vh' }}></div>
                }
            </Ibox>

            <Route path={ "/:handle/:appId/:entity/:asset_id?/attachment-form/:attachment_index/:tab?"} render={(route_props) => (
                <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                    <CatchErrors modal={true}>
                        <AttachmentForm {...route_props}
                            expires={ props.expires }
                            field={ props.field } 
                            id={ props.id } 
                            invoice={ props.invoice }
                            prefix={ props.prefix } 
                            singular={ props.singular } 
                            source={ props.source } 
                            store={ props.store } 
                        />
                    </CatchErrors>
                </Authorize>
            )} />

        </>
    );
};

export default ExternalInvoicesTab;