import Form from 'react-bootstrap/Form';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox } from 'enspire-manager-framework';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Dropzone } from 'enspire-manager-framework';
import { Input } from 'components/test/input';
import { MultiSelect } from 'enspire-manager-framework';
import { Select } from 'enspire-manager-framework';
import { ValidateTable } from 'enspire-manager-framework';

const escapeStringRegexp = require('escape-string-regexp');
var _ = require('lodash');
var moment = require('moment');

export const Table = forwardRef((props, ref) => {

    const editRef = useRef(null);
    const tableRef = useRef(null);

    const [search, setSearch] = useState('');
    const [filterButton, setFilterButton] = useState();
    const [filterLimit, setFilterLimit] = useState(99);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(25);
    const [showLimit, setShowLimit] = useState(false);
    const [order, setOrder] = useState({ fields: [], direction: [] });
    const [group_by, setGroup_by] = useState({ fields: [], direction: [] });

    const [containerHeight, setContainerHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [rowsTop, setRowsTop] = useState('0');
    const [hScroll, setHScroll] = useState([0, 0, 0]);

    const [selected, setSelected] = useState([]);

    const [edit, setEdit] = useState({}); // Field being edited
    const [edited, setEdited] = useState({});
    const [newItem, setNewItem] = useState(null); // local state of newItem being built before being applied for saving
    const [indexes, setIndexes] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [editActive, setEditActive] = useState(false);

    const [collapsed, setCollapsed] = useState([]);
    const [existing, setExisting] = useState(false);
    const [oldItem, setOldItem] = useState({});
    const [editIndex, setEditIndex] = useState();

    const [portalTarget, setPortalTaget] = useState();

    useEffect(() => {
        const portalDiv = document.createElement("div");
        
        /** 
         * This ID is optional and has been added
         * to better recognize it in the DOM tree.
         */
        portalDiv.id = "myPortalDiv";
        document.body.appendChild(portalDiv);
        setPortalTaget(portalDiv);
        return () => document.body.removeChild(portalDiv);
    }, []);

    useEffect(() => {
        handleResize();
        handleSetScroll();
        window.addEventListener("resize", handleResize);
        // window.addEventListener("keydown", handleKeydownListener);
        if (props.pagination_callback) props.pagination_callback("next", parseInt(page) + 1);

        return () => {
            window.removeEventListener("resize", handleResize);
            // window.removeEventListener("keydown", handleKeydownListener);
        };
    }, []);

    // if button names change, reset active button / Set default Active --------------
    useEffect(() => {
        setFilterButton();
    }, [_.filter(props.filters?.buttons, (o) => o.value != '-').map(item => item.name).toString()]);

    // this should be after "if button names change"
    useEffect(() => {
        if (props.filters?.active) setFilterButton(JSON.stringify(props.filters.active));
    }, [props.filters?.active]);
    // -------------------------------------------------------------------------------
    
    useEffect(() => (props.pagination_callback && props.total_rows) && setShowLimit(true), [props.total_rows]);
    useEffect(() => setShowLimit((props.show_limit) ? props.show_limit : (props.total_rows) ? true : false), [props.show_limit]);
    useEffect(() => (props.filters?.limit) ? setFilterLimit(props.filters?.limit) : setFilterLimit(99), [props.filters?.limit]);
    useEffect(() => setLimit((parseInt(props.limit)) ? parseInt(props.limit) : 25), [props.limit]);
    useEffect(() => (props.rowsTop) ? setRowsTop(props.rowsTop) : setRowsTop('0'), []);
    useEffect(() => setOrder(props.order), [props.order]);
    useEffect(() => setOrder((props.order_by) ? props.order_by : { fields: [], direction: [] }), [props.order_by]);
    useEffect(() => setGroup_by((props.group_by) ? props.group_by : { fields: [], direction: [] }), [props.group_by]);
    useEffect(() => {
        if (group_by?.collapsible) {
            var groupBy = null;
            var groups = collapsed;
            var isCollapsed = false;
            const field = group_by.fields[0];
            if (typeof group_by?.defaultOpen == 'boolean') isCollapsed = !group_by.defaultOpen;
            var index = -1;
            display_data.forEach((data) => {
                if (Array.isArray(group_by?.defaultOpen)) isCollapsed = true;
                if (data[field]?.toLowerCase() != groupBy?.toLowerCase() && !groups.find((group) => group.name == data[field]?.toLowerCase())) {
                    index++;
                    groupBy = data[field];
                    if (Array.isArray(group_by?.defaultOpen)) {
                        if (group_by.defaultOpen.includes(index)) isCollapsed = false;
                    }
                    groups.push({ name: groupBy?.toLowerCase(), collapsed: isCollapsed });
                }
            });
            setCollapsed(groups);
        }
    }, [props.data]);

    useEffect(() => {
        if (newItem) {
            // Dynamically auto-populate if only one option exists and field is required 
            props.columns?.filter((column) => column.edit?.type == 'select' && column.edit.valid?.includes('required') && !newItem[column.field]).map((column) => {
                let colName = column.field;
                let options = column.edit.options.filter(option => option?.value);
                options.length == 1 && setNewItem(prev => ({ ...prev, [colName]: options[0].value }));
            });

            // Format item so that values match options list values format
            var formattedItem = { ...newItem };
            props.columns.filter((column) => column.edit?.type == 'select').map((column) => {
                let colName = column.field;
                const match = column.edit.options.find((option) => option?.label == newItem[colName]);
                if (match && formattedItem[colName] != match.value) {
                    formattedItem[colName] = match.value;
                    setNewItem(formattedItem);
                    // setEdit(prev => ({...prev, item: formattedItem}))
                    // startEdit(editIndex, column.edit.type, column.field, column.edit.placeholder, formattedItem, column.edit.callback, null, true);
                }
            });
        }
        if (currentIndex > 0) {
            let editColumn = props.columns[parseInt(indexes[currentIndex])];
            if (!editColumn) return;
            // Skip current field if prepopulated
            if (editColumn.edit?.valid?.includes('required') && editColumn?.edit?.type == "select" && editColumn.edit?.options?.filter((option) => option.value).length == 1) {
                stopEdit();
                setCurrentIndex(prev => prev + 1);
            };
        }
    }, [props.columns]);

    // Call blur handler when select or date changes
    useEffect(() => {
        if ((edit.type == "select") && edit.value != edit.item?.[edit.field]) handleBlur();
    }, [edit.value]);

    // call callback whenever New Item changes
    useEffect(() => (props?.onNewItemChanged) && props.onNewItemChanged(newItem), [newItem]);

    // advance edit to next field
    useEffect(() => {
        if (currentIndex > 0 && !existing) {
            let editColumn = props.columns[parseInt(indexes[currentIndex])];
            if (!editColumn) return;
            if (newItem[editColumn?.field] || editColumn?.edit?.type == "dropzone" || (editColumn?.edit?.noEdit && existing)) {
                setCurrentIndex(prev => prev + 1);
                return;
            }

            startEdit(editIndex, editColumn?.edit?.type, editColumn?.field, editColumn?.edit?.placeholder, newItem, editColumn?.edit?.callback);
        }
    }, [currentIndex]);

    /* EDIT --------------------------------------------------------------------*/

    const startEdit = (rowIndex, type, field, placeholder, item, callback, form_error, override) => {
        if (editActive && !override) {
            handleBlur();
            return;
        }
        console.log('start');
        if (parseInt(edit.form_error?.length)) return;
        setEdit({ rowIndex, type, field, placeholder, item, callback, value: item[field], form_error });
        setEditActive(true);
    };
    const stopEdit = async () => {
        console.log('stop');
        setEdit({});
        setEditActive(false);
    };
    const stopNewItem = () => {
        setExisting(false);
        stopEdit();
        setCurrentIndex(0);
        setNewItem(null);
    };
    const highlightEdited = (id) => {
        if (id) {
            setEdited({ id, fade: 'in' });
            setTimeout(() => {
                setEdited({ id, fade: 'out' });
                setTimeout(() => {
                    setEdited({});
                }, 350);
            }, 350);
        }
    };
    const debounce = useCallback(
        _.debounce(callback => callback(), 300), [], // will be created only once initially
    );
    // const handleKeydownListener = (event) => {
    //     event.stopPropagation();
    //     if (event.keyCode === 13) debounce(saveNewItem);
    // }
    const handleKeyDown = (event) => {
        event.stopPropagation();
        if (event.keyCode === 27) debounce(stopNewItem);
        if (event.keyCode === 13 && edit.type != 'textarea') debounce(handleBlur);
    };
    const handleChange = (event, value) => {
        let newValue = (value || value == 0) ? value.toString() : event.target.value;
        setEdit(prev => ({ ...prev, value: newValue, form_error: [] }));
    };
    const handleChangeDate = (field, date) => {
        setEdit(prev => ({ ...prev, value: date, form_error: [] }));
        handleBlur(true, date); // New Datepicker has no Onblur.  Can't wait for state change - send date to handleBlur
    };
    const handleChangeTime = (field, time) => {
        setEdit(prev => ({ ...prev, value: time, form_error: [] }));
        handleBlur(true, time); // New Datepicker has no Onblur.  Can't wait for state change - send date to handleBlur
    };
    const handleClearDate = () => {
        setEdit(prev => ({ ...prev, value: null, form_error: [] }));
        handleBlur(true, null); // New Datepicker has no Onblur.  Can't wait for state change - send date to handleBlur
    };
    const handleChangeSelect = (column, event) => {
        setEdit(prev => ({ ...prev, value: event.target.value, form_error: [] }));
    };
    const handleChangeMultiSelect = (value) => {
        setEdit(prev => ({ ...prev, value, form_error: [] }));
    };
    const handleChangeCheckbox = (item, row_index, column, e) => {

        if (newItem) {
            setNewItem(prev => ({ ...prev, [column.field]: !prev[column.field] }));
        } else if (props.saveNewOnly) {
            setExisting(true);
            setOldItem(display_data[row_index]);
            setEditIndex(row_index);
            setNewItem(display_data[row_index]);
        } else {
            if (typeof column.edit.callback == 'function') column.edit.callback(item, e);
            if (typeof column.callback == 'function') column.callback(item, e);
        }
    };
    const handleChangeDropzone = (field, downloadURL, filename, fileType, item) => setNewItem(prev => ({ ...prev, [field]: downloadURL }));
    const handleBlur = (useValue, value) => {
        var newValue = (useValue === true) ? value : edit.value;
        let column = _.find(props.columns, (o) => { return o.field == edit.field; });
        var form_error;
        var validation;

        if (!newItem) {
            if (column.edit?.validation_callback) validation = column.edit.validation_callback({ ...edit, value: newValue }, column.name);
            if ((parseInt(column.edit?.valid?.length) && column.edit.type != 'select') || ((parseInt(column.edit?.valid?.length) && column.edit.options?.filter(option => option.value).length))) {
                form_error = ValidateTable({ ...column, ...column.edit }, newValue);
            }
        }
        if (parseInt(form_error?.length)) {
            setEdit(prev => ({ ...prev, form_error }));
        } else if (validation) {
            window.toastr.error(validation, 'Please update your value for <em>' + column.name + '</em>');
        } else {
            submitForms(useValue, value);
        }
    };
    const handleFocus = (event) => {
        if (typeof props.on_focus == 'function') props.on_focus(event.target);
    };
    const submitForms = (useValue, value) => {
        var newValue = (useValue === true) ? value : edit.value;

        // Prevent save if value is the same
        if (newValue == edit.item[edit.field]) {
            stopEdit();
            return;
        }
        // Editing without New Item
        else if (edit.field && !newItem) {
            edit.callback(edit.field, edit.item, newValue);
            highlightEdited(edit.item?.id);
            stopEdit();

        // Editing for New Item
        } else if (edit.field && newItem) {

            // callback to onNewEdit & update row with result
            let group = {};
            let column = _.find(props.columns, (column) => (column.field == edit.field));
            if (typeof column?.edit?.onNewEdit === "function") {
                group = column.edit.onNewEdit(column, newValue) ?? {};
            }

            setNewItem(prev => ({ ...prev, [edit.field]: newValue, ...group }));
            stopEdit();

            // Advance to Next field?
            if (currentIndex < (indexes.length - 1)) {
                setCurrentIndex(currentIndex + 1);
            }
            // Done Editing, save New Item
        } else if (!edit.field && newItem) {
            saveNewItem();
            stopEdit();
        }
    };
    const saveNewItem = async () => {
        // iterate through column validations before saving
        let errorColumns = _.filter(props.columns, (o) => { return o.edit?.valid; });
        let columns = _.filter(props.columns, (o) => { return o.edit; });

        console.log(columns);

        // invalidColumn => value exists but is not in options
        const invalidColumn = columns.filter((column) => column.edit?.type == "select").filter((column) => column.edit.options?.findIndex((option) => option?.value == newItem[column.field]) < 0 && newItem[column.field]);
        const errorColumn = errorColumns.find((column) => parseInt(ValidateTable({ ...column, ...column.edit }, newItem[column.field])?.length));

        if (invalidColumn.length >= 1) {
            invalidColumn.forEach((invalid) => {
                setNewItem(prev => ({ ...prev, [invalid.field]: '' }));

                if (invalid.edit?.valid) {
                    const form_error = [{ field: invalid.field, type: '' }];
                    startEdit(editIndex, invalid.edit?.type, invalid.field, invalid.edit?.placeholder, newItem, invalid.edit?.callback, form_error);
                    return;
                }
            });
        } else if (errorColumn) {
            const form_error = ValidateTable({ ...errorColumn, ...errorColumn.edit }, newItem[errorColumn.field]);
            if (!editActive) {
                startEdit(editIndex, errorColumn.edit?.type, errorColumn.field, errorColumn.edit?.placeholder, newItem, errorColumn.edit?.callback, form_error);
            } else setEdit(prev => ({ ...prev, field: errorColumn.field, form_error }));
            return;
        } else {
            if (props.validation_callback) {
                var error = await props.validation_callback(newItem, oldItem);
                if (error?.error) {
                    error.message && window.toastr.error(error.message);
                    if (error.quit) {
                        setExisting(false);
                        setCurrentIndex(0);
                        stopNewItem();
                    }
                    return;
                }
            }
            if (existing) {
                props.edit_new_callback(oldItem, newItem);
            } else {
                props.save_new_callback(newItem);
            }
            setExisting(false);
            setCurrentIndex(0);
            stopNewItem();
        }
    };

    /* HANDLERS --------------------------------------------------------------------*/

    const handleResize = () => {
        handleSetScroll();
        if (props.container_id) {
            var container_height = (document.getElementById(props.container_id)) ? document.getElementById(props.container_id).clientHeight : 0;
            var container_width = (document.getElementById(props.container_width_id))
                ? (document.getElementById(props.container_width_id).querySelectorAll('tbody')[0])?.clientWidth
                : (document.getElementById(props.container_id))
                    ? (document.getElementById(props.container_id).querySelectorAll('tbody')[0])?.clientWidth
                    : 0;
            setContainerHeight(container_height);
            setContainerWidth(container_width);
        }
    };
    const handleSetScroll = () => {
        let scrollLeft = document.getElementById(`table-responsive-${props.id}`).scrollLeft;
        let scrollWidth = document.getElementById(`table-responsive-${props.id}`).scrollWidth;
        let clientWidth = document.getElementById(`table-responsive-${props.id}`).clientWidth;
        setHScroll([scrollLeft, scrollWidth, clientWidth]);
    };
    const handleOnHScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        setHScroll([scrollLeft, scrollWidth, clientWidth]);
    }
    const handleLimit = (event) => {
        stopEdit();
        setLimit(parseInt(event.target.value));
        setPage(0);
        if (props.pagination_callback) props.pagination_callback("prev", 0, parseInt(event.target.value));
    };
    const handleSearch = (event) => {
        stopEdit();
        setSearch(event.target.value);
        setPage(0);
        if (typeof props.search_callback === 'function') props.search_callback(event.target.value);
    };
    const handleClearSearch = () => {
        stopEdit();
        setSearch('');
        if (typeof props.search_callback === 'function') props.search_callback('');
    };
    const handleFilter = (button, event) => {
        event.preventDefault();
        stopEdit();
        if (filterButton === button && !props.filters.required) button = "";
        setFilterButton(button);
        setPage(0);
        if (props.filters.filter_notify) props.filters.filter_notify(button);
        if (props.filters.filter_callback) props.filters.filter_callback(button);
    };
    const handleFilterDropdown = (event) => {
        stopEdit();
        setFilterButton(event.target.value);
        setPage(0);
        if (props.filters.filter_notify) props.filters.filter_notify(event.target.value);
        if (props.filters.filter_callback) props.filters.filter_callback(event.target.value);
    };
    const handlePage = (e) => {

        stopEdit();
        setPage(e.target.getAttribute('number'));
    };
    const handlePrevious = () => {
        stopEdit();
        if (page > 0) {
            if (props.pagination_callback) props.pagination_callback("prev", page - 1, limit);
            setPage(page - 1);
        }
    };
    const handleNext = (max_page) => {
        stopEdit();
        if (page < max_page) {
            if (props.pagination_callback) props.pagination_callback("next", page, limit);
            setPage(page + 1);
        }
    };
    const handleFirst = () => {
        stopEdit();
        setPage(0);
    };
    const handleLast = (max_page) => {
        stopEdit();
        setPage(max_page);
    };
    const handleDuplicate = (row_index) => {
        setEditIndex(0);
        var copy = props.button_callback();

        // Format item so that values match options list values format
        var formattedItem = { ...display_data[row_index] };
        props.columns.filter((column) => column.edit?.type == 'select').map((column) => {
            let colName = column.field;
            const match = column.edit.options.find((option) => option?.label == display_data[row_index][colName]);
            if (match) formattedItem[colName] = match.value;
        });
        if (props.copyFields) props.copyFields.map((field) => {
            copy[field] = formattedItem?.[field];
        });
        else {
            console.error('Missing copyFields array');
            return;
        }
        const column = props.columns.filter((column) => column.edit).find((column) => !copy?.[column.field]);
        setNewItem(copy);
        if (column) startEdit(0, column.edit.type, column.field, column.edit.placeholder, copy, column.edit.callback);
    };
    const handleButton = () => {
        stopEdit();
        if (props.button_callback && typeof props.button_callback === 'function') {
            let newItemValue = props.button_callback();
            if (newItemValue) {
                setNewItem(newItemValue);
                setOldItem(newItemValue);
                setEditIndex(0);

                // Check if any select columns are required and only have one option. if so, set empty fields to that option
                props.columns.filter((column) => column.edit?.type == 'select' && column.edit.valid?.includes('required') && !newItemValue[column.field]).map((column) => {
                    let colName = column.field;
                    let options = column.edit.options.filter(option => option?.value);
                    options?.length == 1 && setNewItem(prev => ({ ...prev, [colName]: options[0]?.value }));
                });

                // Auto start first empty.
                var indexes = _.keys(_.pickBy(props.columns, (o) => { return o.edit; }));
                setIndexes(indexes);
                const emptyIndex = props.columns?.findIndex((column) => column.edit && !newItemValue?.[column.field]);
                // If some columns are filled, set to empty colum first
                let editColumn = (emptyIndex) ? props.columns[emptyIndex] : props.columns[indexes[0]];
                startEdit(0, editColumn?.edit?.type, editColumn?.field, editColumn?.edit?.placeholder, newItemValue, editColumn?.edit?.callback,);
            }
        }
    };
    const handleAddNew = (callbackResult) => {
        stopEdit();
        let newItemValue = callbackResult;
        if (newItemValue) {
            setNewItem(newItemValue);
            setEditIndex(0);

            // Check if any select columns are required and only have one option. if so, set empty fields to that option
            props.columns.filter((column) => column.edit?.type == 'select' && column.edit.valid?.includes('required') && !newItemValue[column.field]).map((column) => {
                let colName = column.field;
                let options = column.edit.options.filter(option => option?.value);
                options?.length == 1 && setNewItem(prev => ({ ...prev, [colName]: options[0]?.value }));
            });

            // Auto start first column.
            var indexes = _.keys(_.pickBy(props.columns, (o) => { return o.edit; }));
            setIndexes(indexes);
            let editColumn = props.columns[indexes[0]];
            startEdit(0, editColumn?.edit?.type, editColumn?.field, editColumn?.edit?.placeholder, newItemValue, editColumn?.edit?.callback,);
        }
    };
    const handleExpandAll = () => {
        setCollapsed(collapsed.map((o) => ({ ...o, collapsed: false })));
    };
    const handleCollapseAll = () => {
        setCollapsed(collapsed.map((o) => ({ ...o, collapsed: true })));
    };

    const handleClick = (item, row_index, tableRef) => {
        console.log('click');
        props.click_callback(item, row_index, tableRef);
    };
    const handleToggleMultiple = (id, e) => {
        e.stopPropagation();
        stopEdit();
        var selected = selected;
        if (selected.includes(id)) {
            _.remove(selected, (n) => { return n == id; });
        } else {
            selected.push(id);
        }
        setSelected(selected);
    };

    const handleSetCollapsed = (groupBy) => {
        var copy = collapsed.map((collapsed) => ({ ...collapsed }));

        const index = copy.findIndex((group) => group.name == groupBy?.toLowerCase());
        copy[index] = { ...copy[index], collapsed: !copy[index]?.collapsed };
        if (index < 0) return;

        setCollapsed(copy);
    };

    /* ACTIONS --------------------------------------------------------------------*/

    const formatItem = (item, column) => {
        if (column.type === 'timestamp') {
            if (column.format) {
                if (column.utc) return moment.utc(item[column.field], 'X').format(column.format);
                return moment(item[column.field], 'X').format(column.format);
            } else console.error('EM Table: Format required for Timestamp field');

        } else if (column.type === 'date') {
            if (column.format) {
                if (column.utc) return (item[column.field]?.seconds) ? moment.utc(item[column.field].seconds, 'X').format(column.format) : '';
                return (item[column.field]?.seconds) ? moment(item[column.field].seconds, 'X').format(column.format) : '';
            } else console.error('EM Table: Format required for Date field');

        } else if (column.type === 'number') {
            if (parseFloat(item[column.field])) {
                if (column.format) {
                    if (column.format === 'usd') {
                        return '<span style="white-space: nowrap">$ ' + parseFloat(item[column.field]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</span>';
                    } else if (column.format === 'round0') {
                        return Math.round(parseFloat(item[column.field])).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else if (column.format === 'round2') {
                        return (Math.round(parseFloat(item[column.field]) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else if (column.format === 'round3') {
                        return (Math.round(parseFloat(item[column.field]) * 1000) / 1000).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else console.error('EM Table: Unknown number format');
                } else console.error('EM Table: Format required for Number field');
            } else {
                return '';
            }

        } else if (Array.isArray(item[column.field])) {
            return item[column.field].map((o) => o.label).join(', ');
        } else {

            let result = (item?.[column.field]) ? item[column.field].toString().replace(/_/g, " ") : (column.edit?.placeholder) ? column.edit.placeholder : ''; // replace _ with space 

            if (column.format == 'ucfirst') {
                result = result.charAt(0).toUpperCase() + result.slice(1);
            }
            if (column.format == 'uppercase') {
                result = result.toUpperCase();
            }

            if (props.highlight_search) {
                var highlight_words = (props.search_query) ? props.search_query.split(" ") : search.split(" ");
                if (Array.isArray(highlight_words)) highlight_words.forEach((word) => {
                    if (word) result = result.replace(new RegExp(escapeStringRegexp(word), "i"), (match) => { return '<mark>' + match + '</mark>'; });
                });
            }
            return (result)
                ? ((column.prefix && item?.[column.field]) ? column.prefix : '') + result + ((column.postfix && item?.[column.field]) ? column.postfix : '')
                : '';
        }
    };
    const columnSort = (column) => {
        var sortindex = (order) ? order.fields.indexOf(column.field) : -1;
        var direction = (sortindex > -1) ? ((order.direction[sortindex] === 'asc') ? 'desc' : 'asc') : 'asc';
        var newOrder = {
            fields: [column.field],
            direction: [direction],
        };
        setOrder(newOrder);
    };

    {/*----------------------------------------------------
        PREPROCESSING
    -----------------------------------------------------*/}

    /* Sort ------------------------------------*/

    let fields = [...group_by?.fields, ...order?.fields];
    let direction = [...group_by?.direction, ...order?.direction];

    var ordered_data = props.data;
    if (Array.isArray(ordered_data)) {
        for (let i = fields.length - 1; i >= 0; i--) {
            if (direction[i] == 'asc') {
                ordered_data = ordered_data.sort((a, b) => a[fields[i]]?.toString().localeCompare(b[fields[i]], 'en', { numeric: true }));
            } else {
                ordered_data = ordered_data.sort((b, a) => a[fields[i]]?.toString().localeCompare(b[fields[i]], 'en', { numeric: true }));
            }
        }
    }

    /* Search all Fields -----------------------*/

    var filtered_data = (search && !props.search_callback) ? _.filter(ordered_data, (o) => {
        var result = false;
        props.columns.forEach((column, index) => {

            if (Array.isArray(column.link)) {
                var link_data_field = column.link[0];
                var link_field = column.link[1];
            } else {
                var link_data_field = column.link;
                var link_field = column.link;
            }

            var record = o;
            if (link_data_field && link_field) {
                console.info(link_data_field, o[link_data_field]);
                record = _.find(column.data, (n) => { return n[link_field] == o[link_data_field]; });
            }

            if (record && typeof record[column.field] === 'string' && record[column.field]?.toLowerCase().includes(search?.toLowerCase())) result = true;
            if (record && typeof record[column.field] === 'number' && record[column.field]?.toString().startsWith(search?.toLowerCase())) result = true;
        });
        return result;
    }) : ordered_data;

    /* Filtered -----------------------------------*/

    if (props.filters?.buttons && (typeof props.filters?.filter_callback !== 'function')) { // has buttons?
        if (filterButton) {
            var isValidJSON = filterButton ? true : false;
            try { JSON.parse(filterButton); } catch (e) { isValidJSON = false; }
            var button = (isValidJSON) ? JSON.parse(filterButton) : filterButton;
            var newData = [];
            filtered_data.forEach((data) => {
                if (Array.isArray(data[props.filters.field])) {
                    if (Array.isArray(button)) {
                        button.forEach((button) => {
                            if (data[props.filters.field].includes(button.toString()?.trim())) newData.push(data);
                        });
                    } else if (data[props.filters.field].includes(button.toString()?.trim())) newData.push(data);
                } else {
                    if (Array.isArray(button)) {
                        button.forEach((button) => {
                            if (button.toString()?.trim() == data[props.filters.field]?.toString()?.trim()) newData.push(data);
                        });
                    } else if (data[props.filters.field]?.toString()?.trim() == button.toString()?.trim()) newData.push(data);
                }
            });
            filtered_data = newData;
        };
    };

    /* Limit --------------------------------------*/

    // var page = page;
    var display_data = (showLimit && limit > 0 && props.show_limit && !props.total_rows)
        ? filtered_data.slice(page * limit, page * limit + limit)
        : filtered_data;

    /* Pagination ---------------------------------*/

    var gap_low = false;
    var gap_high = false;
    var max_page = (props.total_rows) ? ((props.total_rows % limit) ? Math.floor(props.total_rows / limit) : (props.total_rows / limit) - 1) : Math.floor(filtered_data?.length / limit);
    var pagination = [];

    if (limit > 0 && filtered_data?.length > limit) {
        for (var i = 0; i * limit < filtered_data?.length; i++) {

            if (i < page - 2) {
                gap_low = true;
            } else if (i > page + 2) {
                gap_high = true;
            } else {
                pagination.push(
                    <button key={i} number={i} type="button" className={'btn btn-' + ((page == i) ? 'primary' : 'default')} onClick={(e) => handlePage(e)}>{i + 1}</button>
                );
            }
        }
    }

    /* Columns Headings ---------------------------------*/

    var headings = props.columns.filter((column) => column.width != '0');
    if (props?.second_line) headings = headings.filter((column) => column.field != props.second_line);

    var groupNames =[];
    var columnHeadings = [];
    headings.forEach((column, index) => {

        var sortindex = (order) ? order.fields.indexOf(column.field) : -1;
        var sort = (sortindex > -1) ? ((order.direction[sortindex] === 'asc') ? 'sort-up' : 'sort-down') : null;

        var styles = { lineHeight: 1, position: 'sticky', top: rowsTop, zIndex: 1, backgroundColor: 'white' };
        if (column.text_align) styles.textAlign = column.text_align;
        if (column.width) styles.width = column.width.toString() + '%';

        if (!column.group || (column.group && !groupNames.includes(column.name))) {
            groupNames.push(column.name);
            columnHeadings.push(
                <th key={'th' + index} style={{ ...styles, ...(props.hide_header) ? { padding: 0 } : {} }}>
                    { !props.hide_header &&
                        <a style={{ cursor: (!props.total_rows) ? 'pointer' : 'default', whiteSpace: 'nowrap' }} onClick={(!props.no_sorting && !props.total_rows) ? () => columnSort(column) : null}>
                            {(column.icon) ? column.icon : (column.name) ? (column.name?.toUpperCase() + `${(column.edit?.valid?.find((validation) => validation == 'required')) ? '*':''}`) : ''}
                            {(column.edit && !column.edit.noEdit && (column.name || column.icon)) &&
                                <i className="fas fa-pencil-alt" style={{ color: '#dddddd', marginLeft: '7px' }}></i>
                            }
                            <i className={'fa fa-' + sort} style={{ color: '#aaaaaa', marginLeft: '7px' }} />
                        </a>
                    }
                </th>
            );
        };
    });

    if (props.image) columnHeadings.unshift(<th key={'th-image'}><div style={{ width: `${props.imageWidth}px` }}></div></th>);
    if (props.duplicate) columnHeadings.unshift(<th key={'th-duplicate'}><a style={{ cursor: 'default', whiteSpace: 'nowrap' }}>&nbsp;</a></th>);

    /* ------------------------------------------------------------------------------------------------*/
    /* ------------------------------------------------------------------------------------------------*/
    // 
    //  ITERATE THROUGH TABLE ROWS
    //
    /* ------------------------------------------------------------------------------------------------*/
    /* ------------------------------------------------------------------------------------------------*/

    var groupedRows = [];
    var groupBy = null;
    var rows = [];
    var columnTotals = [];

    // Insert newItem at beginning of list
    if (newItem) (existing) ? display_data[editIndex] = newItem : display_data = [newItem, ...display_data];

    if (display_data?.length) display_data.map((item, row_index) => {

        /* Group By ---------------------------------*/

        if (group_by.fields.length) {
            if (item[group_by.fields[0]]?.toLowerCase() != groupBy?.toLowerCase()) {
                groupedRows.push(item[group_by.fields[0]]?.toLowerCase());
                groupBy = item[group_by.fields[0]];
                var display = (group_by.display) ? group_by.display[0] : group_by.fields[0];

                // Get badge totals for group heading
                var count = 0;
                if (props?.group_by?.badgeCount) {
                    count = props.data.filter((data) => data[display] == item[display]).length;
                } else if (props?.group_by?.badgeTotal?.length) {
                    props.data.filter((data) => data[display] == item[display]).forEach((row) => {
                        group_by.badgeTotal.forEach((totalField) => {
                            if (row[totalField]) count += row[totalField];
                        });
                    });
                }
                var badgeComponent = (props?.group_by?.badgeTotal?.length || props?.group_by?.badgeCount)
                    ? <span className="badge mr-1">{count.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    : null;

                // Groupby totals
                if (props?.group_by?.columnTotal?.length > 0) {
                    var headerCells = [];
                    var total = false;
                    var firstFound = false;
                    var colSpan = 0;

                    // Create an array of each header cell
                    props.columns.forEach((column, index) => {
                        if (group_by.columnTotal?.includes(column.field)) {
                            if (!total) {
                                // Total found, but need to seprate from previous cells
                                if (!firstFound) {
                                    // If duplicate button is active, add 1 space to first header cell
                                    if (props?.duplicate) colSpan++;
                                    // First cell has group_by display
                                    headerCells.push({ colSpan, content: item[display] });
                                    firstFound = true;
                                }
                                // Cell not defined in totals array
                                else headerCells.push({ colSpan });
                                total = true;
                            }
                            // Cell found matching total
                            var count = 0;
                            props.data.filter((data) => data[display] == item[display]).forEach((item) => { count += (item[column.field]) ? item[column.field] : 0; });
                            headerCells.push({ colSpan: 1, field: column.field, content: (count) ? count.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null });
                            colSpan = 0;
                        } else if (index == props.columns.length - 1) {
                            // Last cell
                            colSpan += 2;
                            headerCells.push({
                                colSpan,
                                content: (group_by.collapsible) ? ((collapsed.find((group) => group.name == item[group_by.fields[0]])?.collapsed)
                                    ? <div className="d-flex float-right">{badgeComponent}&nbsp;<i className={`fa fa-angle-up ml-1 float-right`}></i></div>
                                    : <div className="d-flex float-right">{badgeComponent} &nbsp;<i className={`fa fa-angle-down pl-1 float-right`}> </i></div>)
                                    : null
                            });
                        } else {
                            colSpan += 1;
                            total = false;
                        }
                    });
                    rows.push(
                        <tr key={'heading:' + groupBy + row_index} className='group-row'>
                            {headerCells.map((cell, cell_index) =>
                                <td colSpan={cell.colSpan} className="group-cell"
                                    key={'cell: ' + groupBy + row_index + cell_index}
                                    onClick={(group_by?.collapsible) ? () => handleSetCollapsed(item[group_by.fields[0]]) : null}
                                    style={{ 
                                        textTransform: 'uppercase', 
                                        cursor: (group_by?.collapsible) ? 'pointer' : null,
                                        width: containerWidth 
                                    }}
                                >
                                    {cell.content}
                                </td>
                            )}
                        </tr>
                    );

                } else {
                    rows.push(
                        <tr key={'heading:' + groupBy + row_index} className='group-row'>
                            <td colSpan="100" className="group-cell"
                                onClick={(group_by?.collapsible) ? () => handleSetCollapsed(item[group_by.fields[0]]) : null}
                                style={{ 
                                    textTransform: 'uppercase', 
                                    cursor: (group_by?.collapsible) ? 'pointer' : null,
                                    width: containerWidth 
                                }}
                            >
                                <span className="d-flex justify-content-between">
                                    {item[display]}{(group_by.collapsible)
                                        ? ((collapsed.find((group) => group.name == item[group_by.fields[0]])?.collapsed)
                                            ? <div>{badgeComponent}&nbsp;<i className={`fa fa-angle-up ml-1`}></i></div>
                                            : <div>{badgeComponent} &nbsp;<i className={`fa fa-angle-down pl-1`}> </i></div>)
                                        : null
                                    }
                                </span>
                            </td>
                        </tr>
                    );
                }
            }
        }

        var inputProps = {};

        /* Click ------------------------------------*/

        if (props.click_callback && typeof props.click_callback === 'function' && (edit.rowIndex != row_index)) {
            inputProps.onClick = () => { handleClick(item, row_index, tableRef); };
        }

        /* ------------------------------------------------------------------------------------------------*/
        // 
        //  ITERATE THROUGH COLUMNS ON THE CURRENT ROW
        //
        //      item - current row
        //      row_index - index to current row
        //      column - iterated column
        //
        /* ------------------------------------------------------------------------------------------------*/

        if (!props.columns?.length) console.error('EM Table: Missing Columns Definition');

        // --------------------------------------------------------------------------------------------------------------------------
        //  Grouped Columns - a group of columns with same "name" but different fields, edit values, and group identifiers
        //      This allows a column to render one of the grouped columns based on an items "_group" value.
        //      In the iteration below, a single <td> must be rendered - either the identified column or an empty column 
        //
        //  e.g:
        //  { group: 'A', name: 'Value / Duration', field: 'duration', edit: { type: 'select', options: durationOptions, callback: handleEdit }, width: 20 },
        //  { group: 'B', name: 'Value / Duration', field: 'value', edit: { type: 'text', callback: handleEdit, validation_callback: handleEditValidation }, width: 20 },
        // --------------------------------------------------------------------------------------------------------------------------

        const columns = props.columns.filter((column) => column.width != '0');
        var groups_to_iterate = _.filter(columns, (o) => o.group)?.length ?? 0;
        var group_rendered = false;
        var second_line_row;
        fields = (columns.length) ? columns.map((column, column_index) => {

            var field;  // This will be returned at the end of this loop

            const isSecondLine = (props?.second_line && (column.field == props?.second_line));

            var styles = (column.nowrap || column.checkbox) ? { whiteSpace: 'nowrap' } : { overflowWrap: 'anywhere' };
            if (item['_stripe_color'] && column_index == 0) styles.paddingLeft ='15px';

            if (isSecondLine) inputProps.colSpan = "99";

            /* Totals ------------------------------------*/

            let currentTotal = (columnTotals[column_index] !== undefined) ? columnTotals[column_index] : 0;
            columnTotals[column_index] = (column.total || column.average) ? parseFloat(currentTotal) + parseFloat(item[column.field]) : null;

            /* NoWrap & Width & Alignment ------------------------------------*/

            let imageWidth = (props.imageWidth) ? parseInt(props.imageWidth) - 12 : 0;

            if (column.width) styles.width = ((containerWidth - 10 - (imageWidth)) * column.width / 100).toString() + 'px';
            if (column.text_align) styles.textAlign = column.text_align;
            if (column._highlight) styles.backgroundColor = column._highlight;

            /* Data ------------------------------------*/

            if (column.data) {

                if (Array.isArray(column.link)) {
                    var link_data_field = column.link[0];
                    var link_field = column.link[1];
                } else {
                    var link_data_field = column.link;
                    var link_field = column.link;
                }

                var items = _.filter(column.data, { [link_field]: item[link_data_field] });

                if (column.type == 'button') {
                    console.error('EM Table: Field of type Button cannot have a Data Link');
                } else {
                    if (!items[0]?.[column.field]) {

                        var nullfield = '';
                        var nullLabel = (column.null_label) ? column.null_label : column.nullLabel;
                        if (nullLabel) {

                            var badgestyle = '';
                            if (column.style == 1) badgestyle = 'badge-success';
                            if (column.style == 2) badgestyle = 'badge-info';
                            if (column.style == 3) badgestyle = 'badge-warning';
                            if (column.style == 4) badgestyle = 'badge-danger';
                            if (column.style == 5) badgestyle = 'badge-default';
                            if (column.style == 6) badgestyle = 'badge-primary';
                            if (column.style == 7) badgestyle = 'badge-secondary';
                            if (column.style == 8) badgestyle = 'badge-light';
                            if (column.style == 9) badgestyle = 'badge-dark';

                            nullfield = <span className={'badge ' + badgestyle}>{nullLabel}</span>;
                        }
                        field = (<td key={'td' + column_index} {...inputProps} style={styles}>{nullfield}</td>); // TODO check for multiple
                    } else {
                        field = (<td key={'td' + column_index} {...inputProps} style={styles} dangerouslySetInnerHTML={{ __html: formatItem(items[0], column) }}></td>); // TODO check for multiple
                    }
                }

            } else {

                {/* BUTTON ---------------------------------------------------------------------------------------------*/ }

                if (column.type == 'datepicker' || column.type == 'select') {
                    console.error('EM Table:  This column type has been deprecated');

                } else if (column.type == 'button') {

                    if (!column.button.callback) console.error('Button requires a Column Button Callback');
                    let callback = (column.button.callback) ? column.button.callback : column.callback;

                    if (!item._no_button) {
                        if (!callback) return (<td key={'td' + column_index} {...inputProps} style={styles}><button className={'btn ' + column.button.className}>{column.button.name}</button></td>);
                        field = <td key={'td' + column_index} {...inputProps} style={styles}><button className={'btn ' + column.button.className} onClick={(e) => callback(item, e)}>{column.button.name}</button></td>;
                        if (isSecondLine) second_line_row = field;
                    } else {
                        field = <td key={'td' + column_index} {...inputProps} style={styles}></td>;
                    }

                {/* CHECKBOX ---------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'checkbox') {

                    field = <td key={'td' + column_index} {...inputProps} style={styles}>

                        { !item._no_checkbox?.includes(column.field)  &&
                            <Checkbox
                                disabled={ item._disable_checkbox?.includes(column.field) }
                                className="table"
                                checked={item[column.field]}
                                onClick={(!item._noEdit && !(newItem && row_index != editIndex)) ? (e) => handleChangeCheckbox(item, row_index, column, e) : null}
                            />
                        }
                    </td>;

                {/* TOGGLE ---------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'toggle') {

                    // if (!column.button.callback) console.error('Toggle requires a Column Button Callback');
                    let callback = (column.button.callback) ? column.button.callback : column.callback;

                    if (item[column.field] != null) {
                        let toggle_index = (item[column.field]) ? 1 : 0;
                        let name = (Array.isArray(column.button.name)) ? column.button.name[toggle_index] : column.button[toggle_index].name;
                        let className = (Array.isArray(column.button.className)) ? column.button.className[toggle_index] : column.button[toggle_index].className;
                        field = <td key={'td' + column_index} {...inputProps} style={styles}><button className={'btn ' + className} onClick={(e) => callback(item, e)}>{name}</button></td>;
                    } else {
                        field = <td key={'td' + column_index} {...inputProps} style={styles}></td>;
                    }

                {/* JSX ---------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'jsx') {
                    const jsxItem = (Array.isArray(item[column.field])) ? item[column.field][0] : item[column.field];
                    field = <td key={'td' + column_index} {...inputProps} style={styles}>{jsxItem}</td>;

                {/* BADGE --------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'badge') {

                    var badgestyle = '';
                    if (item[column.field] == 1) badgestyle = 'badge-success';
                    if (item[column.field] == 2) badgestyle = 'badge-info';
                    if (item[column.field] == 3) badgestyle = 'badge-warning';
                    if (item[column.field] == 4) badgestyle = 'badge-danger';
                    if (item[column.field] == 5) badgestyle = 'badge-default';
                    if (item[column.field] == 6) badgestyle = 'badge-primary';
                    if (item[column.field] == 7) badgestyle = 'badge-secondary';
                    if (item[column.field] == 8) badgestyle = 'badge-light';
                    if (item[column.field] == 9) badgestyle = 'badge-dark';

                    field = <td key={'td' + column_index} {...inputProps} style={styles}><span className={'badge ' + badgestyle}>{column.badge[item[column.field]]}</span></td>;

                {/* LABEL --------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'label') {

                    var badges = [];
                    if (Array.isArray(item?.[column.field])) {
                        badges = item?.[column.field];
                    } else {
                        badges = [{ value: item?.[column.field] }];
                    }

                    badges = badges?.map((content, index) => {
                        var badgestyle = '';
                        const styleId = (content?.style) ? content.style : column.style;
                        if (styleId == 1) badgestyle = 'badge-success';
                        if (styleId == 2) badgestyle = 'badge-info';
                        if (styleId == 3) badgestyle = 'badge-warning';
                        if (styleId == 4) badgestyle = 'badge-danger';
                        if (styleId == 5) badgestyle = 'badge-default';
                        if (styleId == 6) badgestyle = 'badge-primary';
                        if (styleId == 7) badgestyle = 'badge-secondary';
                        if (styleId == 8) badgestyle = 'badge-light';
                        if (styleId == 9) badgestyle = 'badge-dark';

                        return <span key={index} className={'badge mr-1 ' + badgestyle}>{(column?.prefix && content?.value) && column.prefix}{content?.value}</span>;
                    });

                    field = <td key={'td' + column_index} {...inputProps} style={styles}>{badges}</td>;

                {/* ACTIONS --------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'actions') {

                    var buttonClass = (selected && selected?.includes(item[column.field])) ? column.button.activeClass : column.button.className;
                    var row_results = (selected && selected?.length > 0) ? selected : item; // item[column.field];

                    field = <td key={'td' + column_index}>
                        <DropdownButton variant="default" size="xs" role="button" drop="left"
                            title={column.button.name} 
                            className={'no-caret ' + buttonClass} 
                            onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                        >
                            {
                                column.button.links.map((link, link_index) => {
                                    if (link.name == 'divider') return (<Dropdown.Divider />);
                                    if (item?.['_hide_actions']?.includes(link_index)) return null;
                                    return (
                                        <Dropdown.Item key={column_index} className="py-2" onClick={() => link.callback(row_results)} disabled={ link.disabled ?? false }>
                                            {link.name}
                                        </Dropdown.Item>
                                    );
                                })
                            }
                        </DropdownButton>
                    </td>;

                {/* SELECT --------------------------------------------------------------------------------------------*/ }

                } else if (column.type == 'select') {
                    // DEPRECATED
                    console.error('EM Table: field of type Select must have a Data Link');

                {/* NO TYPE --------------------------------------------------------------------------------------------*/ }

                } else {

                    /* ------------------------------------------------------------------------------------------------*/
                    // 
                    //  EDITABLE FIELD
                    //
                    /* ------------------------------------------------------------------------------------------------*/

                    if (column.edit) {

                        {/* EDIT --------------------------------------------------------------------------------------------*/ }

                        if (edit.field == column.field && edit.rowIndex == row_index) {

                            if (column.edit.type == 'text' || column.edit.type == 'usd' || column.edit.type == 'number-inc') {
                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        <Input
                                            autoFocus={true}
                                            className="m-0"
                                            inputMode={column.edit.inputMode}
                                            form_error={edit.form_error}
                                            label={column.name}
                                            name={column.field}
                                            noLabel={true}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            onKeyDown={handleKeyDown}
                                            placeholder={edit.placeholder}
                                            ref={editRef}
                                            type={column.edit.type}
                                            value={edit.value}
                                        />
                                        <div style={{ color: '#888888', marginLeft: '10px', marginTop: '3px', fontSize: '9px', whiteSpace: 'nowrap' }}>
                                            ESC&nbsp;|&nbsp;ENTER
                                        </div>
                                    </td>
                                );
                            }
                            if (column.edit.type == 'textarea') {
                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        <textarea 
                                            autoComplete="off" 
                                            className="form-control form-control-sm" 
                                            inputMode={column.edit.inputMode}
                                            name={ column.field } 
                                            onBlur={ handleBlur }
                                            onChange={ handleChange } 
                                            onFocus={ handleFocus }
                                            onKeyDown={handleKeyDown}
                                            placeholder={ edit.placeholder } 
                                            ref={editRef}
                                            rows={ 6 }
                                            value={ edit.value } 
                                        />
                                        <div style={{ color: '#888888', marginLeft: '10px', marginTop: '3px', fontSize: '9px', whiteSpace: 'nowrap' }}>
                                            ESC&nbsp;to&nbsp;CANCEL
                                        </div>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <button className={'btn btn-success btn-xs'} onClick={saveNewItem}>SAVE UPDATE</button>
                                            <button className={'btn btn-default btn-xs ml-2'} onClick={stopNewItem}>CANCEL</button>
                                        </div>
                                    </td>
                                );
                            }
                            if (column.edit.type == 'select') {
                                var select_options;
                                if (column.edit.optGroup) {
                                    // Create array of all different optGroups
                                    var optGroups = [];
                                    column.edit.options.map((item, index) => {
                                        if (!optGroups.includes(item.optGroup) && item.optGroup != null) {
                                            optGroups.push(item.optGroup.replace(/\s+/g, ' ').trim());
                                        }
                                    });
                                } else {
                                    if (item.options?.[column.field]?.length) {
                                        select_options = item.options?.[column.field].map((item, index) => {
                                            return (<option key={index} value={item.value}>{item.label}</option>);
                                        });
                                    } else {
                                        select_options = column.edit.options?.map((item, index) => {
                                            return (<option key={index} value={item.value}>{item.label}</option>);
                                        });
                                    }
                                }

                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        <Select
                                            autoFocus={false}
                                            className="m-0"
                                            name={column.field}
                                            value={edit.value}
                                            label={column.name}
                                            noLabel={true}
                                            form_error={edit.form_error}
                                            onChange={ handleChangeSelect.bind(this, column) }
                                            onBlur={handleBlur}
                                            onFocus={handleFocus}
                                        >
                                            {(column.edit.optGroup)
                                                ? <>
                                                    {column.edit.options.filter((item) => item.optGroup == null).map((item, index) => {
                                                        return (<option key={index} value={item.value}>{item.label}</option>);
                                                    })}
                                                    {optGroups.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)).map((optGroup, index) => {
                                                        return (
                                                            <optgroup key={index} label={optGroup}>
                                                                {column.edit.options.map((item, index) => {
                                                                    if (item.optGroup?.replace(/\s+/g, ' ').trim() == optGroup) {
                                                                        return (<option key={index} value={item.value}>{item.label}</option>);
                                                                    }
                                                                })}
                                                            </optgroup>
                                                        );
                                                    })}
                                                </>
                                                : select_options
                                            }

                                        </Select>
                                    </td>
                                );
                            }
                            if (column.edit.type == 'multiselect') {
                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        <MultiSelect
                                            creatable={ column.edit.creatable }
                                            style={{ position: 'relative', zIndex: '1000' }}
                                            className={'m-0'}
                                            label={column.name}
                                            noLabel={true}
                                            name={column.field}
                                            onChange={handleChangeMultiSelect}
                                            options={column.edit.options}
                                            value={edit.value}
                                            disabled={column.edit.disabled}
                                            onBlur={handleBlur}
                                            onFocus={handleFocus}
                                        />
                                    </td>
                                );
                            }

                            if (column.edit.type == 'date') {

                                 field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        <Input
                                            ref={editRef}
                                            autoFocus={true}
                                            className="m-0"
                                            type="date"
                                            form_error={edit.form_error}
                                            name={column.field}
                                            value={edit.value}
                                            selected={edit.value}
                                            dateFormat={column.edit.format}
                                            label={column.name}
                                            noLabel={true}
                                            placeholder={edit.placeholder}
                                            onChange={handleChangeDate}
                                            onFocus={handleFocus}
                                            filterDate={column?.edit?.filterDate}
                                            minDate={column?.edit?.minDate}
                                            maxDate={column?.edit?.maxDate}
                                            onKeyDown={handleKeyDown}
                                            showTimeSelect={column.edit.showTimeSelect}
                                            clearButton={true}
                                            onClear={handleClearDate}
                                        />
                                    </td>
                                );
                            }

                            if (column.edit.type == 'time') {

                                let timedate = moment().format('YYYY-MM-DD ') + edit.value;
                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        <Input
                                            ref={editRef}
                                            autoFocus={true}
                                            className="m-0"
                                            type="time"
                                            form_error={edit.form_error}
                                            name={column.field}
                                            value={moment(timedate).toDate()}
                                            selected={edit.value}
                                            dateFormat={column.edit.format}
                                            label={column.name}
                                            noLabel={true}
                                            placeholder={edit.placeholder}
                                            onChange={handleChangeTime}
                                            onFocus={handleFocus}
                                            filterTime={column?.edit?.filterTime}
                                            minTime={column?.edit?.minTime}
                                            maxTime={column?.edit?.maxTime}
                                            onKeyDown={handleKeyDown}
                                            showTimeSelect={column.edit.showTimeSelect}
                                            clearButton={true}
                                            onClear={handleClearDate}
                                        />
                                    </td>
                                );
                            }
                        } else {

                            // Edit fields, but rendered static
                            var content;

                            // if (column.edit?.type == 'select' && !props.saveNewOnly || (column.edit?.type == 'select' && newItem && row_index == editIndex && !column.edit.noEdit)) {
                            if (column.edit?.type == 'select' && !props.saveNewOnly || (column.edit?.type == 'select' && newItem && row_index == editIndex)) {
                                content = _.find(item.options?.[column.field], { value: item[column.field] })?.label;
                                if (!content) content = _.find(column.edit?.options, { value: item[column.field] })?.label;
                            } else if (column.edit?.type == 'date' || (column.edit?.type == 'date' && newItem && row_index == editIndex)) {
                                content = (item[column.field])
                                    ? (item[column.field]?.seconds)
                                        ? moment(item[column.field].seconds, 'X').format(column.format)
                                        : moment(item[column.field]).format(column.format)
                                    : null;
                            } else if (column.edit?.type == 'time' || (column.edit?.type == 'time' && newItem && row_index == editIndex)) {
                                content = (item[column.field])
                                    ? (moment(item[column.field]).isValid())
                                        ? moment(item[column.field]).format(column.format)
                                        : item[column.field]
                                    : null;
                            } else if (column.edit?.type == 'checkbox') {
                                content = <Checkbox
                                    checked={item[column.field]}
                                    onClick={() => handleChangeCheckbox(item, row_index, column)}
                                />;
                            } else if (column.edit?.type == "dropzone") {
                                content = <Dropzone
                                    accept={column.edit.accept}
                                    camera={column.edit.camera}
                                    bin={column.edit.bin}
                                    buttonClassName={column.edit.buttonClassName}
                                    buttonTitle={column.edit.buttonTitle}
                                    field={column.field}
                                    directory={column.edit.directory}
                                    image={item[column.field]}
                                    multiple={column.edit.multiple ?? false}
                                    onChange={(!newItem || newItem && row_index != editIndex) ? (results) => column.edit.callback(results, item) : (results) => handleChangeDropzone(results, item)}
                                    onLightbox={column.edit.lightboxCallback}
                                    resultsArray={true}
                                    storage={'firebase'}
                                    storageRef={column.edit.firebase.storage().ref()}
                                    uploadButton={true}
                                    uploadTitle={column.edit.uploadTitle}
                                    viewTitle={column.edit.viewTitle}
                                    viewButtonClass={column.edit.viewButtonClass}
                                />;

                            } else if (column.edit?.type == 'multiselect') {
                                content = (item[column.field] || []).map((type) => {
                                    return type.label;
                                }).join(', ');
                            } else if (newItem && row_index == editIndex) {
                                content = formatItem(item, column);
                            }
                            else {
                                content = <div style={{ maxHeight: props.max_height, overflow: 'hidden', color: (!item[column.field] && column.edit.placeholder) && "gray" }}><span dangerouslySetInnerHTML={{ __html: formatItem(item, column) }}></span></div>;
                            }

                            // Rendered static even if edit object exists
                            if (column.edit?.type == 'checkbox' || column.edit?.type == 'dropzone' || item?._disabled_fields?.includes(column.field)) {
                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}>
                                        {content}
                                    </td>);
                            } else if (column.edit?.type == 'na') {
                                field = (
                                    <td key={'td' + column_index} {...inputProps} style={styles}><span className="text-muted">- n/a -</span></td>
                                );
                            } else {
                                if (!item._noEdit) {
                                    if (!props.saveNewOnly && !column.edit.noEdit && !newItem || (newItem && row_index == editIndex && (!column.edit.noEdit || !existing))) {
                                        field = (
                                            <td key={'td' + column_index} {...inputProps}
                                                className={'edit_hover'}
                                                style={{ ...styles, cursor: 'pointer' }}
                                                onClick={() => {
                                                    startEdit(row_index, column.edit.type, column.field, column.edit.placeholder, {
                                                        ...item,
                                                        [column.field]: (column.edit.type == 'date')
                                                            ? (_.isDate(item[column.field]))
                                                                ? item[column.field]
                                                                : (item[column.field]) 
                                                                    ? moment(item[column.field].seconds, 'X').toDate()
                                                                    : null
                                                            : item[column.field]
                                                    }, column.edit.callback);
                                                }}
                                                onSelect={() => {
                                                    startEdit(row_index, column.edit.type, column.field, column.edit.placeholder, {
                                                        ...item,
                                                        [column.field]: (column.edit.type == 'date')
                                                            ? (_.isDate(item[column.field]))
                                                                ? item[column.field]
                                                                : (item[column.field]) 
                                                                    ? moment(item[column.field].seconds, 'X').toDate()
                                                                    : null
                                                            : item[column.field]
                                                    }, column.edit.callback);
                                                }}
                                            >
                                                {content}
                                            </td>
                                        );
                                    } else if (props.saveNewOnly && !newItem && !column.edit.noEdit) {
                                        field = (<td key={'td' + column_index} {...inputProps}
                                            style={{ ...styles, cursor: 'pointer' }}
                                            onClick={() => {
                                                setExisting(true);
                                                setOldItem(display_data[row_index]);
                                                setEditIndex(row_index);
                                                setNewItem(display_data[row_index]);

                                                // Format item so that values match options list values format
                                                var formattedItem = { ...display_data[row_index] };
                                                props.columns.filter((column) => column.edit?.type == 'select' && !column.edit.noEdit).map((column) => {
                                                    let colName = column.field;
                                                    const match = column.edit.options?.find((option) => option?.label == display_data[row_index][colName]);
                                                    if (match) formattedItem[colName] = match.value;
                                                });
                                                setNewItem(formattedItem);
                                                // startEdit(row_index, column.edit.type, column.field, column.edit.placeholder, formattedItem, column.edit.callback);
                                            }}
                                        >
                                            {content}
                                        </td>);
                                    } else {
                                        field = (<td key={'td' + column_index} {...inputProps} style={{ ...styles }}>{content}</td>);
                                    }
                                } else {
                                    field = (<td key={'td' + column_index} {...inputProps} style={{ ...styles }}>{content}</td>);
                                }
                            }
                        }

                    {/* NO TYPE & NO EDIT --------------------------------------------------------------------------------------------*/ }

                    } else {

                        var secondLineStyle = (isSecondLine) ? { whiteSpace: 'normal' } : {};
                        var secondLineClamp = (props.second_line_clamp) ? 'line-clamp-' + props.second_line_clamp : '';
                        var content;
                        if (item[column.field] == null) {

                            var nullfield = '';
                            var nullLabel = (column.null_label) ? column.null_label : column.nullLabel;
                            if (nullLabel) {

                                var badgestyle = '';
                                if (column.style == 1) badgestyle = 'badge-success';
                                if (column.style == 2) badgestyle = 'badge-info';
                                if (column.style == 3) badgestyle = 'badge-warning';
                                if (column.style == 4) badgestyle = 'badge-danger';
                                if (column.style == 5) badgestyle = 'badge-default';
                                if (column.style == 6) badgestyle = 'badge-primary';
                                if (column.style == 7) badgestyle = 'badge-secondary';
                                if (column.style == 8) badgestyle = 'badge-light';
                                if (column.style == 9) badgestyle = 'badge-dark';

                                nullfield = <span className={'badge ' + badgestyle}>{nullLabel}</span>;
                            }

                            content = <div style={{ maxHeight: props.max_height, overflow: 'hidden' }}>{nullfield}</div>;
                        } else {
                            let finalContent = formatItem(item, column);
                            if (item[column.line2]) finalContent += '<br/>' + item[column.line2];
                            content = <div style={{ maxHeight: props.max_height, overflow: 'hidden', ...secondLineStyle }} className={secondLineClamp}>
                                <span dangerouslySetInnerHTML={{ __html: finalContent }}></span>
                            </div>;
                        }
                        field = (
                            <td key={'td' + column_index} {...inputProps}
                                colSpan={(isSecondLine) ? columnHeadings.length : null}
                                style={{
                                    ...styles,
                                    ...(isSecondLine) ? { whiteSpace: 'noWrap', overflow: 'none' } : {},
                                }}
                            >{content}</td>
                        );
                    }
                }
            }

            // ---------------------------------------------------------------
            // If isSecondLine, render below
            // Otherwise check for _group to render selected columns
            // ---------------------------------------------------------------
            
            if (isSecondLine) {
                second_line_row = field;
            } else {
                if (!column.group) return field;

                // Grouped Columns - render identified column, null, or empty if none are identified
                groups_to_iterate--;
                if (item._group == column.group) {
                    group_rendered = true;
                    return field;
                } else {
                    if (!group_rendered && !groups_to_iterate) return <td></td>
                    return null;
                }
            }
    
        }) : null;

        /* ------------------------------------------------------------------------------------------------*/
        //  END ITERATE THROUGH COLUMNS ON THE CURRENT ROW
        /* ------------------------------------------------------------------------------------------------*/

        // Add IMAGE to beginning of row
        if (props.image) fields.unshift(<td key={'img'} style={{ padding: '4px' }} rowSpan={(props?.second_line) ? 2 : 1}>
            { item[props.image] }
        </td>);

        var active = '';
        if (props.active_id && props.active_field) {
            if (item[props.active_field] == props.active_id) {
                active = 'active';
            }
        }
        var editedValue = '';
        if (item.id == edited.id) editedValue = 'edited-' + edited.fade;
        if (item.id == props.highlight?.id) editedValue = 'edited-' + props.highlight?.fade;

        var stripe_color_class = (!item['_stripe_color']?.includes('#')) ? 'stripe_color_' + item['_stripe_color'] : '';
        var deprecated_stripe_color = (props.stripe_color) ? 'stripe_color_' + item[props.stripe_color] : null;
        var class_name = (item['_class_name']) ? item['_class_name'] : '';

        var stripe_style = { padding: '0px' }
        if (item['_stripe_color']?.includes('#')) stripe_style = { backgroundColor: item['_stripe_color'], padding: '2px' };
        
        /* Table Rows TR & Delete column-------------------------------------*/

        var tr_style = { cursor: ((props.click_callback) ? 'pointer' : 'default') };
        if (item._accent) tr_style = { ...tr_style, ...item._accent };
        if (containerWidth > 0) tr_style.width = containerWidth;

        if (group_by.fields.length) {
            // Don't display row if collapsed: true
            if (collapsed.find((group) => group.name == item[group_by.fields[0]]?.toLowerCase())?.collapsed) return;
        };
        var saveNew_styles = (newItem && (row_index == editIndex)) ? 'saveNew-row':'';

        /* ------------------------------------------------------------------------------------------------*/
        /* ------------------------------------------------------------------------------------------------*/
        // 
        //  RENDER THE SECOND LINE
        //
        /* ------------------------------------------------------------------------------------------------*/
        /* ------------------------------------------------------------------------------------------------*/

        if (item._jsx) {

            rows.push(<tr><td className="p-0" colSpan={99}>{ item._jsx }</td></tr>);

        } else if (second_line_row || (newItem && (row_index == editIndex))) {
            rows.push(
                <tr key={'tr' + row_index} id={'tr' + row_index} className={'second-line-row ' + class_name + active + ' ' + editedValue + ' ' + stripe_color_class + ' ' + deprecated_stripe_color + ' ' + saveNew_styles} style={tr_style}>
                    <td style={ stripe_style }></td>
                    <td className="px-0" colSpan={99}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    { props.image &&
                                        <th></th>
                                    }
                                    {props.columns.filter((column) => column.width != '0' && column.field != props.second_line).map((column, index) => {

                                        var styles = {};
                                        if (column?.width) styles.width = column.width.toString() + '%';
                                        styles.padding = 0;

                                        return (
                                            <th key={'th' + index} style={styles}></th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ maxWidth: '100%' }}>
                                <tr>
                                    {props.duplicate &&
                                        (newItem && (row_index == editIndex)
                                            ? <td style={{ cursor: 'pointer' }}></td>
                                            : <td style={{ cursor: 'pointer' }} onClick={() => handleDuplicate(row_index)}><i title="duplicate" className="fa fa-copy fa-lg text-black-50"></i></td>
                                        )
                                    }
                                    {fields}
                                    {(props.select) &&
                                        <td style={{ cursor: 'pointer' }}>
                                            {!item._no_select && <Form.Check disabled={item?._disable_select} type={'checkbox'} onClick={(e) => props.on_select(item, e)} />}
                                        </td>
                                    }
                                    { !(newItem && (row_index == editIndex)) &&
                                        <>
                                            {props.delete &&
                                                <>
                                                    {item._no_delete
                                                        ? <td></td>
                                                        : <td style={{ cursor: 'pointer' }} onClick={() => props.on_delete(item)}><i className="fa fa-times"></i></td>
                                                    }
                                                </>
                                            }
                                            {props.chevron &&
                                                <td><i className="fas fa-chevron-right text-muted" {...inputProps}></i></td>
                                            }
                                        </>
                                    }
                                </tr>
                                { newItem && (row_index == editIndex) &&
                                    <tr id={'new_item' + row_index} className={'second_line mt-0 pt-0'} style={{ overflow: 'none' }}>
                                        <td key={'submit' + row_index} style={{ whiteSpace: 'nowrap' }} colSpan="99">
                                            <button className={'btn btn-success btn-xs'} onClick={saveNewItem}>SAVE UPDATE</button>
                                            <button className={'btn btn-default btn-xs ml-2'} onClick={stopNewItem}>CANCEL</button>
                                        </td>
                                    </tr>
                                }
                                { second_line_row &&
                                    <tr id={'second_line' + row_index} className={'second_line mt-0 pt-0'} style={{ overflow: 'none' }}>
                                        {second_line_row}
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            );
        } else {
            rows.push(
                <tr key={'tr' + row_index} id={'tr' + row_index} className={'data-row ' +class_name + active + ' ' + editedValue + ' ' + stripe_color_class + ' ' + deprecated_stripe_color + ' ' + saveNew_styles} style={tr_style}>
                    <td style={ stripe_style }></td>
                    {props.duplicate &&
                        (newItem && (row_index == editIndex)
                            ? <td key={'delete' + row_index} style={{ cursor: 'pointer' }}></td>
                            : <td key={'delete' + row_index} style={{ cursor: 'pointer' }} onClick={() => handleDuplicate(row_index)}><i className="fa fa-copy fa-lg text-black-50"></i></td>
                        )
                    }
                    {fields}
                    {(props.select) &&
                        <td key={'select' + row_index} style={{ cursor: 'pointer' }}>
                            {!item._no_select && <Form.Check disabled={item?._disable_select} checked={item?.checked ? item.checked : false} type={'checkbox'} onClick={(e) => props.on_select(item, e)} />}
                        </td>
                    }
                    {props.delete &&
                        <>
                            {item._no_delete
                                ? <td key={'delete' + row_index}></td>
                                : <td key={'delete' + row_index} style={{ cursor: 'pointer' }} onClick={() => props.on_delete(item)}><i className="fa fa-times"></i></td>
                            }
                        </>
                    }
                    {props.chevron &&
                        <td key={'devron' + row_index} {...inputProps}><i className="fas fa-chevron-right text-muted"></i></td>
                    }
                </tr>
            );
        }
    });

    /* ------------------------------------------------------------------------------------------------*/
    //  END ITERATE THROUGH TABLE ROWS
    /* ------------------------------------------------------------------------------------------------*/

    /* Footer Totals --------------------------*/

    var footer = columnTotals.map((total, index) => {
        let styles = { fontWeight: 'bold' };
        let isUSD = (props.columns[index].format == 'usd');
        if (props.columns[index].text_align) styles.textAlign = props.columns[index].text_align;
        if (props.columns[index].total) {
            return (
                <td key={index} style={styles}>{((isUSD) ? '$ ':'') + parseFloat(total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            );
        } else if (props.columns[index].average) {
            return (
                <td key={index} style={styles}>{((isUSD) ? '$ ':'') + (parseFloat(total) / filtered_data.length).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            );
        }

    });

    /* Filter Buttons / Dropdown --------------------------*/

    var filters;
    if (props.filters && props.filters?.buttons?.length <= filterLimit) {
        filters = (props.filters) ? props.filters.buttons.map((item, index) => {
            return (
                <label key={'filter' + index} className={'btn' + ((filterButton == item.value) ? ' btn-primary active' : ' btn-white')} onClick={(event) => handleFilter(item.value, event)}>
                    <input type="radio" name={item.name} value={filterButton} /> {item.name}
                </label>
            );
        }) : null;

    } else {
        filters = (props.filters) ? props.filters.buttons.map((item, index) => {
            let result = (item.name) ? item.name.toString().replace(/_/g, " ") : ''; // replace _ with space 
            return (
                <option key={'filter' + index} style={{ textTransform: 'capitalize' }} value={item.value}>{result}</option>
            );
        }) : null;
    }


    var buttonStyle = (props.buttonStyle) ? props.buttonStyle : {};
    if (props.button_in_ibox) buttonStyle = { position: 'absolute', right: '0px', top: '-54px' };

    /* Fixed height scrollable ---------------------------*/

    var header_style = (containerHeight > 0 && props.container_margin > 0) ? { display: 'table' } : {};
    var tbody_style = (containerHeight > 0 && props.container_margin > 0) ? { display: 'block', height: (containerHeight - props.container_margin), overflowY: 'scroll' } : {};
    var tr_style = (containerWidth > 0) ? { display: 'table', width: containerWidth } : {};

    /* Allows access to ref.current functions ---------------------------*/

    if (ref) ref.current = { handleAddNew, handleExpandAll, handleCollapseAll, newItem, setNewItem, stopNewItem, existing, setFilterButton };


    /* ------------------------------------------------------------------------------------------------*/
    /* ------------------------------------------------------------------------------------------------*/
    // 
    //  RENDER THE TABLE
    //
    /* ------------------------------------------------------------------------------------------------*/
    /* ------------------------------------------------------------------------------------------------*/

    return (

        <div className="row">
            <div className="col-lg-12">

                <form className="table-options row mb-2" autoComplete="off">
                    <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>

                    {/*----------------------------------------------------
                        LIMITS
                    -----------------------------------------------------*/}

                    {props.show_limit && showLimit &&
                        <div className="col-lg m-b-xs">
                            <select className="form-control-sm form-control input-s-sm inline" name="limit" value={limit} onChange={handleLimit}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                {!props.total_rows && <option value="0">All</option>}
                            </select>
                        </div>
                    }

                    {/*----------------------------------------------------
                        SEARCH, FILTERS, AND BUTTON
                    -----------------------------------------------------*/}

                    {props.button &&
                        <div className={"col m-b-xs " + ((props?.show_search && props?.button && !props.button_in_ibox) ? 'row':'')}>
                            <button type="button" className={`ml-3 
                                ${((props.button_in_ibox) ? 'mr-3':'')} 
                                ${((props.button_class) ? props.button_class : 'btn btn-sm btn-primary')}`} 
                                disabled={((props.button_disabled) ? props.button_disabled : false)} 
                                onClick={handleButton} 
                                style={buttonStyle}
                            >
                                    {props.button}
                            </button>
                        </div>
                    }
                    {filters &&
                        <div className="col-12 m-b-xs">
                            {filters?.length <= filterLimit &&
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    {filters}
                                </div>
                            }
                            {filters?.length > filterLimit &&
                                <select className="form-control input-s-sm inline" name="limit" value={filterButton} onChange={handleFilterDropdown} >
                                    <option value=''>- No {(props.filters.name) ? props.filters.name : ''} Filter -</option>
                                    {filters}
                                </select>
                            }
                        </div>
                    }
                    {props.show_search &&
                        <div className={"col-12 m-b-xs " + ((props?.show_search && props?.button && !props.button_in_ibox) ? 'row':'')}>
                            <div className={"input-group " + ((props?.show_search && props?.button && !props.button_in_ibox) ? 'col':'')}>
                                <span style={{ position: 'relative', width: '100%' }}>
                                    {props.show_search && search &&
                                        <i className="fas fa-times-circle" style={{ position: 'absolute', color: '#bbbbbb', zIndex: 9, right: '5px', top: '5px', fontSize: '20px', cursor: 'pointer' }} onClick={handleClearSearch}></i>
                                    }
                                    {props.show_search &&
                                        <input name="search" placeholder="Search" type="text" className="form-control" value={search} onChange={handleSearch} />
                                    }
                                </span>
                            </div>
                        </div>
                    }
                </form>

                { (hScroll[1] != hScroll[2]) &&
                    <div style={{ width: '100%', height: '5px', marginBottom: '5px', borderRadius: '5px', background: '#eeeeee' }}>
                        <div style={{ marginLeft: `${(hScroll[0]/hScroll[1]*100)}%`, height: '5px', borderRadius: '5px', background: '#cccccc', width: `${(hScroll[2]/hScroll[1]*100)}%` }}></div>
                    </div>
                }

                <div id={ `table-responsive-${props.id}` } className="table-responsive-sm" style={{ ...props?.style, backgroundColor: 'white', overflowX: 'auto' }}
                    onScroll={handleOnHScroll}
                >

                    {/*----------------------------------------------------
                        TABLE
                    -----------------------------------------------------*/}

                    <table ref={tableRef} id={ props.id } className={`table table-striped table-hover em ${props?.className}`} style={{ ...props?.table_style }}>
                        <thead style={header_style}>
                            <tr style={tr_style}>
                                <th style={{ padding: '0px' }}></th>
                                {columnHeadings}
                                {props.delete &&
                                    <th key={'delete'} style={{ padding: '0px' }}></th>
                                }
                                {props.chevron &&
                                    <th key={'chevron'} style={{ padding: '0px' }}></th>
                                }
                            </tr>
                        </thead>
                        <tbody style={tbody_style}>
                            {rows.length
                                ? rows
                                : <tr style={{ backgroundColor: 'transparent' }}><td colSpan={props.columns?.length}><h2 className="text-center" style={{ marginTop: '40px' }}>No Records Found</h2></td></tr>
                            }
                            <tr id="table-footer">
                                {footer}
                            </tr>
                        </tbody>
                    </table>

                    {/*----------------------------------------------------
                        PAGINATION
                    -----------------------------------------------------*/}
                    {showLimit &&
                        <div className="btn-group d-flex" role="group" aria-label="Basic example">
                            {!props.total_rows && (props.data?.length > limit) && limit > 0 &&
                                <button type="button" className="btn btn-default" onClick={handleFirst}>First</button>
                            }
                            {(!props.total_rows && limit > 0 && props.data?.length > limit || props.total_rows > limit) &&
                                <button type="button" className="btn btn-default" onClick={handlePrevious}>Prev</button>
                            }
                            {gap_low &&
                                <button type="button" className="btn btn-default">...</button>
                            }
                            {!props.total_rows &&
                                pagination
                            }
                            {gap_high &&
                                <button type="button" className="btn btn-default">...</button>
                            }
                            {props.total_rows > 0 && props.total_rows > limit &&
                                <button type="button" className="btn btn-primary">{`${page + 1} / ${max_page + 1}`}</button>
                            }
                            {(!props.total_rows && limit > 0 && props.data?.length > limit || props.total_rows > limit) &&
                                <button type="button" className="btn btn-default" onClick={() => handleNext(max_page)}>Next</button>
                            }
                            {!props.total_rows && limit > 0 && props.data?.length > limit &&
                                <button type="button" className="btn btn-default" onClick={() => handleLast(max_page)}>Last</button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
});