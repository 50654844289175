import React, { Fragment } from 'react';
import StateOptions from 'components/common/state_options';
import { ADDRESSES } from 'components/constants';
import _ from 'lodash';

export function address_fields() {

	return (
		{
			addressName: '',
			addressTypeId: '',
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
		}
	);
}
export function address_form_layout(source) {

	let addressTypes = _.filter(Object.entries(ADDRESSES), (o) => { return o[1].source.includes(source) });
	var address_type_options = addressTypes.map((type) => (<option key={type[1].id} value={type[1].id}>{type[1].name}</option>));

	return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Address Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-6',
					label: 'Location Name',
					field: 'addressName',
					type: 'text',
				}, {
					grid: 'col-6',
					label: 'Type',
					field: 'addressTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">- Select -</option>
							{address_type_options}
						</Fragment>
				}, {
					grid: 'col-8',
					label: 'Address',
					field: 'addressLine1',
					type: 'location-autocomplete',
					prepend: <i className="fas fa-map-marker-alt"></i>,
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Secondary Address',
					field: 'addressLine2',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'City',
					field: 'city',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'State',
					field: 'state',
					type: 'select',
					options:
						<Fragment>
							<option value="">- Select -</option>
							<StateOptions />,
						</Fragment>,
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Zip',
					field: 'zip',
					type: 'text',
					valid: ['required'],
				}],
			}],
		}]
	);
}