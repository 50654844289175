import './react-pro-sidebar.css';
import Authorize from 'components/common/authorize';
import React, { useRef } from 'react';
import UserProfile from 'components/layout/user-profile';
import moment from 'moment';
import { APPS, PERMISSIONS } from 'components/constants';
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, useProSidebar, } from 'react-pro-sidebar';
import { getAuth } from "firebase/auth";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

export default function SideMenu(props) {

    const ref = useRef(null);
	const params = useParams();

    const settings = useSelector((store) => store.settings.settings);
	const userPermissions = useSelector(store => store.users?.user_permissions);

    const firebaseUser = getAuth().currentUser;
    const email = firebaseUser?.email;
    const { collapseSidebar, collapsed } = useProSidebar();
    const customerAssetsEnabled = settings?.assets?.customerAssets;
    const settingsPermissions = _.chain(Object.values(PERMISSIONS)).filter((item) => item.group == 'settings').orderBy(['order'], ['asc']).value();

    var firstAuthorizedSetting = null;
    settingsPermissions?.forEach((perm) => {
        if (userPermissions[perm.key] && !firstAuthorizedSetting) firstAuthorizedSetting = perm.page;
    });
    if (!firstAuthorizedSetting) firstAuthorizedSetting = 'user_preferences';

    return (

        <div ref={ref} style={{ display: 'flex', height: '100%', position: 'absolute', zIndex: 1999 }}>
            <Sidebar
                width={'220px'}
                style={{ height: '100vh' }}
                defaultCollapsed={false}
                collapsedWidth='80px'
                backgroundColor='#2f4050'
            >
                <div style={{ minHeight: '195px', marginBottom: '20px' }} >
                    {props.userRoles && props.user && email &&
                        <UserProfile container={ref} user={props.user} email={email} userRoles={props.userRoles} collapsed={collapsed} />
                    }
                </div>
                <Menu iconShape="circle" closeOnClick={true}>

                    <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/dashboard`)}
                        icon={<i className="fa-solid fa-gauge fa-xl"></i>}
                        component={<Link replace to={`/${props.handleApp}/dashboard`} />}
                    >DASHBOARD
                    </MenuItem>

                    { (params.appId == APPS.SERVICE.id || customerAssetsEnabled) &&
                        <Authorize perm={ PERMISSIONS.CUSTOMERS_VIEW }>
                            <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/customers`)}
                                icon={<i className="fa-solid fa-users fa-xl"></i>}
                                component={<Link  replace to={`/${props.handleApp}/customers`} />}
                            >CUSTOMERS
                            </MenuItem>
                        </Authorize>
                    }    

                    <Authorize appId={ APPS.ASSETS.id } perm={ PERMISSIONS.ASSETS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/assets`)}
                            icon={<i className="fa-solid fa-truck-container fa-xl"></i>}
                            component={<Link replace to={`/${props.handleApp}/assets`} />}        
                        >ASSETS
                        </MenuItem>
                    </Authorize>

                    {/* <Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/service`)}
                            icon={<i className="fa-solid fa-wrench fa-xl"></i>}
                            component={<Link  replace to={`/${props.handleApp}/service`} />}
                        >SERVICE
                        </MenuItem>
                    </Authorize> */}

                    <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/inventory`)}
                            icon={<i className="fa-solid fa-scanner-gun fa-xl"></i>}
                            component={<Link replace to={`/${props.handleApp}/inventory`} />}
                        >INVENTORY
                        </MenuItem>
                    </Authorize>

                    <Authorize perm={ PERMISSIONS.VENDORS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/vendors`)}
                            icon={<i className="fa-solid fa-warehouse fa-xl"></i>}
                            component={<Link replace to={`/${props.handleApp}/vendors`} />}
                        >VENDORS
                        </MenuItem>
                    </Authorize>
                    {/* <Authorize perm={ PERMISSIONS.PURCHASE_ORDERS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/vendors`)}
                            icon={<i className="fas fa-file-alt fa-lg"></i>}
                            component={<Link replace to={`/${props.handleApp}/vendors`} />}
                        >PURCHASE ORDERS
                        </MenuItem>
                    </Authorize> */}


                    {/* <MenuItem
                        active={props.location.pathname.startsWith('/' + props.handleApp + '/purchase_orders')}
                        icon={<i className="fas fa-file-alt fa-lg"></i>}
                        component={<Link replace to={'/' + props.handleApp + '/purchase_orders'} />}
                    >
                        PURCHASE ORDERS
                    </MenuItem> */}

                    <Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/employees`)}
                            icon={<i className="fa-solid fa-id-badge fa-xl"></i>}
                            component={<Link replace to={`/${props.handleApp}/employees`} />}
                        >EMPLOYEES
                        </MenuItem>
                    </Authorize>

                    {/* <Authorize perm={ PERMISSIONS.SCHEDULE_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/map`)}
                            icon={<i className="fa-solid fa-map-location-dot fa-xl"></i>}
                            component={<Link replace to={`/${props.handleApp}/map/0/${moment().format('YYYY-MM-DD')}`} />}
                        >SERVICE MAP
                        </MenuItem>
                    </Authorize> */}

                    <Authorize perm={ PERMISSIONS.SCHEDULE_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/schedule`)}
                            icon={<i className="fa-solid fa-calendar-days fa-xl"></i>}
                            component={<Link replace to={`/${props.handleApp}/schedule/${moment().format('YYYY-MM-DD')}`} />}
                        >SCHEDULE
                        </MenuItem>
                    </Authorize>

                    <div className="mb-5"></div>

                    <Authorize perm={ PERMISSIONS.ARCHIVES_VIEW }>
                        <MenuItem rootStyles={{ position: 'absolute', left: 0, bottom: 100 }}
                            active={props.location.pathname.startsWith(`/${props.handleApp}/archive`)}
                            icon={<i className="fa-solid fa-box-archive fa-xl"></i>}
                            component={<Link to={`/${props.handleApp}/archive`} />}
                        >ARCHIVE
                        </MenuItem>
                    </Authorize>
                    
                    <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/settings`)}
                        rootStyles={{ position: 'absolute', left: 0, bottom: 50 }}
                        icon={<i className="fa-solid fa-gear fa-xl"></i>}
                        component={<Link to={`/${props.handleApp}/settings/${firstAuthorizedSetting}`} />}
                    >SETTINGS
                    </MenuItem>
                </Menu>
                <main style={{ position: 'absolute', left: 10, bottom: 5 }}>
                    <center>
                        {window.build}
                    </center>
                </main>
            </Sidebar>
        </div>
    );
}
