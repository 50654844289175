import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import * as toolbox from 'components/common/toolbox';
import AttachementGallery from 'components/attachments/attachment-gallery';
import AttachmentForm from 'components/attachments/attachment-form/attachment-form';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Ibox } from 'enspire-manager-framework';
import { PERMISSIONS } from 'components/constants';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Table } from 'em-table';
import { imageSize } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux'

const AttachmentsTab = (props) => {

    // CANNOT HAVE TWO ATTACHMENT-TABS ACTIVE AT THE SAME TIME OR REFRESH LIMIT WILL OCCUR

    // props.id - id for attachment doc
    // props.source - path to doc e.g. 'assets' to read/write '[path]/[path]/[id]' 
    // props.prefix - prefix for Action Type e.g. [PREFIX]_ATTACHMENTS
    // props.field - for changing from default "attachments"
    // props.store & props.singular for accessing store e.g. useSelector((store) => [store].[singular]);

	const seg = 2;
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
	const routeLocation = useLocation();
    const pathname = routeLocation.pathname;

	const admin = useSelector((store) => store.admin);
    const table = useSelector((store) => store[props.store]);
    const user_permissions = useSelector(store => store.users?.user_permissions);
    
    const field = (props.field) ? props.field : 'attachments';
    const attachments = table[props.singular + '_' + field] ?? [];

    const [ data, setData ] = useState([]);
    const [ galleryPhotos, setGalleryPhotos ] = useState([]);
    const [ tabView, setTabView ] = useState('list');

    useEffect(() => {
		dispatch(actions_attachments.subAttachments(params.handle, props.id, props.source, props.prefix, { field }));
		return () => {
			const unsubscribe = table[`${props.singular}_${field}_unsubscribe`];
			if (typeof unsubscribe == 'function') unsubscribe();
		};
	}, [params.handle, props.id, props.source]);

	useEffect(() => {
        var newPhotos = [];

        attachments.forEach((attach, i) => {
            if (attach.imageUrl) newPhotos.push({ name: attach.name, image: attach.imageUrl });
            if (attach.imagesAlt) attach.imagesAlt.forEach((image) => {
                newPhotos.push({ name: attach.name, image });
            });
        });
        const result = Object.groupBy(newPhotos, ({ name }) => name);
        setGalleryPhotos(result);

	}, [attachments]);

	useEffect(() => {
        var newData = [];       
        attachments.forEach((attach, i) => {
            newData.push({ ...attach, index: i, images: (attach.imageUrl) ? <i className="fa-solid fa-camera fa-xl" role="button" onClick={ () => toggleLightbox(attach) }></i> : null });
        });

        setData(newData)
	}, [attachments]);

    /* Handlers --------------------------*/

    const handleAddNew = () => {
		let location = toolbox.modifyPath(pathname, seg+3, "attachment-form/0");
		history.push({ pathname: location });
    };

    /* Actions --------------------------*/
    
	const openForm = (item) => {
        let location = toolbox.modifyPath(pathname, seg+3, `attachment-form/${item.index + 1}`);
        history.push({ pathname: location });
	};

	const toggleLightbox = (attach) => {
		let imagesAlt = attach.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(attach.imageUrl, 'lg') },
			...(attach.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}

    /* Constants --------------------------*/

    const columns = [
        { name: 'Name', field: 'name', width: 80},
        { name: 'Image', field: 'images', type: 'jsx', width: 10 },
        { name: '', field: 'id', type: 'button', button: { name: <i className="fa-regular fa-gear fa-lg text-muted"></i>, className: 'btn-default btn-xs float-right', callback: openForm }, width: 10 }
    ];

    var galleries = [];
    for (let name of Object.keys(galleryPhotos)) {
        galleries.push(<AttachementGallery name={ name  } photos={ galleryPhotos[name] } />);
    }
   
    return (
        <>
            <Ibox 
                jsx = {
                    <ButtonGroup className="mt-n1" style={{ marginRight: '120px' }}>
                        <Button variant={ (tabView == 'list') ? 'secondary' : 'default' } onClick={ () => setTabView('list') }>List View</Button>
                        <Button variant={ (tabView == 'gallery') ? 'secondary' : 'default' } onClick={ () => setTabView('gallery') }>Gallery View</Button>
                        { props.store == 'serviceRequests' &&
                            <Button variant={ (tabView == 'all') ? 'secondary' : 'default' } onClick={ () => setTabView('all') }>Work Orders Gallery</Button>
                        }
                    </ButtonGroup>
                }
                title={' '}
                button={(props.button) ? props.button : '+ Attachment'}
                button_callback={handleAddNew}
                button_disabled={!user_permissions.ASSETS_CREATE && !user_permissions.ASSETS_EDIT}
            >
                { tabView == 'list'
                    ?   <>
                            <h3>{ `${props.name} Attachments` }</h3>
                            <Table id="attachments-tab"
                                data={ data }
                                columns={columns}
                                on_focus={(target) => props.setFocused(target)}
                                style={{ minHeight: '330px' }}
                            />
                        </>
                    :   galleries
                }

                { admin.flutter_keyboard &&
                    <div style={{ paddingBottom: '55vh' }}></div>
                }
            </Ibox>

            <Route path={ "/:handle/:appId/:entity/:asset_id?/attachment-form/:attachment_index/:tab?"} render={(route_props) => (
                <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                    <CatchErrors modal={true}>
                        <AttachmentForm {...route_props}
                            expires={ props.expires }
                            field={ props.field } 
                            id={ props.id } 
                            invoice={ props.invoice }
                            prefix={ props.prefix } 
                            singular={ props.singular } 
                            source={ props.source } 
                            store={ props.store } 
                        />
                    </CatchErrors>
                </Authorize>
            )} />

        </>
    );
};

export default AttachmentsTab;