import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function TooltipOverlay(props) {

    return (
        <OverlayTrigger 
            placement={(props.placement) ? props.placement : 'top'} 
            delay={(props.delay) ? props.delay : 300} 
            overlay={<Tooltip>{ props.tooltip }</Tooltip>}
        >
            { props.children }
        </OverlayTrigger>
    );
};
