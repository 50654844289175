import React, { useEffect, useState, useRef } from 'react';

const ExpandDetails = (props) => {

	const [height, setHeight] = useState(0); // current height of section
    const [currentLimit, setCurrentLimit] = useState(0); // current limit amount
    const [hideEllipsis, setHideEllipsis] = useState(false);

	const ref = useRef(null);
	const limitHeight = (props.limitHeight) ? props.limitHeight: 150; // Height to limit
    const buttonTop = (props.top) ? props.top : -24;

	useEffect(() => {
        if (props.default == 'open') setCurrentLimit(999);
        if (props.default == 'closed') setCurrentLimit(0);
        if (!props.default) setCurrentLimit(limitHeight);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setHeight(ref.current?.clientHeight);
            let difference = (ref.current?.scrollHeight - ref.current?.clientHeight);
            if (height && height == currentLimit && difference < 20) setCurrentLimit(999);
		}, 600);
	});
    
	useEffect(() => {
		if (typeof props.callback === 'function') {
            props.callback(currentLimit)
        }
	}, [currentLimit]);

    const handleButton = () => {
        setHideEllipsis(true);

        // if (currentLimit && currentLimit == height) {
        //     setCurrentLimit(999);


        if (!currentLimit)  {
            setCurrentLimit(limitHeight);
        } else if (currentLimit && currentLimit == height) {
            setCurrentLimit(999);
        } else {
            setCurrentLimit(0);
        }

        setTimeout(() => {
            setHideEllipsis(false);
        }, 1000);
    }

	return (
        <div className="position-relative">
            <button 
                className="btn btn-default btn-md position-absolute" 
                style={{ top: buttonTop, right: 5, zIndex: 1, borderRadius: '25px' }}
                onClick={ handleButton }
            >
                { height == currentLimit
                    ?	<>
                            { (props.more) ? <span className="mr-1">{ props.more }</span> : '' }
                            <i className={ `fas ${(props.more) ? 'fa-angle-down' : 'fa-chevron-down'} text-muted`}></i>
                        </>
                    :	<>
                            { (props.less) ? <span className="mr-1">{ props.less }</span> : '' }
                            <i className={ `fas ${(props.less) ? 'fa-angle-up' : 'fa-chevron-up'} text-muted`}></i>
                        </>
                }
            </button>

            { height == currentLimit && !hideEllipsis &&
                <button 
                    className="btn btn-default btn-md position-absolute animated fadeIn" 
                    style={{ top: height - 5, left: '50%', transform: 'translate(-50%, 0)', zIndex: 1, borderRadius: '25px' }}
                    onClick={ handleButton }
                >
                    <i className="fas fa-ellipsis-h fa-xl text-muted"></i>
                </button>
            }

            <div ref={ref} className={(height == currentLimit)?'fade-bottom':''} style={{ 
                maxHeight: `${currentLimit}px`, 
                overflow: 'hidden', 
                position: 'relative', 
                transition: 'max-height 0.5s', 
                marginBottom: '3px', 
                marginLeft: '3px'
            }}>
                { props.children }
            </div>
        </div>

	);
}

export default ExpandDetails;

