import * as actions_admin from 'actions/admin-actions';
import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { DEFAULT_SCHEDULE } from 'components/constants';
import { LOG, ACTION } from 'components/constants';
import { getDoc } from 'firebase/firestore';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  IMPORT / EXPORT
/*-----------------------------------------------*/

export async function exportProfiles(handle, columns, type) {

	var profiles = [];
	var snapshot = await firestore.collection(handle + '/profiles/profiles').where('type', '==', type).get();
	snapshot.forEach((doc) => {
		var entry = {};
		columns.forEach((column) => {
			entry[column] = doc.data()[column];
		});
		profiles.push({ 
			...entry, 
			id: doc.id, 
			addresses: JSON.stringify(doc.data().addresses)?.replace(',', '|'), 
			contacts: JSON.stringify(doc.data().contacts)?.replace(',', '|'), 
		});
	});

	return profiles;
}
export async function importProfile(handle, profileForm, profile_type) {

	var profileId;
	var addressId;
	var contactId;
	var batch = firestore.batch();
	var profile = {};

	profileId = await nextProfileId(handle, batch);

	var { addressName, addressLine1, addressLine2, city, state, zip, email, firstName, lastName, mobile, phone, fax, contactTypeId,
		addressTypeId, notes, ...new_profile } = profileForm;

	/* Contact ---------------------------------------------------*/

	if (firstName || lastName || email || mobile || phone) {
		contactId = await nextContactId(handle, batch);
		var contact = { contactTypeId: (contactTypeId) ? contactTypeId : '2', firstName, lastName, email, mobile, phone, fax, notes };
		contact.profileIds = [profileId];
		contact.notifyViaEmail = true;
		contact.notifyViaSms = true;
		contact.deleted = false;
		contact.created = new Date();
		contact.modified = new Date();
		if (profileForm?.importId) contact.importId = profileForm.importId;

		const contactRef = firestore.collection(handle + '/contacts/contacts');
		batch.set(contactRef.doc(contactId), { ...contact }, { merge: true });
	}

	/* Address ---------------------------------------------------*/

	if (addressName || addressLine1 || addressLine2 || city || state || zip) {
		addressId = await nextAddressId(handle, batch);
		var address = { addressId, addressTypeId: (addressTypeId) ? addressTypeId : '5', contactIds: [contactId], addressName, 
			addressLine1, addressLine2, city, state, zip, };
		address.profileId = profileId;
		address.deleted = false;
		address.created = new Date();
		address.modified = new Date();

		Object.keys(address).forEach((key) => { if (address[key] == undefined) delete address[key]; });

		const addressRef = firestore.collection(handle + '/addresses/addresses');
		batch.set(addressRef.doc(addressId), { ...address }, { merge: true });
	}

	/* profile ---------------------------------------------------*/

	profile = { ...new_profile, phone: (phone) ? phone : mobile };
	profile.created = new Date();
	profile.deleted = false;

	profile.modified = new Date();
	profile.type = profile_type;

	const profileRef = firestore.collection(handle + '/profiles/profiles');
	batch.set(profileRef.doc(profileId), { ...profile }, { merge: true });

	await batch.commit().catch((error) => {
		toolbox.process_error(error, 'Profile NOT Saved!');
	});
};

/*-----------------------------------------------*/
/*  EMPLOYEES
/*-----------------------------------------------*/

export function subEmployees(handle) {

	return async dispatch => {

		var unsubscribe = firestore.collection(handle + '/profiles/profiles/').where('type', '==', 'employee').where('deleted', '==', false).onSnapshot(async (snapshot) => {

			dispatch({ type: types.EMPLOYEES + '_PENDING' });

			var employees = [];
			snapshot.forEach((doc) => {
				var employee = { ...doc.data(), id: doc.id };
				employee.contact = (employee.contacts && employee.contacts != {}) ? Object.values(employee.contacts)?.[0] : {};		
				employees.push(employee);
			});

			dispatch({ type: types.EMPLOYEES + '_FULFILLED', data: employees, unsubscribe });
		});
	};
}

/*-----------------------------------------------*/
/*  PROFILES
/*-----------------------------------------------*/

export function subProfile(handle, id, profile_type) {

	return dispatch => {

		dispatch({ type: types[profile_type] + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/profiles/profiles').doc(id).onSnapshot(async (doc) => {
			var profile = null;

			if (doc.exists) {
				profile = { ...doc.data(), id: doc.id };
				var addresses = [];
				var contacts = [];
				var user = null;

				const snapshot1 = await firestore.collection(handle + '/addresses/addresses').where('profileId', '==', profile.id).get();
				snapshot1.forEach((doc) => {
					addresses.push({ ...doc.data(), id: doc.id });
				});

				const snapshot2 = await firestore.collection(handle + '/contacts/contacts').where('profileIds', 'array-contains', profile.id).get();
				snapshot2.forEach((doc) => {
					contacts.push({ ...doc.data(), id: doc.id });
				});

				if (contacts?.[0]?.email) {
					const userDoc = await firestore.collection(handle + '/users/users').doc(contacts[0].email).get();
					user = userDoc.data();
				}

				profile = { ...profile, ...user };
				profile.addresses = addresses;
				
				if (profile_type == 'EMPLOYEE') {
					profile.contact = contacts[0];
				} else {
					profile.contacts = contacts;
				}
			}

			dispatch({ type: types[profile_type] + '_FULFILLED', data: profile, unsubscribe });
		});
	};
}
export function saveProfile({ handle, profileId, profileForm, profileType, prevEmail, silent = false, callback }) {

	// Primarily Updates Profile, but also creates associated Contact, Address, & User for new profiles.

	return async dispatch => {

		dispatch({ type: types['PROFILE'] + '_SAVE_PENDING' });

		var addressId;
		var contactId;
		var user;
		var batch = firestore.batch();
		var type = profileType?.toLowerCase();

		var { addressName, addressLine1, addressLine2, city, state, zip, profileIds, email, firstName, lastName, mobile, contactPhone, contactTypeId, 
			addressTypeId, notifyViaEmail, notifyViaSms, photoUrl, roles,
			activated, ...profile_fields } = profileForm;

		const fields = [
			'appIds',
			'displayName',
			'customerTypeId',
			'companyName',
			'phone',
			'fax',
			'website',
			'notes',
			'departmentId', 
			'website',
			'tags', 
			'hireDate',
			'terminationDate',
			'roles',
			'certifications', 
			'schedule',
			'showOnSchedule',
			'deleted',
			'rateBill',
			'ratePay',
			'taxExempt',
			'type',
		];

		var profile = toolbox.sanitize(profile_fields, fields);
	
		if (roles) profile = { ...profile, roles: roles.map((role) => role.value) };

		/* New Profile -----------------------------------------------------------------*/

		if (!parseInt(profileId)) {
			// Check if an employee is already registered under this email
			if (profileType == "EMPLOYEE" && email) {
				const userRef = firestore.collection(handle + "/users/users").doc(profileForm.email);
				const userDoc = await getDoc(userRef);
				if (userDoc.exists() && !userDoc.data()?.['deleted']) {
					toolbox.process_error('A user already exists with this email!');
					dispatch({ type: types[profileType] + '_SAVE_FULFILLED' });
					return;
				}
			}
			
			if (profileType == "EMPLOYEE") profile.schedule = DEFAULT_SCHEDULE;
			profile.type = type;
			profileId = await nextProfileId(handle, batch);

			/* Contact ---------------------------------------------------*/

			if (firstName || lastName || email || mobile || contactPhone) {
				contactId = await nextContactId(handle, batch);
				var contact = { firstName, lastName, email, mobile, 
					contactTypeId: (contactTypeId) ? contactTypeId : null,  
					contactPhone: (contactPhone) ? contactPhone : null, 
				};
				contact.profileIds = [profileId];
				contact.notifyViaEmail = true;
				contact.notifyViaSms = true;
				contact.photoUrl = (photoUrl) ? photoUrl : null;
				contact.type = type;
				contact.deleted = false;
				contact.created = new Date();
				contact.modified = new Date();
				if (profileForm?.importId) contact.importId = profileForm.importId;

				const contactRef = firestore.collection(handle + '/contacts/contacts');
				batch.set(contactRef.doc(contactId), { ...contact }, { merge: true });
			}

			/* Address ---------------------------------------------------*/

			if (addressName || addressLine1 || addressLine2 || city || state || zip) {
				addressId = await nextAddressId(handle, batch);
				var address = { addressId, addressTypeId, addressName, addressLine1, addressLine2, city, state, zip, };
				if (contactId) address.contactIds = [contactId];
				address.profileId = profileId;
				address.deleted = false;
				address.created = new Date();
				address.modified = new Date();

				const addressRef = firestore.collection(handle + '/addresses/addresses');
				batch.set(addressRef.doc(addressId), { ...address }, { merge: true });
			}

			/* user ------------------------------------------------------*/

			if (profileType == "EMPLOYEE" && email) {
				user = { roles: roles.map((role) => role.value) };
				user.email = email; // needed for collection group query accross users
				user.deleted = false;
				user.firstName = firstName;
				user.lastName = lastName;
				user.profileId = profileId;

				const usersRef = firestore.collection(handle + '/users/users');
				batch.set(usersRef.doc(email), user, { merge: true });
			}

			/* profile ---------------------------------------------------*/

			profile.created = new Date();
			profile.deleted = false;

		} else {
			if (profileType == "EMPLOYEE") {
				const usersRef = firestore.collection(handle + '/users/users');
				if (prevEmail && prevEmail != email) {
					batch.delete(usersRef.doc(prevEmail));
				}
				if (email) {
					user = {};
					if (roles) user.roles = roles.map((role) => role.value);
					user.deleted = false;
					user.firstName = firstName;
					user.lastName = lastName;
					user.profileId = profileId;

					batch.set(usersRef.doc(email), user, { merge: true });
				}
			}
		}

		profile.modified = new Date();

		const profileRef = firestore.collection(handle + '/profiles/profiles');
		batch.set(profileRef.doc(profileId), { ...profile }, { merge: true });

		await batch.commit().then(() => {
			dispatch({ type: types['PROFILE'] + '_SAVE_FULFILLED', data: profile });
			(!silent) && window.toastr.success('The Profile has been successfully saved/updated', 'Profile Saved!');
			if (typeof callback === 'function') callback(profileId, addressId, contactId);
		}).catch((error) => {
			toolbox.process_error(error, 'Profile NOT Saved!');
		});
	};
}
export function deleteProfile(handle, profileId, profile_type, callback) {

	return async dispatch => {

		dispatch({ type: types[profile_type] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const profileRef = firestore.collection(handle + '/profiles/profiles');
		batch.set(profileRef.doc(profileId), { deleted: true, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types[profile_type] + '_SAVE_FULFILLED' });
			window.toastr.success('The Profile record has been Archived', 'Profile Archived!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Profile NOT Archived!');
		});
	};
}
export function clearProfile() {

	return dispatch => {
		dispatch({ type: types.PROFILE + '_CLEAR' });
	};
}

/*-----------------------------------------------*/
/*  ADDRESSES
/*-----------------------------------------------*/

export function getAddress(handle, id) {

	return dispatch => {

		dispatch({ type: types.ADDRESS + '_PENDING' });

		firestore.collection(handle + '/addresses/addresses').doc(id).get().then((doc) => {
			var address = { ...doc.data(), id: doc.id };
			dispatch({ type: types.ADDRESS + '_FULFILLED', data: address });
		});
	};
}
export function addressSelected(address) {

	return dispatch => {
		dispatch({ type: types.ADDRESS + '_SELECTED', data: address });
	};
}

export function clearAddress() {

	return dispatch => {
		dispatch({ type: types.ADDRESS + '_CLEAR' });
	};
}
export function saveAddress(handle, profileId, address, callback) {

	return async dispatch => {

		dispatch({ type: types.ADDRESS + '_SAVE_PENDING' });

		const fields = [
			'addressTypeId', 
			'addressName',
			'addressLine1', 
			'addressLine2', 
			'city', 
			'state', 
			'zip',
			'deleted',
		];

		var batch = firestore.batch();
		var addressId = address.id;
		var update = toolbox.sanitize(address, fields);

		if (!parseInt(addressId)) {
			addressId = await nextAddressId(handle, batch);
			update.profileId = profileId;
			update.created = new Date();
		}
		update.modified = new Date();

		var { addressTypeId, addressName, addressLine1, addressLine2, city, state, zip } = address;
		update = { ...update, addressTypeId: (addressTypeId) ? addressTypeId : null, addressName, addressLine1, addressLine2, 
			city, state, zip };

		batch.set(firestore.collection(handle + '/addresses/addresses').doc(addressId), { ...update }, { merge: true });

		batch.commit().then((doc) => {
			dispatch({ type: types.ADDRESS + '_FULFILLED', data: address });
			window.toastr.success('The Address record has been successfully saved/updated', 'Address Saved!');
			if (typeof callback === 'function') callback(addressId);
		});
	};
}
export function deleteAddress(handle, address, callback) {

	return async dispatch => {

		dispatch({ type: types.ADDRESS + '_SAVE_PENDING' });
		
		var batch = firestore.batch();
		batch.delete(firestore.collection(handle + '/addresses/addresses').doc(address.id));
		batch.commit().then((doc) => {
			dispatch({ type: types.ADDRESS + '_FULFILLED', data: null });
			window.toastr.success('The Address record has been successfully deleted', 'Address Deleted!');
			if (typeof callback === 'function') callback(address.id);
		});
	};
}

/*-----------------------------------------------*/
/*  CONTACTS
/*-----------------------------------------------*/

export function getContact(handle, id) {
	
	return dispatch => {

		dispatch({ type: types.CONTACT + '_PENDING' });
		
		firestore.collection(handle + '/contacts/contacts').doc(id).get().then((doc) => {
			var contact = { ...doc.data(), id: doc.id };
			
			dispatch({ type: types.CONTACT + '_FULFILLED', data: contact });
		});
	};
}
export function getContactFromUserEmail(handle, email) {
	
	return dispatch => {

		dispatch({ type: types.CONTACT + '_PENDING' });
		
		firestore.collection(handle + '/contacts/contacts').where('email', '==', email).get().then((snapshot) => {
			let doc = snapshot.docs[0];
			var contact = { ...doc.data(), id: doc.id };
			
			dispatch({ type: types.CONTACT + '_FULFILLED', data: contact });
		});
	};
}
export function clearContact() {

	return dispatch => {
		dispatch({ type: types.CONTACT + '_CLEAR' });
	};
}
export function saveContact(handle, profileId, contact, type, callback) {

	return async dispatch => {

		dispatch({ type: types.CONTACT + '_SAVE_PENDING' });

		const fields = [
			'contactTypeId', 
			'profileIds',
			'firstName', 
			'lastName', 
			'email', 
			'mobile', 
			'phone',
			'notes',
			'photoUrl',
			'dashboards',
			'notifyViaEmail',
			'notifyViaSms',
			'type',
			'deleted',
		];
		
		var batch = firestore.batch();
		var contactId = contact.id;
		var update = toolbox.sanitize(contact, fields);

		if (!parseInt(contactId)) {
			contactId = await nextContactId(handle, batch);
			update.profileIds = firebase.firestore.FieldValue.arrayUnion(profileId);
			update.type = type;
			update.created = new Date();
			update.deleted = false;
		} else {
			contactId = contact.id;
		}
		update.modified = new Date();

		if (parseInt(contact.prevLinkAddressId)) {
			batch.update(firestore.collection(handle + '/addresses/addresses').doc(contact.prevLinkAddressId), {
				contactIds: firebase.firestore.FieldValue.arrayRemove(contactId)
			});
		}
		if (parseInt(contact.linkAddressId)) {
			batch.update(firestore.collection(handle + '/addresses/addresses').doc(contact.linkAddressId), {
				contactIds: firebase.firestore.FieldValue.arrayUnion(contactId)
			});
		}

		batch.set(firestore.collection(handle + '/contacts/contacts').doc(contactId), { ...update }, { merge: true });
		batch.set(firestore.collection(handle + '/profiles/profiles').doc(profileId), { modified: new Date() }, { merge: true });

		// const contactDoc = await firestore.collection(handle + '/contacts/contacts').doc(contact.id).get();
		// const contactRecord = contactDoc.data();
		// if (contactRecord.email) batch.set(firestore.collection(handle + '/users/users').doc(contactRecord.email), { modified: new Date() }, { merge: true });

		batch.commit().then((doc) => {
			dispatch({ type: types.CONTACT + '_FULFILLED', data: contact });
			if (typeof callback === 'function') callback(contactId);
		});
	};
}
export function deleteContact(handle, contact, callback) {

	return async dispatch => {

		dispatch({ type: types.CONTACT + '_SAVE_PENDING' });
		
		var batch = firestore.batch();
		batch.delete(firestore.collection(handle + '/contacts/contacts').doc(contact.id));
		batch.commit().then((doc) => {
			dispatch({ type: types.CONTACT + '_FULFILLED', data: null });
			window.toastr.success('The Contact record has been successfully deleted', 'Contact Deleted!');
			if (typeof callback === 'function') callback(contact.id);
		});
	};
}

/*-----------------------------------------------*/
/*  NEXT IDS
/*-----------------------------------------------*/

async function nextProfileId(handle, batch) {
	const table = 'profiles';
	const field = 'nextProfileId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextAddressId(handle, batch) {
	const table = 'addresses';
	const field = 'nextAddressId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextContactId(handle, batch) {
	const table = 'contacts';
	const field = 'nextContactId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

// export function transform(handle) {

//     return async dispatch => {

//         const batch = firestore.batch();
//         const profilesRef = firestore.collection(handle + '/profiles/profiles').where('type', '==', 'vendor');
        
//         var profiles = [];
//         const snapshot = await profilesRef.get();
//         snapshot.forEach((doc) => {
//             profiles.push({ ...doc.data(), id: doc.id });
//         });

//         for (let profile of profiles) {
// 			if (profile.addresses) {
// 				for (let addressId of Object.keys(profile.addresses)) {
// 					const addressRef = firestore.collection(handle + '/addresses/addresses').doc(addressId);
// 					let update = {
// 						addressTypeId: '1',
// 					}
// 					console.log(addressId, update);
// 					batch.update(addressRef, { ...update });
// 				}
// 			}
//         }
        
//         batch.commit().then(() => {
//             window.toastr.success('The Profile record has been successfully saved/updated', 'Profile Saved!');
//         }).catch((error) => {
//             toolbox.process_error(error, 'Profile NOT Saved!');
//         });
//     };
// }

