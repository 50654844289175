import * as toolbox from 'components/common/toolbox';
import ActivityLog from 'components/activity-log/activity-log';
import AttachmentsTab from 'components/attachments/attachments-tab';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import ExternalInvoicesTab from 'components/attachments/external-invoices-tab';
import LineItemsTab from 'components/line-items/line-items-tab/line-items-tab';
import React from 'react';
import { LOG, PERMISSIONS } from 'components/constants';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { numPanels } from 'components/common/toolbox';
import { useSelector } from 'react-redux';

export default function WorkOrderTabs(props) {

    const seg = 11;
    const match = useRouteMatch();
    const params = useParams();
    const history = useHistory();
    
	const user = useSelector(store => store.users?.user);
    const work_order = useSelector((store) => store.workOrders.work_order);

    function tab_active(value) {
        return (params.tab === value) ? ' active' : ''; // returns 'active' if value tab is active
    }
	function link_tab(value, event) {
		event.preventDefault();
		setTimeout(() => {
			history.replace(toolbox.modifyPath(props.location.pathname, seg, value, seg));
		}, 50);
	}

    return (
        <>
            <Route path={match.path} render={(route_props) =>
                <Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW } or={ work_order?.technicianId == user?.profileId }>
                    <div className="tabs-container mt-3">
                        <ul className={ `nav nav-tabs underline ${(numPanels(1))?'scroll-tabs':''}` } role="tablist">
                            <li><a className={'nav-link' + tab_active('items')} onClick={link_tab.bind(this, 'items')}> Line Items</a></li>
                            <li><a className={'nav-link' + tab_active('external-invoices')} onClick={link_tab.bind(this, 'external-invoices')}> External Invoices</a></li>
                            <li><a className={'nav-link' + tab_active('attachments')} onClick={link_tab.bind(this, 'attachments')}> Attachments</a></li>
                            <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                                <li><a className={'nav-link' + tab_active('activity')} onClick={link_tab.bind(this, 'attachments')}>Activity</a></li>
                            </Authorize>
                        </ul>

                        <div className="tab-content" style={{ marginBottom: '65px' }}>
                            <div role="tabpanel" className={'tab-pane' + tab_active('items')}>
                                <div className="panel-body">
                                    <Route path={match.path} render={(route_props) =>
                                        <CatchErrors>
                                            <LineItemsTab {...route_props} source={'work-orders'} setFocused={ props.setFocused } />
                                        </CatchErrors>
                                    } />
                                </div>
                            </div>
                            <div role="tabpanel" className={'tab-pane' + tab_active('external-invoices')}>
                                <div className="panel-body">
                                    <Route path={match.path} render={(route_props) =>
                                        <CatchErrors>
                                            <ExternalInvoicesTab {...route_props} 
                                                button="+ Ext Invoice"
                                                field="external_invoices"
                                                id={ params.work_order_id } 
                                                invoice={ true }
                                                prefix="WORK_ORDER" 
                                                setFocused={ props.setFocused }
                                                singular="work_order" 
                                                source="work-orders" 
                                                store="workOrders" 
                                                title="Ext Invoices"
                                            />
                                        </CatchErrors>
                                    } />
                                </div>
                            </div>
                            <div role="tabpanel" className={'tab-pane' + tab_active('attachments')}>
                                <div className="panel-body">
                                    <Route path={match.path} render={(route_props) =>
                                        <CatchErrors>
                                            <AttachmentsTab {...route_props} 
                                                id={ params.work_order_id }
                                                name="Work Order" 
                                                prefix="WORK_ORDER"
                                                singular="work_order" 
                                                source="work-orders" 
                                                store="workOrders" 
                                                setFocused={ props.setFocused }
                                            />
                                        </CatchErrors>
                                    } />
                                </div>
                            </div>
                            <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                                <div role="tabpanel" className={'tab-pane' + tab_active('activity')}>
                                    <div className="panel-body">
                                        <Route path={props.match.path} render={(route_props) =>
                                            <CatchErrors>
                                                <ActivityLog {...route_props} 
                                                    id="work_order_id" 
                                                    prefix="WORK_ORDER" 
                                                    singular="work_order"
                                                    store="workOrders" 
                                                    type={ `${LOG.WORK_ORDER.key}` } 
                                                />
                                            </CatchErrors>
                                        } />
                                    </div>
                                </div>
                            </Authorize>
                        </div>
                    </div>
                </Authorize>
            } />
        </>
    );
};

