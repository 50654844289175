import * as actions_quotes from 'actions/quotes-actions';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Input, Select } from 'enspire-manager-framework';
import { confirmDelete } from 'components/common/toolbox';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const SelectOptions = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const dispatch = useDispatch();

	const [editMode, setEditMode] = useState(null);
	const [options, setOptions] = useState(null);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleEditMode = () => {
        if (editMode) {
            dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { options }));
            setEditMode(false);
        } else {
            setOptions(props.quote.options);
            setEditMode(true);
        }
    };
	const handleChange = (e) => {
        var newOptions = options.map((option, index) => {
            if (index == props.selectedOption) {
                return { name: e.target.value, index }
            } else return option;
        });
        setOptions(newOptions);
	};
    const deleteOption = () => {
        setEditMode(false);
        props.setSelectedOption(0);
        var newServiceItems = props.quote?.serviceItems?.filter((item) => item.option != props.selectedOption);
        var newOptions = props.quote?.options?.filter((option) => option.index != props.selectedOption);
        
        dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { options: newOptions, serviceItems: newServiceItems }, () => {
            // Update Overall Selected Option
            // if (quotes?.options_selected?.find((opt) => opt == option)) dispatch(actions_quotes.updateSelectedOptions(quotes?.options_selected?.filter((opt) => opt != option)));
        }));
    };

    return (
        <>
            { props.quote.options?.length > 1 &&
                <>
                    <label>Multiple Options</label>
                    <div className="d-flex align-items-stretch">
                        { editMode
                            ?   <Input
                                    autoFocus={ true }
                                    className="w-100"
                                    name={'optionName'}
                                    noLabel={true}
                                    onChange={handleChange}
                                    type={ 'text' }
                                    value={options[props.selectedOption].name}
                                />
                            :   <Select className="w-100"
                                    noLabel={true} 
                                    name={'assetTypeId'}
                                    value={props.selectedOption}
                                    onChange={(event) => { props.setSelectedOption(event.target.value); }}
                                >
                                    { props.quote.options.map((option, index) => 
                                        <option key={`option${index}`} value={option.index}>
                                            {option.name}
                                        </option>
                                    )}
                                </Select>
                        }
                        { editMode
                            ?   <>
                                    <button className="btn btn-success btn-sm ml-2 mb-2" onClick={ handleEditMode }>Update</button>
                                    <button className="btn btn-warning btn-sm ml-1 mb-2" onClick={ () => confirmDelete('Option', deleteOption) }>Delete</button>
                                    <button className="btn btn-default btn-sm ml-1 mb-2" onClick={ () => setEditMode(false) }>Cancel</button>
                                </>
                            :   <button className="btn btn-default btn-sm ml-2 mb-2" onClick={ handleEditMode }>Edit&nbsp;Option</button>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default SelectOptions;