import * as actions_admin from 'actions/admin-actions';
import Gallery from "react-photo-gallery";
import React, { useEffect, useState } from 'react';
import { confirmDelete, imageSize } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux'

const AttachmentGallery = (props) => {

    const dispatch = useDispatch();
    const admin = useSelector((store) => store.admin);

    useEffect(() => {
        // reset to 0 when closed
		return () => {
            dispatch(actions_admin.lightboxIndex(0));
		};
	}, []);

    const openLightbox = (e, photo) => {

        const photos = props.photos.map((entry) => {
            return ({ src: imageSize(entry.image, 'lg') })
        });
    
        if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources(photos));
		dispatch(actions_admin.lightboxIndex(photo.index));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
    }

    const galleryImages = props.photos.map((entry) => {
        return ({
            src: imageSize(entry.image, 'md'),
            width: 4,
            height: 3,
        });
    });

    return (
        <div className="p-1 mb-3"style={{ border: '1px solid #dddddd' }}>
            <Gallery photos={galleryImages} onClick={openLightbox} />
            <p className="pl-1 m-0">{props.name}</p>
        </div>
    );
};

export default AttachmentGallery;