import DateRange from 'components/common/date-range';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CloseX } from 'enspire-manager-framework';
import { numPanels } from 'components/common/toolbox';
import { useHistory } from 'react-router-dom';

export default function DashboardAnalytics(props) {
	
	const history = useHistory();
	const [showAnalytics, setShowAnalytics] = useState(false);

	/* Effects -----------------------------------------------------------------------------------------------------------------*/
	
	useEffect(() => {
		setShowAnalytics(false);
		setTimeout(() => {
			setShowAnalytics(true);
		}, 150);
	}, []);

	/* Constants -----------------------------------------------------------------------------------------------------------------*/	

	return (
		<>
			{ showAnalytics &&
				<div className="row">
					<div className="col-12">
						<div id="employee-analytics" className="col-12 mb-3 animated fadeIn">
							{ numPanels(1) && <CloseX onClick={() => history.goBack()} className="mr-3 mt-0" color={ '#aaaaaa' } /> }
							<h1 className="analytics-title">Dashboard Overview</h1>
							<div className="middleOfLine text-muted mt-4">
								<span className="fa-stack fa-2x">
									<i className="fas fa-circle fa-stack-2x"></i>
									<i className="far fa-clock fa-stack-1x fa-inverse"></i>
								</span>
							</div>
						</div>
						<DateRange {...props} showPresets={true} buttonTitle={'Update Range'} className={'mt-3'}>
							<div className="m-3 d-flex justify-content-around align-items-center" style={{ fontSize: '72px', color: '#cccccc', backgroundColor: '#eeeeee', height: '180px', overflow: 'hidden' }}>
								<i className="fa-solid fa-chart-pie-simple-circle-dollar"></i>
								<i className="fa-solid fa-list"></i>
								<i className="fa-solid fa-chart-simple"></i>
							</div>
						</DateRange>
					</div>
				</div>
			}
		</>
	);
};

