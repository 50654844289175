import CurrencyInput from 'react-currency-masked-input';
// import DatePicker from "react-multi-date-picker";
import React, { Fragment, useState, useEffect, useRef } from 'react';
// import TimePicker from "react-multi-date-picker/plugins/time_picker";
// import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import _ from 'lodash';
import escapeStringRegexp from 'escape-string-regexp';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
// import { AttachmentIcon } from '../attachment-icon';
// import { ValidateMessage } from './validate-message';
// import { elasticSearch } from '../elastic-search';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useMask } from '@react-input/mask';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

var moment = require('moment');

export function Input(props) {

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [search, setSearch] = useState('');
	const [options, setOptions] = useState([]);
	const [hits, setHits] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [disableNew, setDisableNew] = useState(false);
	const [portalTarget, setPortalTaget] = useState();
	const [reRenders, setRerenders] = useState(0);
	const [renderTypeahead, setRenderTypeahead] = useState(true);

	const datePickerRef = useRef();
	const typeaheadRef = useRef();

	const { ref } = usePlacesWidget({
		apiKey: 'AIzaSyDovWF9_-LUGkC_tNoNp0wSVqGJ8mzxl1Y',
		onPlaceSelected: (place) => props.autocomplete(place, props.name),
		options: {
			types: (props.locate) ? props.locate : ["address"], // cities, address
			componentRestrictions: { country: "us" },
		},
	});
	const conditionalRef = (props.type === 'location-autocomplete') ? { ref: ref } : {};

    useEffect(() => {
        const portalDiv = document.createElement("div");
        portalDiv.id = "myPortalDiv";
        document.body.appendChild(portalDiv);
        setPortalTaget(portalDiv);
        return () => document.body.removeChild(portalDiv);
    }, []);

	useEffect(() => {
		if (typeof props.onChange !== 'function') console.error('Missing onChange callback');
		if (props.form_error !== undefined) {
			const error = _.find(props.form_error, { field: props.name });

			if (error) {
				// const errorMessage = ValidateMessage(error);
				// if (props.form_error[0].field === props.name) {
				// 	if (ref.current) ref.current.focus();
				// 	window.toastr.error(`Please update your ${(props.form_error[0]?.subType) ? `<em>${props.form_error[0].subType}</em> ` : ''}value for <em>${props.label}</em>`, errorMessage);
				// }
				// setError(true);
				// setErrorMessage(errorMessage);
			} else {
				setError(false);
				setErrorMessage(null);
			}
		}
	}, [props.form_error]);

	useEffect(() => {
		if (props.type == 'typeahead' && props.data) {
			handleSearch(search, true)
		}
	}, [props.data])

	// Re-render Typeahead after initial defaultSelected change
	useEffect(() => {
		if (reRenders <= 1) {
			setRenderTypeahead(false);
			if (props.defaultSelected) setOptions(props.defaultSelected); // prevent ADD NEW 
			setTimeout(() => {
				setRenderTypeahead(true);
			}, 50);
		}
		setRerenders(reRenders + 1);
	}, [props.defaultSelected])

	const onChange = (event, value) => {
		if (props.inputMode == 'email') event.target.value = event.target.value.toLowerCase();
		props.onChange(event, value);
	};
	const onBlur = (event) => {
		props.onBlur?.(event);
	};
	const onFocus = (event) => {
		if (window.flutter) {
			// prevent iOS auto shift from virtual keyboard
			event.target.style.opacity = 0;
			setTimeout(() => event.target.style.opacity = 1);
		}
		props.onFocus?.(event);
	};
	const onTypeaheadChange = (result) => {
		if (result?.[0]?.target) {
			result[0].target = result[0].target.replace(new RegExp('<mark>', 'g'), '');
			result[0].target = result[0].target.replace(new RegExp('</mark>', 'g'), '');
			if (props.inputMode == 'email') result[0].target = result[0].target.toLowerCase();
		}
		const disableNew = !!(_.find(hits, (o) => o[props.target]?.trim() === result?.[0]?.target?.trim()));
		setDisableNew(disableNew);
		props.onChange(props.name, result);
	};
	const handleInc = (type, e) => {
		let value = parseFloat(props.value);
		if (isNaN(value)) value = 0;
		if (type == 'inc') value = (parseFloat(value) + 1);
		if (type == 'dec') value = (parseFloat(value) - 1);
		if (parseFloat(value) < 0) value = 0;
		props.onChange(null, value);
	}
	const handleSearch = async (search, skip) => {
		setIsLoading(true);
		const config = {
			table: props.table,
			fields: props.fields,
			sort: props.sort,
			type: props.type_filter,
		};
		if (parseInt(props.appId) >= 0) config.appId = props.appId;

		var filteredHits = [];
		var options = [];
		var data = props.data;

		if (props.onTypeaheadSearch && !skip) { // Use callback
			await props.onTypeaheadSearch(search);
			return;

		} else if (data) { // Filter based off of provided data

			// Filter data by fields that include search
			data.forEach((hit) => {
				if (!Array.isArray(props.fields)) {
					var searchField = props.fields;
					if (hit?.[searchField].includes(search)) {
						filteredHits.push(hit);
						return;
					}
				} else {
					var hitFound = false;
					props.fields.forEach((field) => {
						if (hitFound) return;
						if (hit?.[field]?.includes(search)) {
							filteredHits.push(hit);
							hitFound = true;
							return;
						}
					});
				}
			});

			// Format options with highlighted search
			filteredHits?.forEach((item) => {
				let target = '';
				if (!Array.isArray(props.target)) {
					target = item[props.target];
				} else {
					props.target.forEach((targetField) => {
						target += item[targetField] + ' ';
					});
				}
				target = target.trim();

				const highlightWords = search.split(' ');
				if (Array.isArray(highlightWords)) {
					highlightWords.forEach((word) => {
						if (word) target = target.replace(new RegExp(escapeStringRegexp(word), 'i'), (match) => `<mark>${match}</mark>`);
					});
				}

				options.push({ target: target, id: item[props.id] });
			});

		} else {
			// Elastic search
			// const hits = await elasticSearch(search, config);
			// let filteredHits = hits;
			// if (typeof props.filter_callback === 'function') filteredHits = props.filter_callback(hits);
			// options = filteredHits.map((hit) => {
			// 	let target = '';
			// 	if (!Array.isArray(props.target)) {
			// 		target = hit[props.target];
			// 	} else {
			// 		props.target.forEach((item) => {
			// 			target += hit[item] + ' ';
			// 		});
			// 	}
			// 	target = target.trim();

			// 	const highlightWords = search.split(' ');
			// 	if (Array.isArray(highlightWords)) {
			// 		highlightWords.forEach((word) => {
			// 			if (word) target = target.replace(new RegExp(escapeStringRegexp(word), 'i'), (match) => `<mark>${match}</mark>`);
			// 		});
			// 	}

			// 	return { target: target, id: hit[props.id] };
			// });
			// const disableNew = !!(_.find(filteredHits, (o) => o[props.target]?.trim() === search?.trim()));
			// setDisableNew(disableNew);
		}
		
		setOptions(options);
		setSearch(search);
		setHits(filteredHits);
		setIsLoading(false);
		return false; // prevent <Enter> key from reloading
	};
	
	const inputProps = {};
	if (props.defaultValue) inputProps.defaultValue = props.defaultValue;
	if (props.endDate) inputProps.endDate = props.endDate;
	if (props.name) inputProps.name = props.name;
	if (props.selected) inputProps.selected = props.selected;
	if (props.selectsEnd) inputProps.selectsEnd = props.selectsEnd;
	if (props.selectsStart) inputProps.selectsStart = props.selectsStart;
	if (props.startDate) inputProps.startDate = props.startDate;
	else if (props.value !== undefined) inputProps.value = props.value;

	return (
		<div className={`form-group ${props.className} ${error ? ' has-error' : ''}`}>
			{props.hideLabel ? <label>&nbsp;</label> : (
				<>
					{props.noLabel ? <></> : (
						<label style={{ whiteSpace: 'nowrap', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{props.label + (props.required ? ' *' : '')}
						</label>
					)}
				</>
			)}

			<div className={`input-group input-group-sm flex-nowrap ${props.type === 'date' ? 'date ' + props.name : ''}`}>
				{props.prepend && (
					<div className="input-group-prepend">
						{ props.type === 'date'
							?	<button className="input-group-text input-group-addon" type="button" onClick={() => datePickerRef.current.openCalendar()}>
									{props.prepend}
								</button>
							:	<span className="input-group-text input-group-addon">{props.prepend}</span>
						}
					</div>
				)}
				{props.type === 'number-inc' && (
					<div className="input-group-prepend">
						<button className="input-group-text input-group-addon" type="button" onClick={handleInc.bind(this, 'dec')}>
							<i id="dec" className="fa-solid fa-minus"></i>
						</button>
					</div>
				)}
				{(props.type === 'text' ||  props.type === 'number' ||  props.type === 'number-inc' || props.type === 'location-autocomplete') && (
					<input
						autoComplete="off"
						autoFocus={props.autoFocus}
						className="form-control form-control-sm"
						disabled={props.disabled}
						inputMode={(props.type === 'number-inc') ? 'numeric' : props.inputMode}
						name={props.name}
						onBlur={onBlur}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={props.onKeyDown?.bind(this)}
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						type={(props.inputMode) ? props.inputMode : 'text'}
						{...conditionalRef} // conditionally assign ref
						{...inputProps}
					/>
				)}
				{(props.type === 'usd') && (
					<CurrencyInput
						autoComplete="off"
						autoFocus={props.autoFocus}
						className="form-control form-control-sm"
						disabled={props.disabled}
						inputMode={'decimal'}
						name={props.name}
						onBlur={onBlur}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={props.onKeyDown}
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						type={'text'}
						{...inputProps}
					/>
				)}
				{/* {(props.type === 'tel') && (
					<input
						autoComplete="off"
						autoFocus={props.autoFocus}
						className="form-control form-control-sm"
						disabled={props.disabled}
						inputMode={'tel'}
						name={props.name}
						onBlur={onBlur}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={props.onKeyDown?.bind(this)}
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						type={(props.inputMode) ? props.inputMode : 'text'}
						{...conditionalRef} // conditionally assign ref
						{...inputProps}
						ref={useMask({ 
							mask: '(___) ___-____', 
							replacement: { _: /\d/ },
							showMask: false,
							modify: (input) => { return { showMask: true }}
						})}
					/>
				)} */}
				{props.type === 'typeahead' && (
					<>
						{ renderTypeahead &&
							<AsyncTypeahead
								allowNew={props.allowNew}
								defaultSelected={props.defaultSelected}
								filterBy={() => true}
								highlightOnlyResult={true}
								id="async-lookahead"
								inputProps={{ inputMode: props.inputMode }}
								isLoading={isLoading}
								labelKey="target"
								minLength={(props.minLength || props.minLength === 0) ? props.minLength : 1}
								newSelectionPrefix={'ADD NEW: '}
								onBlur={ () => typeaheadRef.current?.blur() }
								onChange={onTypeaheadChange}
								onFocus={(props.minLength === 0) ? () => handleSearch('') : undefined}
								onSearch={handleSearch}
								options={options}
								placeholder={props.placeholder}
								ref={typeaheadRef}
								selected={props.selected}
								style={{ flexGrow: 1 }}
								renderMenuItemChildren={(options, props) => (
									<Fragment>
										<span dangerouslySetInnerHTML={{ __html: options.target }} />
									</Fragment>
								)}
							/>
						}
					</>
				)}
				{props.type === 'typeahead' && props.allowNew && props.allowNewButton && (
					<div className="input-group-append">
						<button className="btn btn-primary" type="button" disabled={disableNew} onClick={() => { props.onChange(props.name, [{ customOption: true, target: search }]); }}>
							+ New
						</button>
					</div>
				)}
				{props.type === 'date' && (
					<LocalizationProvider dateAdapter={AdapterMoment}>	
						<MobileDatePicker
							inputRef={datePickerRef} 
							autoFocus={props.autoFocus}
							defaultValue={moment(props.defaultValue)}
							format={props.dateFormat ? props.dateFormat : 'MM-DD-YYYY'}
							minDate={(props.minDate) ? moment(props.minDate) : null}
							maxDate={(props.maxDate) ? moment(props.maxDate) : null}
							shouldDisableDate={props.filterDate}
							onChange={(momentDate, context) => props.onChange(props.name, momentDate?.toDate(), context)}
							onAccept={(momentDate, context) => props.onChange(props.name, momentDate?.toDate(), context)}
							readOnly={props.readOnly}
							value={moment(props.value)}
						/>
					</LocalizationProvider>
				)}
				{props.type === 'time' && (
					<LocalizationProvider dateAdapter={AdapterMoment}>	
						<TimePicker
							inputRef={datePickerRef} 
							autoFocus={props.autoFocus}
							defaultValue={moment(props.defaultValue)}
							format={props.dateFormat ? props.dateFormat : 'h:mm a'}
							minTime={(props.minTime) ? moment(props.minTime) : null}
							maxTime={(props.maxTime) ? moment(props.maxTime) : null}
							shouldDisableTime={props.filterTime}
							minutesStep={props.minutesStep}
							onChange={(momentDate, context) => props.onChange(props.name, momentDate?.toDate(), context)}
							onAccept={(momentDate, context) => props.onChange(props.name, momentDate?.toDate(), context)}
							readOnly={props.readOnly}
							value={moment(props.value)}
						/>
					</LocalizationProvider>
				)}
				{props.type === 'attachment' && (
					<div className="input-group input-group-sm">
						{(!props.readOnly || props.readOnly && props.value?.attachment) &&
							<div className="input-group-prepend">
								{(!props.readOnly && props.value?.attachment) &&
									<div className="input-group-text px-0 py-0">
										<button className="btn btn-xs" type="button" onClick={() => props.onChange(null, props.name, 'attachment')}><i className="fa fa-times" aria-hidden="true"></i></button>
									</div>
								}
								{/* <AttachmentIcon
									className="input-group-text px-0 py-0"
									onChange={props.onChange}
									icon={props.icon}
									attachment={props.value?.attachment}
									accept={props.accept}
									readOnly={props.readOnly}
									storage={props.storage}
									storageRef={props.storageRef}
									bin={props.bin}
									directory={props.directory}
									name={props.name}
								/> */}
							</div>
						}
						<input
							autoComplete="off"
							autoFocus={props.autoFocus}
							className="form-control form-control-sm"
							disabled={props.disabled}
							inputMode={props.inputMode}
							name={props.name}
							onBlur={props.onBlur?.bind(this)}
							onChange={(e) => props.onChange(e, props.name, 'input')}
							onFocus={onFocus}
							onKeyDown={props.onKeyDown?.bind(this)}
							placeholder={props.placeholder}
							readOnly={props.readOnly}
							ref={ref}
							type={(props.inputMode) ? props.inputMode : 'text'}
							value={props.value?.input}
							{...inputProps}
						/>
					</div>
				)}
				{props.append && (
					<div className="input-group-append input-group-sm">
						<span className="input-group-text input-group-addon">{props.append}</span>
					</div>
				)}
				{props.type === 'number-inc' && (
					<div className="input-group-append">
						<button className="input-group-text input-group-addon" type="button" onClick={handleInc.bind(this,'inc')}>
							<i id="inc" className="fa-solid fa-plus"></i>
						</button>
					</div>
				)}
				{props.clearButton && (
					<div className="input-group-append input-group-sm">
						<button className="input-group-text input-group-addon" type="button" onClick={() => props.onClear(props.name)}>
							<i className="fa fa-times" aria-hidden="true"></i>
						</button>
					</div>
				)}
			</div>
			{errorMessage && (
				<div className="invalid-feedback" style={{ display: 'block' }}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};