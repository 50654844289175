import * as actions_quotes from 'actions/quotes-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import LineItemsBuilder from 'components/line-items/line-items-builder';
import React, { useEffect, useState } from 'react';
import SelectOptions from './select-options';
import _ from 'lodash';
import moment from 'moment';
import { SERVICE_ITEMS } from 'components/constants';
import { Ibox, ModalAlert, Select, Spinner } from 'enspire-manager-framework';
import { QUOTES, WORKORDERS } from 'components/constants';
import { Table } from 'em-table';
import { columnWidths } from 'components/common/toolbox';
import { confirmChangeStatus } from 'components/common/toolbox';
import { confirmDelete } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const LineItemsTab = (props) => {
    
    /* Hooks --------------------------*/
    
    const seg = 5;
    const params = useParams();
	const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    
    const checkin_time = useSelector((store) => store.workOrders.checkin_time);
    const profile = useSelector((store) => store.profiles.profile);
    const quote = useSelector((store) => store.quotes.quote);
    const settings = useSelector(store => store.settings.settings);
    const user_permissions = useSelector((store) => store.users.user_permissions);
    const workOrder = useSelector((store) => store.workOrders.work_order);
    const workOrderServiceItems = useSelector((store) => store.workOrders.work_order_items);
    const workOrders = useSelector((store) => store.workOrders);

    const [selectedOption, setSelectedOption] = useState(0);
    const [highlight, setHighlight] = useState({});

    const isQuote = (props.source == 'quotes');
    const isWorkOrder = (props.source == 'work-orders');

    const serviceItems = (isWorkOrder) ? workOrderServiceItems : quote?.serviceItems ?? [];
    const filteredServiceItems = _.filter(serviceItems, (o) => o.type != 'external');
    const entity = (isWorkOrder) ? workOrder : quote;
    const isCompleted = (isWorkOrder)
        ?   (parseInt(entity?.statusId) >= parseInt(WORKORDERS.COMPLETE.id))
        :   (parseInt(entity?.statusId) >= parseInt(QUOTES.ACCEPTED.id));


    useEffect(() => {
        setSelectedOption((quote?.approvedOption) ? quote?.approvedOption : 0); 
    }, [quote?.approvedOption]);
    
    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const handleEdit = (field, updatedItem, newValue) => {
        let hasUpdated = false; // ensure only one gets updated
        let newLineItems = filteredServiceItems.map((item, index) => {
            if (item.type != 'external') {
                if (!hasUpdated && actions_work_orders.compareServiceItems(item, updatedItem)) {
                    let count = (field != 'count') ? item.count : newValue;
                    let price = (field != 'price') ? item.price : newValue;
                    hasUpdated = true;
                    return { ...item, [field]: newValue, calculatedPrice: price * count };
                } else {
                    return item;
                }
            }
        });
        saveUpdates(newLineItems);
    };

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const addItems = (items, source) => {
        const newLineItems = actions_work_orders.addItemToLineItems(items, selectedOption, filteredServiceItems, profile, settings);
        saveUpdates(newLineItems);
        if (source != 'checkin') history.goBack();
    }
    const removeItem = (deletedItem) => {
        let hasDeleted = false;
        let newLineItems = [];
        filteredServiceItems.forEach((item, index) => {
            if (hasDeleted || !actions_work_orders.compareServiceItems(item, deletedItem)) {
                newLineItems.push(item);
            } else {
                hasDeleted = true;
            }
        });
        saveUpdates(newLineItems);
    };
    const handleReorder = (reorderedItem) => {
        var newItems = [];
        optionItems.forEach((item, index) => {
            if (actions_work_orders.compareServiceItems(item, reorderedItem) && index) {
                let prevItem = newItems.pop();
                newItems.push(reorderedItem);
                newItems.push(prevItem);
            } else {
                newItems.push(item);
            }
        });
        saveUpdates(newItems);

        setHighlight({ id: reorderedItem.id, fade: 'in' });
        setTimeout(() => {
            setHighlight({ id: reorderedItem.id, fade: 'out' });
            setTimeout(() => {
                setHighlight({});
            }, 350);
        }, 350);
    }

    /* Actions ----------------------------*/

    const setTaxRate = (e) => {
        if (isWorkOrder) {
            dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { taxRateId: e.target.value}));
        } else if (props.source == 'quotes') {
            dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { taxRateId: e.target.value}));
        };
    }
    const saveUpdates = (newLineItems) => {
        if (isWorkOrder) {
            dispatch(actions_work_orders.updateWorkOrderServiceItems(params.handle, params.work_order_id, newLineItems));
        } else if (props.source == 'quotes') {
            let update = { ...quote, serviceItems: newLineItems };
            dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, update));
        }  
    }
    const openBuilder = () => {
        let location = toolbox.modifyPath(routeLocation?.pathname, seg, 'line-items');
        history.push({ pathname: location });
    }
    const addCheckin = (type) => {
        let item = actions_work_orders.createWorkOrderCheckinEntry(type, checkin_time, settings);
        addItems([item], 'checkin');
    }
    const createNewOption = () => {
        ModalAlert({
            title: 'Are you sure?',
            html: `Are you sure you want to create a new Option based on the current selected Option?`,
            icon: 'question',
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes',
            show_cancel: true,
            callback_success: () => {

                // Use highest option number to increment next option number
                var highestOption = 0;
                quote.options?.forEach((option) => {
                    if (option.index > highestOption) highestOption = option.index;
                });
                const newOptionNumber = parseInt(highestOption) + 1;
        
                // Copy all service items from currently selected option
                var serviceItems = [ ...quote?.serviceItems ];
                var newServiceItems = quote?.serviceItems?.filter((item) => item.option == selectedOption);
                newServiceItems = newServiceItems?.map((item) => ({ ...item, option: newOptionNumber }));
                serviceItems = [...serviceItems, ...newServiceItems];
        
                dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { options: [ ...quote.options, { 
                    name:`Option #${parseInt(newOptionNumber) + 1}`, 
                    index: newOptionNumber
                }], serviceItems }));

                setSelectedOption(newOptionNumber);
            }
        });
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const taxRate = settings?.invoices?.tax_rates?.[entity?.taxRateId]?.rate ?? 0;
    const optionItems = _.chain(serviceItems).filter((item) => item.option == selectedOption).orderBy(['type'], ['asc']).value();
    const hasInprogress = !!_.find(optionItems, { checkin: 'inprogress' });
    const hasOnsite = !!_.find(optionItems, { checkin: 'onsite' });
    const hasEnroute = !!_.find(optionItems, { checkin: 'enroute' });

    var currentType = '';
    const data = optionItems.map((item, index) => {
        let details = { ...item }
        if (currentType != item.type) {
            details._no_button = true;
            currentType = item.type;
        }
        let type = _.find(Object.values(SERVICE_ITEMS), { id: item.type });
        if (type) details = { ...details, _type_position: type.position.toString(), _display: type.name }
        details._noEdit = isCompleted;
        return details;
    });

    /*------------------------------------
            TOTALS MATH
    -------------------------------------*/
    if (entity) {
        var sub_total = 0;
        var tax_total = 0;
        var grand_total = (tax_total + sub_total);
        if (optionItems) {
            optionItems.map((item => { if (item.taxable) tax_total += (item.price * item.count) * taxRate/100; }));
            optionItems.map(item => { sub_total += (item.price * item.count); });
        }
        var totals = {
            sub_total: sub_total.toFixed(2),
            tax_total: tax_total.toFixed(2),
            grand_total: (tax_total + sub_total).toFixed(2),
            balance: grand_total.toFixed(2)
        };
    }

    const columns = [
        { name: 'Name', field: 'name', width: columnWidths([30, 50]) },
        { name: 'Qty', field: 'count', text_align: 'center', width: columnWidths([20, 10]), edit: { type: 'text', callback: handleEdit }},
        { name: 'Price', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: columnWidths([20, 15]), edit: { type: 'usd', callback: handleEdit }},
        { name: 'Total', field: 'calculatedPrice', type: 'number', format: 'usd', text_align: 'right', width: columnWidths([20, 15]) },
        { name: '', field: 'id', type: 'button', text_align: 'center', button: { name: <i className="fas fa-arrow-up"></i>, className: 'btn-secondary btn-xs', callback: handleReorder }, width: 10},
        { name: 'Description', field: 'description', edit: { type: 'textarea', placeholder: '(description)', callback: handleEdit } }, // 2nd Line
    ];
   
    return (
        <>
            { entity
                ? <>
                    <Ibox className="" show_spinner={workOrders.work_order_items_pending} no_fade={false} title={
                        <div>Service Items
                            <span className="float-right mt-n1">
                                { !isCompleted && isQuote &&
                                    <button className="btn btn-default btn-sm mr-3" onClick={createNewOption}>+ Option</button>
                                }
                                <button className="btn btn-primary btn-sm" disabled={isCompleted} onClick={openBuilder}>+ Line Items</button>
                            </span>
                        </div>
                    }>
                        { !isCompleted && isQuote &&
                            <SelectOptions quote={ quote } selectedOption={ selectedOption } setSelectedOption={ setSelectedOption } />
                        }

                        {/* SHOW CHECK-IN LABOR & TRAVEL ----------------------------------------------------------------- */}
                        
                        { !isCompleted &&
                            <div className="row">
                                { checkin_time.inprogress > 60 && !hasInprogress &&
                                    <div className="col-xl-6 animated fadeIn animation-delay-2">
                                        <div className="alert alert-info d-flex justify-content-between align-items-center">
                                            <i className="fa-solid fa-arrow-right-to-bracket fa-rotate-90 fa-2x"></i>
                                            <span>In Progress Labor: &nbsp; <strong>{ moment.duration(checkin_time.inprogress, 'seconds').format("hh:mm", { trim: false }) } hrs</strong></span>
                                            <button className="btn btn-info btn-sm" onClick={() => addCheckin('inprogress')}>Add Labor</button>
                                        </div>
                                    </div>
                                }
                                { checkin_time.onsite > 60 && !hasOnsite &&
                                    <div className="col-xl-6 animated fadeIn animation-delay-2">
                                        <div className="alert alert-success d-flex justify-content-between align-items-center">
                                            <i className="fas fa-map-marker-alt fa-2x"></i>
                                            <span>Onsite Labor: &nbsp; <strong>{ moment.duration(checkin_time.onsite, 'seconds').format("hh:mm", { trim: false }) } hrs</strong></span>
                                            <button className="btn btn-success btn-sm" onClick={() => addCheckin('onsite')}>Add Labor</button>
                                        </div>
                                    </div>
                                }
                                { checkin_time.enroute > 60 && !hasEnroute &&
                                    <div className="col-xl-6 animated fadeIn animation-delay-4">
                                        <div className="alert alert-secondary d-flex justify-content-between align-items-center">
                                            <i className="fas fa-route fa-2x"></i>
                                            <span>Enroute Travel: &nbsp; <strong>{ moment.duration(checkin_time.enroute, 'seconds').format("hh:mm", { trim: false }) } hrs</strong></span>
                                            <button className="btn btn-secondary btn-sm" onClick={() => addCheckin('enroute')}>Add Travel</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <Table id="line-items-tab"
                            active_field={'id'}
                            columns={columns}
                            data={data}
                            delete={user_permissions?.WORK_ORDERS_EDIT && !isCompleted}
                            group_by={{ fields: ['_type_position'], direction: ['asc'], display: ['_display'] }}
                            highlight_search={true}
                            on_delete={(item) => confirmDelete('Line Item', removeItem.bind(this, item), { soft: true })}
                            on_focus={(target) => { props.setFocused(target) }}
                            second_line={'description'}
                            show_search={false}
                            highlight={ highlight }
                        />

                        <div className="row">
                            {/* <div className='col-6 justify-content-start align-items-end'>
                                <Select
                                    label={'Tax Rate:'}
                                    name={'taxRateId'}
                                    onChange={setTaxRate}
                                    value={entity?.taxRateId}
                                >
                                    <option value="">None</option>
                                    {settings?.invoices?.tax_rates?.map((rate, index) => {
                                        return <option key={rate.name} value={rate.rateId ?? index}>{rate?.name} - {rate.rate}%</option>
                                    })}
                                </Select>
                            </div> */}
                            <div className="col-11 d-flex justify-content-end">
                                <div className="p-1">
                                    <table className="subtotal">
                                        <tbody>
                                            <tr>
                                                <td align="right">SUBTOTAL: </td>
                                                <td width="5"></td>
                                                <td align="right">{toolbox.formatNumber(totals.sub_total, 2, true)}</td>
                                            </tr>
                                            <tr>
                                                <td align="right">TAX ({(taxRate ?? 0)}%):</td>
                                                <td width="5"></td>
                                                <td align="right">{toolbox.formatNumber(totals.tax_total, 2, true)}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td width="5"></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td align="right" className="font-weight-bold">GRAND TOTAL: </td>
                                                <td width="5"></td>
                                                <td align="right">{ toolbox.formatNumber(totals.grand_total, 2, true) }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Ibox>
                </>
                : <Spinner center />
            }

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.form == 'line-items' &&
                <LineItemsBuilder addItems={ addItems } lineItems={ data }source={props.source} />
            }
        </>
    );
};

export default LineItemsTab;