import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { getFirestore, addDoc, doc, onSnapshot, collection, collectionGroup } from 'firebase/firestore';
import { getCountFromServer, query, limit, where, orderBy } from 'firebase/firestore';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';

const firestore = firebase.firestore();
const firestore2 = firebase.app('secondary').firestore();
const db = getFirestore();

/*-----------------------------------------------*/
/*  Dashboard
/*-----------------------------------------------*/

export function selectDashboard(panel) {
    return async dispatch => {
        dispatch({ type: types.DASHBOARD + '_SELECTED', data: panel });
    };
}

export function subCounts(handle) {

    return async dispatch => {
        
        dispatch({ type: types.DASHBOARD_COUNTS + '_PENDING' });
        const unsubscribe = onSnapshot(doc(db, `${handle}`, "counts"), { includeMetadataChanges: false }, (doc) => {
            dispatch({ type: types.DASHBOARD_COUNTS + '_FULFILLED', data: doc.data(), unsubscribe });
        });
    };
}

/*-----------------------------------------------*/
/*  Activity Log
/*-----------------------------------------------*/

export function saveActivity(handle, log, action, id, description, record, original, { tab } = {}) {

    return async dispatch => {

        try {
            let diffString = JSON.stringify(detailedDiff(original, record))
            diffString = diffString.replaceAll('undefined', "'deleted'");
            let diff = JSON.parse(diffString);

            let update = { log, action, id, description, date: new Date() };
            if (record) update.diff = diff;
            if (tab) update.tab = tab;

            var activity = toolbox.sanitize(update);
            activity.handle = handle;
            
            const userId = firebase.auth().currentUser?.email;

            dispatch({ type: types.ACTIVITY_LOG + '_SAVE_PENDING' });
            const doc = await addDoc(collection(db, `${handle}/users/users/${userId}/activity-log`), activity);
            dispatch({ type: types.ACTIVITY_LOG + '_SAVE_FULFILLED' });
        
        } catch (error) {
            toolbox.process_error(error, 'Activity NOT Logged!');
        }
    };
}
export function subActivityLog(handle, logTypes, id, prefix, limit_) {

    return async dispatch => {

		dispatch({ type: ((prefix) ? prefix + '_' : '') + types.ACTIVITY_LOG + '_CLEAR' });
        dispatch({ type: ((prefix) ? prefix + '_' : '') + types.ACTIVITY_LOG + '_PENDING' });

        logTypes = logTypes.split('|');

        var query_ = query(collectionGroup(db, 'activity-log'), where('handle', '==', handle), where('log', 'in', logTypes), orderBy('date', 'desc'));
        if (id) query_ = query(query_, where('id', '==', id));
        var query_limit_ = query(query_, limit(limit_));

        const unsubscribe = onSnapshot(query_limit_, async (querySnapshot) => {

            const snapshot = await getCountFromServer(query_);
            const count = snapshot.data().count;
            
            var activity_log = [];
            querySnapshot.forEach((doc) => {
                activity_log.push({ ...doc.data(), userId: doc.ref.parent.parent.id });
            });

            dispatch({ type: ((prefix) ? prefix + '_' : '') + types.ACTIVITY_LOG + '_FULFILLED', data: { data: activity_log, totalCount: count }, unsubscribe });
        });
    };
}
export function subUserActivityLog(handle, userId, limit_) {

	return async dispatch => {

		dispatch({ type: types.ACTIVITY_LOG_USER + '_CLEAR' });
		dispatch({ type: types.ACTIVITY_LOG_USER + '_PENDING' });

        if (userId) {
            var query_ = query(collection(db, `${handle}/users/users/${userId}/activity-log`), orderBy('date', 'desc'), limit(limit_));
            const unsubscribe = onSnapshot(query_, async (querySnapshot) => {

                var activity_log = [];
                querySnapshot.forEach((doc) => {
                    activity_log.push({ ...doc.data() });
                });

                var query_ = query(collection(db, `${handle}/users/users/${userId}/activity-log`), orderBy('date', 'desc'));
                const snapshot = await getCountFromServer(query_);
                const count = snapshot.data().count;

                dispatch({ type: types.ACTIVITY_LOG_USER + '_FULFILLED', data: { data: activity_log, totalCount: count }, unsubscribe });
            });
        }
	};
}


/*-----------------------------------------------*/
/*  Error Log
/*-----------------------------------------------*/

export function logError(handle, error) {

    return async dispatch => {

        var existingRef = await firestore.collection(handle + '/error-log/error-log/').where('message', '==', error.message).where('pathname', '==', error.pathname).where('stack', '==', error.stack).where('resolved', '==', false).get();
        var existing = { ...existingRef.docs[0]?.data(), id: existingRef.docs[0]?.id };

        if (!existing?.id) {
            firestore.collection(handle + '/error-log/error-log/').add(error);
        }
    };
}

/*-----------------------------------------------*/
/*  FAB Barcode Scanned
/*-----------------------------------------------*/

export function barcodeToUrl(handle, appId, barcodeId, callback) {

    return async dispatch => {
        if (barcodeId.startsWith('A')) {
            assetURLfromID(handle, appId, barcodeId.substring(1), (url) => callback(url));
        } else if (barcodeId.startsWith('P')) {
            stockPartURLfromID(handle, appId, barcodeId.substring(1), (url) => callback(url));
        } else {
            callback(null);
        }
    }
}

export async function assetURLfromID(handle, appId, id, callback) {
	const assetDoc = await firestore.collection(`${handle}/${appId}/assets/assets`).doc(id).get();
    const asset = { ...assetDoc.data() }
    callback((assetDoc.exists) ? `/${handle}/${appId}/assets/${asset.customerId ?? '0'}/${asset.assetTypeId}/${id}/form/0/service_requests` : null);
}
export async function stockPartURLfromID(handle, appId, id, callback) {
	const partDoc = await firestore.collection(`${handle}/${appId}/stock-parts/stock-parts`).doc(id).get();
    const part = { ...partDoc.data() }
    callback((partDoc.exists) ? `/${handle}/${appId}/inventory/${part.assetTypeIds[0]}/${id}/form/0/assets` : null);
}

/*-----------------------------------------------*/
/*  Bottom Sheet
/*-----------------------------------------------*/

export function bottomSheetShow(value) {

	return async dispatch => {
		dispatch({ type: types.BOTTOM_SHEET_SHOW, data: value });
	};    
}    

/*-----------------------------------------------*/
/*  Lightbox
/*-----------------------------------------------*/

export function lightboxShow(value) {

    return async dispatch => {
        dispatch({ type: types.LIGHTBOX_SHOW, data: value });
    };
}
export function lightboxIndex(value) {

    return async dispatch => {
        dispatch({ type: types.LIGHTBOX_INDEX, data: value });
    };
}
export function lightboxSources(sources) {

    return async dispatch => {
        dispatch({ type: types.LIGHTBOX_SOURCES, data: sources });
    };
}

/*-----------------------------------------------*/
/*  App Platform
/*-----------------------------------------------*/

export function flutterPlatform(platform) {

    return async dispatch => {
        dispatch({ type: types.FLUTTER_PLATFORM, data: platform });
    };
}

/*-----------------------------------------------*/
/*  Flutter Virtual Keyboard
/*-----------------------------------------------*/

export function virtualKeyboard(visible) {

    return async dispatch => {
        dispatch({ type: types.FLUTTER_KEYBOARD, data: visible });
    };
}

/*-----------------------------------------------*/
/*  Help Panel
/*-----------------------------------------------*/

export function showHelp(show, url='') {

    return async dispatch => {

        dispatch({ type: types.HELP + '_URL', data: 'https://manager.mobiletrack.systems/help/docs' + url });
        dispatch({ type: types.HELP + '_SHOW', data: show });

    };
}

/*-----------------------------------------------*/
/*  Copy to new Id
/*-----------------------------------------------*/

export function copyAccount(handle, newHandle) {

    return async dispatch => {

        var snapshot, doc;

        /* COMPANY --------------------------------------------------------------------------------- */

        // await copyDoc('company');

        /* ADDRESSES --------------------------------------------------------------------------------- */

        await copyDoc('addresses');
        await copyCollection('addresses', 'addresses');

        /* CONTACTS --------------------------------------------------------------------------------- */

        await copyDoc('contacts');
        await copyCollection('contacts', 'contacts');

        /* PROFILES --------------------------------------------------------------------------------- */

        await copyDoc('profiles');
        await copyCollection('profiles', 'profiles');

        /* ASSETS --------------------------------------------------------------------------------- */

        await copyDoc('assets');
        await copyCollection('assets', 'assets');

        /* STOCK PARTS --------------------------------------------------------------------------------- */

        await copyDoc('stock-parts');
        await copyCollection('stock-parts', 'stock-parts');

        /* USERS --------------------------------------------------------------------------------- */

        await copyDoc('users');
        await copyCollection('users', 'users');

        /* SETTINGS --------------------------------------------------------------------------------- */

        await copyDoc('settings');
        await copyCollection('settings', 'assetClassifications');
        await copyCollection('settings', 'assetTypes');
        await copyCollection('settings', 'maintenance');
        await copyCollection('settings', 'partsCategories');


        async function copyDoc(table) {
            console.log(table.toUpperCase() + ' document');
            let doc = await firestore.collection(handle).doc(table).get();
            await firestore.collection(newHandle).doc(table).set(doc.data());
        }
        async function copyCollection(table1, table2) {
            console.log(table1.toUpperCase() + ' collection');

            snapshot = await firestore.collection(handle + `/${table1}/${table2}`).get();
            for (let doc of snapshot.docs) {
                console.log(doc.id);
                await firestore.collection(newHandle + `/${table1}/${table2}`).doc(doc.id).set(doc.data());
            }
        }
    };
}

/*-----------------------------------------------*/
/*  Copy to new Id
/*-----------------------------------------------*/

export function moveAccount() {

    const handle = 'biosource';

    return async dispatch => {

        var snapshot, snapshot2;

        /* COMPANY --------------------------------------------------------------------------------- */

        // await copyDoc('company');

        await copyDoc('addresses');
        await copyCollection('addresses', 'addresses');

        await copyDoc('contacts');
        await copyCollection('contacts', 'contacts');

        await copyDoc('profiles');
        await copyCollection('profiles', 'profiles');

        await copyDoc('assets');
        await copyCollection('assets', 'assets', ['attachments']);

        await copyDoc('service-requests');
        await copyCollection('service-requests', 'service-requests', ['attachments']);

        await copyDoc('quotes');
        await copyCollection('quotes', 'quotes', ['attachments']);

        await copyDoc('work-orders');
        await copyCollection('work-orders', 'work-orders', ['attachments', 'check-in', 'serviceItems']);

        await copyDoc('stock-parts');
        await copyCollection('stock-parts', 'stock-parts');

        await copyDoc('users');
        await copyCollection('users', 'users', ['activity-log', 'time-clock']);

        await copyDoc('invoices');
        await copyCollection('invoices', 'invoices');

        await copyDoc('payments');
        await copyCollection('payments', 'payments');

        /* SETTINGS --------------------------------------------------------------------------------- */

        await copyDoc('settings');
        await copyCollection('settings', 'assetClassification', [], 'assetClassifications');
        await copyCollection('settings', 'assetTypes');
        await copyCollection('settings', 'departments');
        await copyCollection('settings', 'partsCategories');

        async function copyDoc(table) {
            console.log(table.toUpperCase() + ' document');
            let doc = await firestore2.collection(handle).doc(table).get();

            let record = doc.data();
            if (table == 'company') {
                record.appIds = ['2'];
                record.locations = [{
                    handle: record.id,
                    name: record.companyName,
                }]
            }

            if (doc.exists) await firestore.collection(handle).doc(table).set(record);
        }
        async function copyCollection(table1, table2, subs, rename) {
            console.log(table1.toUpperCase() + ' collection');

            snapshot = await firestore2.collection(handle + `/${table1}/${table2}`).get();
            for (let doc of snapshot.docs) {
                console.log(doc.id);

                let record = doc.data();

                if (table2 == 'service-requests') {

                    let doc3 = await firestore2.collection(handle + '/assets/assets').doc(record.assetId).get();
                    let asset = doc3.data();

                    record._unitNumber = (asset.unitNumber) ? asset.unitNumber : null;
                    record._imageUrl = (asset.imageUrl) ? asset.imageUrl : null; 

                    if (record.type == 'request') record.type = 'asset_request';
                    if (record.type == 'record') record.type = 'asset_record';
                    if (parseInt(record.statusId) > 1) record.statusId = (parseInt(record.statusId) + 1).toString();        

                } else if (table2 == 'work-orders') {

                    let doc3 = await firestore2.collection(handle + '/service-requests/service-requests').doc(record.serviceRequestId).get();
                    let serviceRequest = doc3.data();

                    record.customerId = (serviceRequest?.customerId) ? serviceRequest.customerId : null;
                    if (serviceRequest?.type == 'request') record.type = 'asset_request';
                    if (serviceRequest?.type == 'record') record.type = 'asset_record';

                } else if (table2 == 'profiles') {
                    if (record.type == 'customer') {
                        record.appIds = [ '2' ];
                        record.customerTypeId = '2';
                    };
                } 

                await firestore.collection(handle + `/${table1}/${(rename)?rename:table2}`).doc(doc.id).set(record);

                if (subs?.length) {
                    for (let sub of subs) {
                        console.log(sub.toUpperCase() + ' sub-collection');
                        snapshot2 = await firestore2.collection(handle + `/${table1}/${table2}/${doc.id}/${sub}`).get();

                        for (let doc2 of snapshot2.docs) {
                            console.log(doc2.id);
                            if (doc2.id == 'partsCategories') {
                                await firestore.collection(handle + `/${table1}/${table2}/${doc.id}/${sub}`).doc(doc2.id).set({ ...doc2.data(), type: 'stock' });
                            } else {
                                await firestore.collection(handle + `/${table1}/${table2}/${doc.id}/${sub}`).doc(doc2.id).set(doc2.data());
                            }
                        }
                    }
                }
            }
        }
    };
}


